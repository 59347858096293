import React from 'react';
import axios from 'axios';
const urlBase = "https://api.escapeplanetario.museoscienza.it";
//const urlBase = "https://escape-game.pavone.dev";
const authBearer = "Bearer 1|zj2Sd3KkHnEvsOwjX7fVgNKHeHyypK2mtUhmzTd4f620755c"; // MUSEO
//const authBearer = "Bearer 1|piuMnkRWPnnexgo2lDZHKeoPkBTGRaB250polSuv5b9036f8"; // PAVONE
const debug = false;

export async function startGame(ticket) {
  try {
    if (debug) console.log("Trying startGame ticket: ", ticket);
    const url = urlBase + "/api/ticket/new";
    const bodyParameters = {
      ticket: ticket
      //ticket: 'ESP20245OJP2YL332QX'
    };
    const config = {
        headers: {
          Authorization: authBearer,
          Accept: "application/json"
       }
    };
    const response = await axios.post(url,bodyParameters,config);
    if (debug) console.log('Response startGame: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error', error);
    return error.response.data;
  }
}

export async function updateState(uuid, mission, chat, pen, mk) {
  try {
     //u: ",uuid, " m: ", mission, " c: ", chat, " p: ", pen,
    if (debug) console.log("Trying updateState  mk: ", mk);
    const url = urlBase + "/api/ticket/" + uuid + "/chat";
    const response = await axios.post(url, {
      game_mission: mission,
      chat: chat,
      game_points: pen,
      master_key: mk
    },{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })
    if (debug) console.log('Response updateState: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error updateState ', error.response.data.error)
    return [];
  }
}

export async function setNickname(uuid, nick, key) {
  try {
    if (debug) console.log("Trying setNickname u: ", uuid, " n: ", nick, " mk: ", key);
    const url = urlBase + "/api/ticket/" + uuid + "/nickname";
    const response = await axios.post(url, {
      nickname: nick,
      master_key: key
    },{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })
    if (debug) console.log('Response setNickname: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error setNickname')
    return [];
  }
}

export async function getNewMaster(uuid) {
  try {
    if (debug) console.log("Trying getNewMaster u: ", uuid);
    const url = urlBase + "/api/ticket/" + uuid + "/new_master_key";
    const response = await axios.post(url,{},{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })
    if (debug) console.log('Response getNewMaster: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error getNewMaster')
    return [];
  }
}


export async function validateTicket(uuid, pt, key) {
  try {
    if (debug) console.log("Trying validateTicket u: ", uuid, " p: ", pt, " mk: ", key);
    const url = urlBase + "/api/ticket/" + uuid + "/validated";
    const response = await axios.post(url, {
      game_points: pt,
      master_key: key
    },{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })
    if (debug) console.log('Response validateTicket: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error validateTicket')
    return [];
  }
}

export async function checkState(uuid) {
  try {
    if (debug) console.log("Trying checkState u: ",uuid,);
    const url = urlBase + "/api/ticket/" + uuid + "/check";
    const response = await axios.get(url,{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })

    if (debug) console.log('Response checkState: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error checkState')
    return "error";
  }
}

export async function getStats(uuid) {
  try {
    if (debug) console.log("Trying getStats u: ", uuid,);
    const url = urlBase + "/api/ticket/stats";
    const response = await axios.get(url,{
      headers:{
        Authorization: authBearer,
        Accept: "application/json"
      }
    })

    if (debug) console.log('Response checkState: ',response.data);
    return response.data;
  } catch(error) {
    if (debug) console.log('catch error checkState')
    return [];
  }
}
