import React from 'react'
import { BalloonContent } from "./content/BalloonContent"
import { HelpContent } from "./content/HelpContent"
import { SuggestionContent } from "./content/SuggestionContent"
import { MissionContent } from "./content/MissionContent"
import { CommonContent } from "./content/CommonContent"




import "./../assets/scss/Balloon.scss";



export const Balloon = (props) => {
   const [isActive, setIsActive] = React.useState([false,false,false,false,false,false,false,false]);
   const newOrder = [7,3,5,2,6,1,8,4]
   const [missionsDone, setMissionsDone] = React.useState(0);

   const handleAccordionClick = (e, ind) => {
      let newIsActive = [false,false,false,false,false,false,false,false];
      newIsActive[ind] = !isActive[ind];
      setIsActive(newIsActive);
   }

   const addZeroBefore = (n) => {
     return (n < 10 ? '0' : '') + n;
   }

   const convertMsToHM = (milliseconds) => {
     let seconds = Math.floor(milliseconds / 1);
     let minutes = Math.floor(seconds / 60);
     let hours = Math.floor(minutes / 60);

     seconds = seconds % 60;
     minutes = minutes % 60;
     hours = hours % 24;
     //return `${hours}:${minutes}:${seconds}`;
     return `${addZeroBefore(hours)}:${addZeroBefore(minutes)}:${addZeroBefore(seconds)}`;
   }

   React.useEffect(() => {
      let tmp = 0;

      props.mdone.forEach((ind) => {
         if(ind) {
            tmp++;
         }
      });
      setMissionsDone(tmp);
   },[props.mdone])


    return(
        <div className={`chat ${(props.type)}`}>

          {(props.clickon.startsWith('w_') || props.clickon.startsWith('z_')) &&
            <div className="dot-flashing"></div>
          }

          {/* // sto stampando la risposta sbagliata preceduta da y_ */ }
          {(props.clickon.startsWith('y_')) &&
            <>{props.clickon.substring(2)}</>
          }

         {/* // sto stampando la risposta sbagliata preceduta da n_ */ }
          {(props.clickon.startsWith('n_')) &&
             <>
               <div className="chatBalloon n">
                  <p>{props.clickon.substring(2)}</p>
               </div>
               <div className="mAfterN">
                  <p>Risposta errata</p>
               </div>
             </>
          }

          {(props.clickon.startsWith('h_')) &&
            <div className="chatBalloon h">
               <HelpContent lang={props.lang} stringToRet={`${props.clickon}_mission${props.mission}`} />
            </div>
          }


          {(props.clickon == 'u_2' && props.mission == 9) &&
             <div className="chatBalloon u">
               <>{props.tk}</>
            </div>
          }


          {(props.clickon == 'u_3' && props.mission == 9 && props.mk == "guest") &&
            <div className="chatBalloon u">
               <BalloonContent lang={props.lang} stringToRet={`${props.clickon}g_mission${props.mission}`} />
            </div>
          }

          {(props.clickon == 'u_3' && props.mission == 9 && props.mk != "guest") &&
            <div className="chatBalloon u">
               <BalloonContent lang={props.lang} stringToRet={`${props.clickon}m_mission${props.mission}`} />
            </div>
          }

          {(props.clickon == 'u_5' && props.mission == 9) &&
             <div className="chatBalloon u">
               <>{props.nk}</>
             </div>
          }

          {(props.clickon.startsWith('u_') &&
           !(props.clickon == 'u_2' && props.mission == 9) &&
           !(props.clickon == 'u_3' && props.mission == 9) &&
           !(props.clickon == 'u_5' && props.mission == 9)) &&
            <div className="chatBalloon u">
               <BalloonContent lang={props.lang} stringToRet={`${props.clickon}_mission${props.mission}`} />
            </div>
          }

          {(props.clickon.startsWith('m_99') && missionsDone >= 8)  &&
            <>
               <BalloonContent lang={props.lang} stringToRet="m_99c" />
               {props.mk != "guest" &&
                  <button className="btn clickTermina" onClick={() => props.onWinGame()}><BalloonContent lang={props.lang} stringToRet="m_99d" /></button>
               }
            </>
          }

          {(props.clickon.startsWith('m_99')  && missionsDone < 8) &&
            <>
               {(props.mission === 0) &&
                  <BalloonContent lang={props.lang} stringToRet="m_99b" />
               }
               {(props.mission != 0) &&
                  <BalloonContent lang={props.lang} stringToRet="m_99" />
               }


               {isActive.map((k, ind) => (

                  <div className={`missionList ${props.mission == newOrder[ind] ? "now":  props.mdone[newOrder[ind]] ? "hide": ""}`} key={ind}>
                    <div className="accBlock">
                       <button
                          className={`clickAccbtn ${isActive[ind] ? "clicked": ""}`}
                          {...((!props.mdone[newOrder[ind]] && props.mission !== newOrder[ind]) ? { onClick:(e) => {handleAccordionClick(e, ind)}} : {})}>
                          <p><MissionContent lang={props.lang} stringToRet={`title_mission${newOrder[ind]}`} /></p>
                          {(!props.mdone[newOrder[ind]] && props.mission !== newOrder[ind]) && (
                             <span>></span>
                          )}
                       </button>
                     </div>
                     <div className={`missionDesc ${isActive[ind] ? "visible": ""}`}>
                       <MissionContent lang={props.lang} stringToRet={`desc_mission${newOrder[ind]}`} />
                       {(props.mk !== 'guest')   &&
                          <button className="enter" onClick={() => props.onChangeMission(newOrder[ind])}><CommonContent stringToRet="entra" lang={props.lang} /></button>
                       }
                     </div>
                   </div>
                ))}
            </>
          }

          {((props.clickon === 'm_2' && props.mission === 10) ||
            (props.clickon === 'm_1' && props.mission === 11)) && (
             <table className="classifica">
                <tbody>
                  {props.stats.map((item, i) => <tr key={item + i}><td>{i+1}.</td><td>{item.nickname}</td><td className="penality">{item.game_points}</td><td>{convertMsToHM(item.time)}</td></tr>)}
                </tbody>
             </table>
          )}

         {((props.clickon === 'm_29' && props.mission === 10) ||
           (props.clickon === 'm_29' && props.mission === 11)) && (
            <>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'icosaedro')}>
                <CommonContent lang={props.lang} stringToRet="schedaIcosaedro" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'kreched')}>
                <CommonContent lang={props.lang} stringToRet="schedaKrechet" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'v2')}>
                <CommonContent lang={props.lang} stringToRet="schedaV2" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'programma101')}>
                <CommonContent lang={props.lang} stringToRet="schedaProgramma101" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'elea')}>
                <CommonContent lang={props.lang} stringToRet="schedaElea" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'toti')}>
                <CommonContent lang={props.lang} stringToRet="schedaToti" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'apollo11')}>
                <CommonContent lang={props.lang} stringToRet="schedaApollo11" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'ebe')}>
                <CommonContent lang={props.lang} stringToRet="schedaEbe" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'biancamano')}>
                <CommonContent lang={props.lang} stringToRet="schedaBiancamano" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'convertiplano')}>
                <CommonContent lang={props.lang} stringToRet="schedaConvertiplano" />
             </button>
             <button className="btn appro" onClick={(e) => props.onToggleExtra(e,'filettatrice')}>
                <CommonContent lang={props.lang} stringToRet="schedaFilettatrice" />
             </button>
             </>
          )}

          {((props.clickon.startsWith('m_') && !props.clickon.startsWith('m_99'))  ||
            props.clickon.startsWith('f_'))   &&
            <>
             <BalloonContent lang={props.lang} stringToRet={`${props.clickon}_mission${props.mission}`} />
            </>
          }

          {(props.clickon.startsWith('s_')) &&
             <SuggestionContent lang={props.lang} stringToRet={`${props.clickon}_mission${props.mission}`} />
          }


        </div>

    )
}
