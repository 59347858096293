import React from 'react'

export const CommonContent = (props) => {
   const string = props.stringToRet;
   let lang = props.lang;

   return (
      <>

         {(string === 'credits' && lang === 'ita') && ( <>Crediti</> )}
         {(string === 'credits' && lang === 'eng') && ( <>Credits</> )}
         {(string === 'credits' && lang === 'fra') && ( <>Crédits</> )}

         {(string === 'schedaIcosaedro' && lang === 'ita') && ( <>Icosaedro vacuo</> )}
         {(string === 'schedaFilettatrice' && lang === 'ita') && ( <>Macchina per filettare viti</> )}
         {(string === 'schedaElea' && lang === 'ita') && ( <>Elea 9003</> )}
         {(string === 'schedaProgramma101' && lang === 'ita') && ( <>Programma 101</> )}
         {(string === 'schedaApollo11' && lang === 'ita') && ( <>Missione Apollo 11</> )}
         {(string === 'schedaKrechet' && lang === 'ita') && ( <>Tuta Krechet</> )}
         {(string === 'schedaToti' && lang === 'ita') && ( <>Sottomarino Enrico Toti</> )}
         {(string === 'schedaEbe' && lang === 'ita') && ( <>Nave scuola Ebe</> )}
         {(string === 'schedaBiancamano' && lang === 'ita') && ( <>Conte Biancamano</> )}
         {(string === 'schedaConvertiplano' && lang === 'ita') && ( <>Convertiplano tipo AW609</> )}
         {(string === 'schedaV2' && lang === 'ita') && ( <>V2 e i depositi del Museo</> )}

         {(string === 'schedaIcosaedro' && lang === 'eng') && ( <>Hollow icosahedron</> )}
         {(string === 'schedaFilettatrice' && lang === 'eng') && ( <>Thread rolling machine</> )}
         {(string === 'schedaElea' && lang === 'eng') && ( <>Elea 9003</> )}
         {(string === 'schedaProgramma101' && lang === 'eng') && ( <>Programma 101</> )}
         {(string === 'schedaApollo11' && lang === 'eng') && ( <>The Apollo 11 mission</> )}
         {(string === 'schedaKrechet' && lang === 'eng') && ( <>Krechet space suit</> )}
         {(string === 'schedaToti' && lang === 'eng') && ( <>Enrico Toti submarine</> )}
         {(string === 'schedaEbe' && lang === 'eng') && ( <>Training ship Ebe</> )}
         {(string === 'schedaBiancamano' && lang === 'eng') && ( <>Conte Biancamano</> )}
         {(string === 'schedaConvertiplano' && lang === 'eng') && ( <>AW609 Tiltrotor</> )}
         {(string === 'schedaV2' && lang === 'eng') && ( <>The V2 and the Museum storage rooms</> )}

         {(string === 'schedaIcosaedro' && lang === 'fra') && ( <>Icosaèdre creux</> )}
         {(string === 'schedaFilettatrice' && lang === 'fra') && ( <>Machine à fileter les vis</> )}
         {(string === 'schedaElea' && lang === 'fra') && ( <>Elea 9003</> )}
         {(string === 'schedaProgramma101' && lang === 'fra') && ( <>Programme 101</> )}
         {(string === 'schedaApollo11' && lang === 'fra') && ( <>La mission Apollo 11</> )}
         {(string === 'schedaKrechet' && lang === 'fra') && ( <>Combinaison d'exploration lunaire Krechet</> )}
         {(string === 'schedaToti' && lang === 'fra') && ( <>Sous-marin Enrico Toti</> )}
         {(string === 'schedaEbe' && lang === 'fra') && ( <>Navire-école Ebe</> )}
         {(string === 'schedaBiancamano' && lang === 'fra') && ( <>Conte Biancamano</> )}
         {(string === 'schedaConvertiplano' && lang === 'fra') && ( <>Convertiplane type AW609</> )}
         {(string === 'schedaV2' && lang === 'fra') && ( <>Les V2 et les dépôts du Musée</> )}

         {(string === 'lingua' && lang === 'ita') && ( <>Lingua</> )}
         {(string === 'lingua' && lang === 'fra') && ( <>Langue</> )}
         {(string === 'lingua' && lang === 'eng') && ( <>Language</> )}

         {(string === 'diventaMaster' && lang === 'ita') && ( <>Diventa Team Leader</> )}
         {(string === 'diventaMaster' && lang === 'fra') && ( <>Devenir le capitaine de l'équipe</>)}
         {(string === 'diventaMaster' && lang === 'eng') && ( <>Become Team Leader</> )}

         {(string === 'fluoStyle' && lang === 'ita') && ( <>Versione standard</> )}
         {(string === 'fluoStyle' && lang === 'eng') && ( <>Standard version</> )}
         {(string === 'fluoStyle' && lang === 'fra') && ( <>Version standard</> )}

         {(string === 'accessibile' && lang === 'ita') && ( <>Versione accessibile</> )}
         {(string === 'accessibile' && lang === 'eng') && ( <>Accessible version</> )}
         {(string === 'accessibile' && lang === 'fra') && ( <>Version accessible</> )}

         {(string === 'elencoMissioni' && lang === 'ita') && ( <>Elenco Missioni</> )}
         {(string === 'elencoMissioni' && lang === 'eng') && ( <>Mission List</> )}
         {(string === 'elencoMissioni' && lang === 'fra') && ( <>Liste des missions</> )}

         {(string === 'missione1' && lang === 'ita') && ( <>Missione 1</> )}
         {(string === 'missione2' && lang === 'ita') && ( <>Missione 2</> )}
         {(string === 'missione3' && lang === 'ita') && ( <>Missione 3</> )}
         {(string === 'missione4' && lang === 'ita') && ( <>Missione 4</> )}
         {(string === 'missione5' && lang === 'ita') && ( <>Missione 5</> )}
         {(string === 'missione6' && lang === 'ita') && ( <>Missione 6</> )}
         {(string === 'missione7' && lang === 'ita') && ( <>Missione 7</> )}
         {(string === 'missione8' && lang === 'ita') && ( <>Missione 8</> )}

         {(string === 'missione1' && lang === 'eng') && ( <>Mission 1</> )}
         {(string === 'missione2' && lang === 'eng') && ( <>Mission 2</> )}
         {(string === 'missione3' && lang === 'eng') && ( <>Mission 3</> )}
         {(string === 'missione4' && lang === 'eng') && ( <>Mission 4</> )}
         {(string === 'missione5' && lang === 'eng') && ( <>Mission 5</> )}
         {(string === 'missione6' && lang === 'eng') && ( <>Mission 6</> )}
         {(string === 'missione7' && lang === 'eng') && ( <>Mission 7</> )}
         {(string === 'missione8' && lang === 'eng') && ( <>Mission 8</> )}

         {(string === 'missione1' && lang === 'fra') && ( <>Mission 1</> )}
         {(string === 'missione2' && lang === 'fra') && ( <>Mission 2</> )}
         {(string === 'missione3' && lang === 'fra') && ( <>Mission 3</> )}
         {(string === 'missione4' && lang === 'fra') && ( <>Mission 4</> )}
         {(string === 'missione5' && lang === 'fra') && ( <>Mission 5</> )}
         {(string === 'missione6' && lang === 'fra') && ( <>Mission 6</> )}
         {(string === 'missione7' && lang === 'fra') && ( <>Mission 7</> )}
         {(string === 'missione8' && lang === 'fra') && ( <>Mission 8</> )}

         {(string === 'mappaMissioni' && lang === 'ita') && ( <>Mappa Missioni</> )}
         {(string === 'mappaMissioni' && lang === 'eng') && ( <>Mission Map</> )}
         {(string === 'mappaMissioni' && lang === 'fra') && ( <>Map des Missions</> )}

         {(string === 'ai' && lang === 'ita') && ( <>Intelligenza Artificiale</> )}
         {(string === 'ai' && lang === 'eng') && ( <>Artificial intelligence</> )}
         {(string === 'ai' && lang === 'fra') && ( <>Intelligence Artificielle</> )}

         {(string === 'tempo' && lang === 'ita') && ( <>TEMPO</> )}
         {(string === 'tempo' && lang === 'eng') && ( <>TIME</> )}
         {(string === 'tempo' && lang === 'fra') && ( <>TEMPS</> )}

         {(string === 'punti' && lang === 'ita') && ( <>PUNTI</> )}
         {(string === 'punti' && lang === 'eng') && ( <>SCORE</> )}
         {(string === 'punti' && lang === 'fra') && ( <>POINTS</> )}

         {(string === 'entra' && lang === 'ita') && ( <>ENTRA</> )}
         {(string === 'entra' && lang === 'eng') && ( <>START</> )}
         {(string === 'entra' && lang === 'fra') && ( <>ENTRER</> )}

         {(string === 'approfondimenti' && lang === 'ita') && ( <>Approfondimenti</> )}
         {(string === 'approfondimenti' && lang === 'eng') && ( <>Fact sheets</> )}
         {(string === 'approfondimenti' && lang === 'fra') && ( <>Fiches d'approfondissement</> )}
      </>
   )
}
