import React from 'react'

export const HelpContent = (props) => {

   const string = props.stringToRet;
   let lang = props.lang;


   return (
   <>

      {/* ******* RICHIESTE HELP ******** */}

      {(string === 'h_1_mission1' && lang === 'ita') && ( <p>Mi sono perso, Meta! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission1' && lang === 'ita') && ( <p>Aiutami Meta <span>(-20pt)</span></p> )}
      {(string === 'h_3_mission1' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission2' && lang === 'ita') && ( <p>Aiutami Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission2' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission3' && lang === 'ita') && ( <p>Dammi un aiutino, Meta <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission3' && lang === 'ita') && ( <p>Aiutami Meta <span>(-30pt)</span></p> )}

      {(string === 'h_1_mission4' && lang === 'ita') && ( <p>Dammi un aiutino, Meta <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission4' && lang === 'ita') && ( <p>Aiutami Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission4' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_4_mission4' && lang === 'ita') && ( <p>Aiutami Meta <span>(-20pt)</span></p> )}
      {(string === 'h_5_mission4' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission5' && lang === 'ita') && ( <p>Dammi un aiutino, Meta <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission5' && lang === 'ita') && ( <p>Aiutami Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission5' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission6' && lang === 'ita') && ( <p>Dammi un aiutino, Meta <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission6' && lang === 'ita') && ( <p>Aiutami Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission6' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission7' && lang === 'ita') && ( <p>Aiutami Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission7' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_3_mission7' && lang === 'ita') && ( <p>Aiutami Meta <span>(-20pt)</span></p> )}

      {(string === 'h_4_mission7' && lang === 'ita') && ( <p>Aiutami Meta <span>(-10pt)</span></p> )}

      {(string === 'h_5_mission7' && lang === 'ita') && ( <p>Aiutami Meta <span>(-10pt)</span></p> )}

      {(string === 'h_6_mission7' && lang === 'ita') && ( <p>Dammi la soluzione Meta <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission8' && lang === 'ita') && ( <p>Mi sono perso, Meta! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission8' && lang === 'ita') && ( <p>Dammi un aiutino, Meta  <span>(-10pt)</span></p> )}
      {(string === 'h_3_mission8' && lang === 'ita') && ( <p>Aiutami Meta  <span>(-30pt)</span></p> )}

      {/* HELP ENG */}

      {(string === 'h_1_mission1' && lang === 'eng') && ( <p>Meta, I’m lost and need help! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission1' && lang === 'eng') && ( <p>Help me, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_3_mission1' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission2' && lang === 'eng') && ( <p>Help me, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission2' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission3' && lang === 'eng') && ( <p>Meta, give me a hint <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission3' && lang === 'eng') && ( <p>Help me, Meta <span>(-30pt)</span></p> )}

      {(string === 'h_1_mission4' && lang === 'eng') && ( <p>Meta, give me a hint <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission4' && lang === 'eng') && ( <p>Help me, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission4' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_4_mission4' && lang === 'eng') && ( <p>Help me, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_5_mission4' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission5' && lang === 'eng') && ( <p>Meta, give me a hint <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission5' && lang === 'eng') && ( <p>Help me, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission5' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission6' && lang === 'eng') && ( <p>Meta, give me a hint <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission6' && lang === 'eng') && ( <p>Help me, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission6' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission7' && lang === 'eng') && ( <p>Help me, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission7' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_3_mission7' && lang === 'eng') && ( <p>Help me, Meta <span>(-20pt)</span></p> )}

      {(string === 'h_4_mission7' && lang === 'eng') && ( <p>Help me, Meta <span>(-10pt)</span></p> )}

      {(string === 'h_5_mission7' && lang === 'eng') && ( <p>Help me, Meta <span>(-10pt)</span></p> )}

      {(string === 'h_6_mission7' && lang === 'eng') && ( <p>Meta, give me the solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission8' && lang === 'eng') && ( <p>Meta, I’m lost and need help! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission8' && lang === 'eng') && ( <p>Meta, give me a hint <span>(-10pt)</span></p> )}
      {(string === 'h_3_mission8' && lang === 'eng') && ( <p>Help me, Meta <span>(-30pt)</span></p> )}


      {/* HELP FRA */}

      {(string === 'h_1_mission1' && lang === 'fra') && ( <p>Je suis perdu, Meta ! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission1' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_3_mission1' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission2' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission2' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission3' && lang === 'fra') && ( <p>Meta, donne-moi un coup de main <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission3' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-30pt)</span></p> )}

      {(string === 'h_1_mission4' && lang === 'fra') && ( <p>Meta, donne-moi un coup de main <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission4' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission4' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_4_mission4' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_5_mission4' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission5' && lang === 'fra') && ( <p>Meta, donne-moi un coup de main <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission5' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission5' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission6' && lang === 'fra') && ( <p>Meta, donne-moi un coup de main <span>(-10pt)</span></p> )}
      {(string === 'h_2_mission6' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-30pt)</span></p> )}
      {(string === 'h_3_mission6' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission7' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-20pt)</span></p> )}
      {(string === 'h_2_mission7' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_3_mission7' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-20pt)</span></p> )}

      {(string === 'h_4_mission7' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-10pt)</span></p> )}

      {(string === 'h_5_mission7' && lang === 'fra') && ( <p>Aide-moi, Meta <span>(-10pt)</span></p> )}

      {(string === 'h_6_mission7' && lang === 'fra') && ( <p>Meta, donne-moi la solution <span>(-50pt)</span></p> )}

      {(string === 'h_1_mission8' && lang === 'fra') && ( <p>Je suis perdu, Meta ! <span>(-10pt)</span></p> )}

      {(string === 'h_2_mission8' && lang === 'fra') && ( <p>Meta, donne-moi un coup de main <span>(-10pt)</span></p> )}
      {(string === 'h_3_mission8' && lang === 'fra') && ( <p>Aide-moi, Meta  <span>(-30pt)</span></p> )}

</>
)
}
