import React from 'react'

export const CommonSvgContent = (props) => {
  const string = props.stringToRet;

  return (
    <>
      {(string === 'def') && (
        <></>
      )}

      {(string === 'mis1') && (
         <svg className="missSvg miss1Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722.68 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m722.68,102.67h-73.91v-16.23h28.92V16.08h-28.76V0h45.15v86.44h28.61v16.23Zm-71.91-2h69.91v-12.23h-28.61V2h-41.15v12.08h28.76v74.37h-28.92v12.23Z" />
           </g>
         </svg>
      )}
      {(string === 'mis2') && (
         <svg className="missSvg miss2Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m720.54,102.67h-73.91v-44.99h7.19v-7.19h7.19v-7.19h35.96v-7.19h7.19v-12.54h-7.19v-7.19h-26.76v7.19h-7.19v7.19h-16.38V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v30.92h-7.19v7.19h-7.19v7.19h-35.96v7.19h-7.19v19.42h57.53v16.38Zm-71.91-2h69.91v-12.38h-57.53v-23.42h7.19v-7.19h35.96v-7.19h7.19v-7.19h7.19v-26.92h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v12.38h12.38v-7.19h7.19v-7.19h30.76v7.19h7.19v16.54h-7.19v7.19h-35.96v7.19h-7.19v7.19h-7.19v40.99Z" />
           </g>
         </svg>
      )}
      {(string === 'mis3') && (
         <svg className="missSvg miss3Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 719.31 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m704.93,102.67h-45.45v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.19h27.07v-7.19h7.19v-12.23h-7.19v-7.04h-28.3v-16.84h28.3v-7.19h7.19v-12.23h-7.19v-7.34h-27.07v7.34h-7.19v7.19h-16.38V14.38h7.19v-7.19h7.19V0h45.45v7.19h7.19v7.19h7.19v30.61h-7.19v12.23h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-43.45-2h41.45v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-16.23h7.19v-26.61h-7.19v-7.19h-7.19V2h-41.45v7.19h-7.19v7.19h-7.19v12.38h12.38v-7.19h7.19v-7.34h31.07v7.34h7.19v16.23h-7.19v7.19h-28.3v12.84h28.3v7.04h7.19v16.23h-7.19v7.19h-31.07v-7.19h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'mis4') && (
         <svg className="missSvg miss4Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m706.46,102.67h-16.69v-28.31h-43.15v-30.3h6.88v-7.5h7.34v-7.34h7.19v-7.5h7.19v-7.19h7.19v-7.19h7.19V0h16.84v58.14h14.08v16.23h-14.08v28.31Zm-14.69-2h12.69v-28.31h14.08v-12.23h-14.08V2h-12.84v7.34h-7.19v7.19h-7.19v7.19h-7.19v7.5h-7.19v7.34h-7.34v7.5h-6.88v26.3h43.15v28.31Zm-.15-40.53h-30.76v-9.34h7.19v-7.5h7.19v-7.19h7.19v-7.19h9.19v31.22Zm-28.76-2h26.76v-27.22h-5.19v7.19h-7.19v7.19h-7.19v7.5h-7.19v5.34Z" />
           </g>
         </svg>
      )}
      {(string === 'mis5') && (
         <svg className="missSvg miss5Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.54 102.83">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m706.16,102.83h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.19h26.76v-7.19h7.19v-19.73h-7.19v-7.19h-50.34V0h73.45v16.38h-57.07v19.57h43.15v7.19h7.19v7.19h7.19v38.11h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19v-34.11h-7.19v-7.19h-7.19v-7.19h-43.15V14.38h57.07V2h-69.45v48.34h50.34v7.19h7.19v23.73h-7.19v7.19h-30.76v-7.19h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'mis6') && (
         <svg className="missSvg miss6Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m706,102.67h-44.99v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v19.27h42.99v7.19h7.19v7.19h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-42.99-2h40.99v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-7.19h-7.19v-7.19h-42.99v-23.27h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.8-12.38h-30.61v-7.19h-7.19v-23.88h37.8v7.19h7.19v16.69h-7.19v7.19Zm-28.61-2h26.61v-7.19h7.19v-12.69h-7.19v-7.19h-33.8v19.88h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'mis7') && (
         <svg className="missSvg miss7Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m677.39,102.67h-16.38v-38.11h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-12.54h-58.29V0h74.67v37.8h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v28.92Zm-14.38-2h12.38v-28.92h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19V2h-70.67v12.08h58.29v16.54h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v34.11Z" />
           </g>
         </svg>
      )}
      {(string === 'mis8') && (
         <svg className="missSvg miss8Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.23 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m705.85,102.67h-44.69v-7.19h-7.19v-7.19h-7.19v-31.07h7.19v-12.23h-7.19V14.38h7.19v-7.19h7.19V0h44.69v7.19h7.19v7.19h7.19v30.61h-7.19v12.23h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-42.69-2h40.69v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-16.23h7.19v-26.61h-7.19v-7.19h-7.19V2h-40.69v7.19h-7.19v7.19h-7.19v26.61h7.19v16.23h-7.19v27.07h7.19v7.19h7.19v7.19Zm35.5-12.38h-30.3v-7.19h-7.19v-16.23h7.19v-7.04h30.3v7.04h7.19v16.23h-7.19v7.19Zm-28.3-2h26.3v-7.19h7.19v-12.23h-7.19v-7.04h-26.3v7.04h-7.19v12.23h7.19v7.19Zm28.3-41.3h-30.3v-7.19h-7.19v-16.23h7.19v-7.19h30.3v7.19h7.19v16.23h-7.19v7.19Zm-28.3-2h26.3v-7.19h7.19v-12.23h-7.19v-7.19h-26.3v7.19h-7.19v12.23h7.19v7.19Z" />
           </g>
         </svg>
      )}

      {(string === 'miss1') && (
         <svg className="missSvg miss1Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 822.68 102.67">
             <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m822.68,102.67h-73.91v-16.23h28.92V16.08h-28.76V0h45.15v86.44h28.61v16.23Zm-71.91-2h69.91v-12.23h-28.61V2h-41.15v12.08h28.76v74.37h-28.92v12.23Z" />
             </g>
         </svg>
      )}

      {(string === 'miss2') && (
         <svg className="missSvg miss2Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m820.54,102.67h-73.91v-44.99h7.19v-7.19h7.19v-7.19h35.96v-7.19h7.19v-12.54h-7.19v-7.19h-26.76v7.19h-7.19v7.19h-16.38V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v30.92h-7.19v7.19h-7.19v7.19h-35.96v7.19h-7.19v19.42h57.53v16.38Zm-71.91-2h69.91v-12.38h-57.53v-23.42h7.19v-7.19h35.96v-7.19h7.19v-7.19h7.19v-26.92h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v12.38h12.38v-7.19h7.19v-7.19h30.76v7.19h7.19v16.54h-7.19v7.19h-35.96v7.19h-7.19v7.19h-7.19v40.99Z" />
             </g>
         </svg>
      )}
      {(string === 'miss3') && (
         <svg className="missSvg miss3Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 819.31 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m804.93,102.67h-45.45v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.19h27.07v-7.19h7.19v-12.23h-7.19v-7.04h-28.3v-16.84h28.3v-7.19h7.19v-12.23h-7.19v-7.34h-27.07v7.34h-7.19v7.19h-16.38V14.38h7.19v-7.19h7.19V0h45.45v7.19h7.19v7.19h7.19v30.61h-7.19v12.23h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-43.45-2h41.45v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-16.23h7.19v-26.61h-7.19v-7.19h-7.19V2h-41.45v7.19h-7.19v7.19h-7.19v12.38h12.38v-7.19h7.19v-7.34h31.07v7.34h7.19v16.23h-7.19v7.19h-28.3v12.84h28.3v7.04h7.19v16.23h-7.19v7.19h-31.07v-7.19h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'miss4') && (
         <svg className="missSvg miss4Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m806.46,102.67h-16.69v-28.31h-43.15v-30.3h6.88v-7.5h7.34v-7.34h7.19v-7.5h7.19v-7.19h7.19v-7.19h7.19V0h16.84v58.14h14.08v16.23h-14.08v28.31Zm-14.69-2h12.69v-28.31h14.08v-12.23h-14.08V2h-12.84v7.34h-7.19v7.19h-7.19v7.19h-7.19v7.5h-7.19v7.34h-7.34v7.5h-6.88v26.3h43.15v28.31Zm-.15-40.53h-30.76v-9.34h7.19v-7.5h7.19v-7.19h7.19v-7.19h9.19v31.22Zm-28.76-2h26.76v-27.22h-5.19v7.19h-7.19v7.19h-7.19v7.5h-7.19v5.34Z" />
           </g>
         </svg>
      )}
      {(string === 'miss5') && (
         <svg className="missSvg miss5Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.54 102.83">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m806.16,102.83h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.19h26.76v-7.19h7.19v-19.73h-7.19v-7.19h-50.34V0h73.45v16.38h-57.07v19.57h43.15v7.19h7.19v7.19h7.19v38.11h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19v-34.11h-7.19v-7.19h-7.19v-7.19h-43.15V14.38h57.07V2h-69.45v48.34h50.34v7.19h7.19v23.73h-7.19v7.19h-30.76v-7.19h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'miss6') && (
         <svg className="missSvg miss6Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m806,102.67h-44.99v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v19.27h42.99v7.19h7.19v7.19h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-42.99-2h40.99v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-7.19h-7.19v-7.19h-42.99v-23.27h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.8-12.38h-30.61v-7.19h-7.19v-23.88h37.8v7.19h7.19v16.69h-7.19v7.19Zm-28.61-2h26.61v-7.19h7.19v-12.69h-7.19v-7.19h-33.8v19.88h7.19v7.19Z" />
           </g>
         </svg>
      )}
      {(string === 'miss7') && (
         <svg className="missSvg miss7Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.54 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m777.39,102.67h-16.38v-38.11h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-12.54h-58.29V0h74.67v37.8h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v28.92Zm-14.38-2h12.38v-28.92h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19v-7.19h7.19V2h-70.67v12.08h58.29v16.54h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v7.19h-7.19v34.11Z" />
           </g>
         </svg>
      )}
      {(string === 'miss8') && (
         <svg className="missSvg miss8Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820.23 102.67">
            <g fill="#ffffff">
               <path d="m73.91,102.67h-16.38V30.76h-5.19v7.19h-7.19v21.57h-16.38v-21.57h-7.04v-7.19h-5.34v71.91H0V0h16.38v7.19h7.19v7.19h7.19v7.19h12.38v-7.19h7.19v-7.19h7.19V0h16.38v102.67Zm-14.38-2h12.38V2h-12.38v7.19h-7.19v7.19h-7.19v7.19h-16.38v-7.19h-7.19v-7.19h-7.19V2H2v98.67h12.38V28.76h9.34v7.19h7.04v21.57h12.38v-21.57h7.19v-7.19h9.19v71.91Z" />
               <path d="m141.38,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m220.02,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m313.35,102.67h-45.15v-7.19h-7.19v-7.19h-7.19v-16.38h16.38v7.19h7.19v7.34h26.76v-7.19h7.19v-12.38h-7.19v-7.19h-35.96v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v16.38h-16.38v-7.19h-7.19v-7.19h-26.76v7.19h-7.19v12.54h7.19v7.19h35.96v7.19h7.19v7.19h7.19v30.76h-7.19v7.19h-7.19v7.04Zm-43.15-2h41.15v-7.04h7.19v-7.19h7.19v-26.76h-7.19v-7.19h-7.19v-7.19h-35.96v-7.19h-7.19v-16.54h7.19v-7.19h30.76v7.19h7.19v7.19h12.38v-12.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v26.92h7.19v7.19h7.19v7.19h35.96v7.19h7.19v16.38h-7.19v7.19h-30.76v-7.34h-7.19v-7.19h-12.38v12.38h7.19v7.19h7.19v7.19Z" />
               <path d="m392.3,102.67h-44.99v-16.38h14.38V16.38h-14.38V0h44.99v16.38h-14.23v69.91h14.23v16.38Zm-42.99-2h40.99v-12.38h-14.23V14.38h14.23V2h-40.99v12.38h14.38v73.91h-14.38v12.38Z" />
               <path d="m471.09,102.67h-45.15v-7.19h-7.19v-7.19h-7.19V14.38h7.19v-7.19h7.19V0h45.15v7.19h7.19v7.19h7.19v73.91h-7.19v7.19h-7.19v7.19Zm-43.15-2h41.15v-7.19h7.19v-7.19h7.19V16.38h-7.19v-7.19h-7.19V2h-41.15v7.19h-7.19v7.19h-7.19v69.91h7.19v7.19h7.19v7.19Zm35.96-12.38h-30.76v-7.19h-7.19V21.57h7.19v-7.19h30.76v7.19h7.19v59.53h-7.19v7.19Zm-28.76-2h26.76v-7.19h7.19V23.57h-7.19v-7.19h-26.76v7.19h-7.19v55.53h7.19v7.19Z" />
               <path d="m582.17,102.67h-16.54v-28.46h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.19h-7.19v-7.5h-5.19v64.72h-16.54V0h16.54v14.53h7.34v7.04h7.04v7.19h7.19v7.5h7.19v7.19h7.19v7.19h5.19V0h16.54v102.67Zm-14.54-2h12.54V2h-12.54v50.64h-9.19v-7.19h-7.19v-7.19h-7.19v-7.5h-7.19v-7.19h-7.04v-7.04h-7.34V2h-12.54v98.67h12.54V35.96h9.19v7.5h7.19v7.19h7.19v7.19h7.19v7.19h7.19v7.19h7.19v28.46Z" />
               <path d="m678.56,102.67h-73.91V0h73.91v16.08h-57.38v26.61h42.99v16.38h-42.99v27.38h57.38v16.23Zm-71.91-2h69.91v-12.23h-57.38v-31.38h42.99v-12.38h-42.99V14.08h57.38V2h-69.91v98.67Z" />
               <path d="m805.85,102.67h-44.69v-7.19h-7.19v-7.19h-7.19v-31.07h7.19v-12.23h-7.19V14.38h7.19v-7.19h7.19V0h44.69v7.19h7.19v7.19h7.19v30.61h-7.19v12.23h7.19v31.07h-7.19v7.19h-7.19v7.19Zm-42.69-2h40.69v-7.19h7.19v-7.19h7.19v-27.07h-7.19v-16.23h7.19v-26.61h-7.19v-7.19h-7.19V2h-40.69v7.19h-7.19v7.19h-7.19v26.61h7.19v16.23h-7.19v27.07h7.19v7.19h7.19v7.19Zm35.5-12.38h-30.3v-7.19h-7.19v-16.23h7.19v-7.04h30.3v7.04h7.19v16.23h-7.19v7.19Zm-28.3-2h26.3v-7.19h7.19v-12.23h-7.19v-7.04h-26.3v7.04h-7.19v12.23h7.19v7.19Zm28.3-41.3h-30.3v-7.19h-7.19v-16.23h7.19v-7.19h30.3v7.19h7.19v16.23h-7.19v7.19Zm-28.3-2h26.3v-7.19h7.19v-12.23h-7.19v-7.19h-26.3v7.19h-7.19v12.23h7.19v7.19Z" />
           </g>
         </svg>

      )}


      {(string === 'diff1') && (
         <svg className="diffSvg diff1Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 47">
             <g fill="#0ad400">
               <rect x="9" y="8" width="15.12" height="30"  />
               <rect x="5" width="58" height="5"  />
               <rect x="-16" y="21" width="37" height="5" transform="translate(26 21) rotate(90)"  />
               <rect x="47" y="21" width="37" height="5" transform="translate(89 -42) rotate(90)"  />
               <rect x="5" y="42" width="58" height="5"  />
               <path d="m44.94,8v30h15.12V8h-15.12Zm13.81,28.75h-12.5V9.25h12.5v27.5Z"  />
               <path d="m26.94,8v30h15.12V8h-15.12Zm13.81,28.75h-12.5V9.25h12.5v27.5Z"  />
             </g>
         </svg>
      )}
      {(string === 'diff2') && (
         <svg className="diffSvg diff2Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 47">
             <g fill="#0ad400">
               <rect x="9" y="8" width="15.12" height="30"  />
               <rect x="27" y="8" width="15.12" height="30"  />
               <path d="m44.94,8v30h15.12V8h-15.12Zm13.81,28.75h-12.5V9.25h12.5v27.5Z"  />
               <rect x="5" width="58" height="5"  />
               <rect x="-16" y="21" width="37" height="5" transform="translate(26 21) rotate(90)"  />
               <rect x="47" y="21" width="37" height="5" transform="translate(89 -42) rotate(90)"  />
               <rect x="5" y="42" width="58" height="5"  />
             </g>
         </svg>
      )}
      {(string === 'diff3') && (
         <svg className="diffSvg diff3Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 47">
             <g fill="#0ad400">
               <rect x="9" y="8" width="15.12" height="30"  />
               <rect x="26.94" y="8" width="15.12" height="30"  />
               <rect x="44.88" y="8" width="15.12" height="30"  />
               <rect x="5" width="58" height="5"  />
               <rect x="-16" y="21" width="37" height="5" transform="translate(26 21) rotate(90)"  />
               <rect x="47" y="21" width="37" height="5" transform="translate(89 -42) rotate(90)"  />
               <rect x="5" y="42" width="58" height="5"  />
             </g>
         </svg>
      )}
      {(string === 'pin') && (
         <svg className="pinSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.7 58.95">
            <g fill="#0ad400">
               <path d="m42.7,8.42h-4.21v-4.21h-4.21V0H9.01v4.21h-4.21v4.21H0v21.1h0v12.59h4.57v4.21h4.21v4.21h4.21v4.21h4.21v4.21h8.42v-4.21h4.3v-4.21h4.21v-4.21h4.12v-4.21h4.44v-12.91h0V8.42Zm-8.56,29.48h-4.21v4.21h-4.3v4.21h-8.42v-4.21h-4.21v-4.21h-4.21v-8.38h0V12.63h4.44v-4.21h16.84v4.21h4.07v10.79h0v14.48Z" />
               <rect x="16.85" y="21.98" width="9" height="9" />
             </g>
         </svg>
      )}

      {(string === 'face') && (
         <svg className="faceSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 400">
            <g>
               <rect className="f1" x="100" y="0" width="50" height="50" />
               <rect className="f2" x="150" y="0" width="50" height="50" />
               <rect className="f3" x="200" y="0" width="50" height="50" />

               <rect className="fronte" x="0" y="50" width="50" height="50" />
               <rect className="fronte" x="50" y="50" width="50" height="50" />
               <rect className="fronte" x="100" y="50" width="50" height="50" />
               <rect className="fronte" x="150" y="50" width="50" height="50" />
               <rect className="fronte" x="200" y="50" width="50" height="50" />
               <rect className="fronte" x="250" y="50" width="50" height="50" />
               <rect className="fronte" x="300" y="50" width="50" height="50" />

               <rect className="os1" x="50" y="100" width="50" height="50" />
               <rect className="os2" x="100" y="100" width="50" height="50" />
               <rect className="os3" x="50" y="150" width="50" height="50" />

               <rect className="od1" x="200" y="100" width="50" height="50" />
               <rect className="od2" x="250" y="100" width="50" height="50" />
               <rect className="od3" x="250" y="150" width="50" height="50" />

               <rect className="naso" x="150" y="100" width="50" height="50" />
               <rect className="naso" x="150" y="150" width="50" height="50" />
               <rect className="naso" x="150" y="200" width="50" height="50" />

               <rect className="b1" x="50" y="250" width="50" height="50" />
               <rect className="b2" x="250" y="250" width="50" height="50" />

               <rect className="b3" x="100" y="300" width="50" height="50" />
               <rect className="b4" x="150" y="300" width="50" height="50" />
               <rect className="b5" x="200" y="300" width="50" height="50" />

               <rect className="b6" x="50" y="350" width="50" height="50" />
               <rect className="b7" x="250" y="350" width="50" height="50" />


             </g>
         </svg>

      )}



      {(string === 'time') && (
        <svg className="timeSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167 167">
          <g>
            <circle cx="83.5" cy="83.5" r="82.5" fill="#96c38f" stroke="#000" strokeMiterlimit="10" strokeWidth="2"/>
            <g>
              <path d="M84.657,130c-26.446,.106-48.49-21.557-48.48-48.309,.009-26.778,21.026-48.476,48.481-48.462,26.561,.013,47.921,20.986,48.269,48.205,.337,26.382-21.778,48.46-48.27,48.566Zm44.391-48.282c.003-24.961-19.68-44.7-44.56-44.688-24.753,.012-44.488,19.757-44.488,44.511,0,24.778,19.679,44.502,44.441,44.541,24.791,.039,44.603-19.665,44.606-44.364Z" fill="#fff"/>
              <path d="M74.285,84.222c-3.107-.001-6.214-.007-9.321,0-1.815,.004-3.229-.581-3.243-2.646-.014-2.062,1.386-2.687,3.205-2.688,4.954-.004,9.914-.142,14.861,.05,2.466,.096,3.766-.844,4.921-2.931,3.821-6.904,7.836-13.7,11.779-20.537,.377-.655,.76-1.306,1.152-1.952,.931-1.532,2.16-2.483,3.947-1.445,1.784,1.036,1.598,2.564,.693,4.133-3.355,5.818-6.709,11.637-10.068,17.453-1.511,2.617-3.128,5.18-4.517,7.86-1.011,1.95-2.354,2.847-4.594,2.738-2.932-.143-5.877-.035-8.816-.037Z" fill="#fff"/>
              <path d="M87.154,47.663c0,.917,.062,1.84-.014,2.75-.13,1.547-.983,2.471-2.578,2.489-1.592,.018-2.538-.879-2.635-2.422-.115-1.827-.111-3.671-.008-5.499,.086-1.527,.988-2.494,2.573-2.513,1.58-.018,2.473,.909,2.635,2.445,.096,.907,.018,1.832,.018,2.749,.003,0,.007,0,.01,0Z" fill="#fff"/>
              <path d="M50.705,84.181c-.917,.003-1.84,.068-2.751-.011-1.542-.133-2.489-1.005-2.517-2.576-.028-1.58,.906-2.533,2.439-2.635,1.827-.121,3.671-.109,5.501-.014,1.539,.08,2.475,.976,2.494,2.573,.019,1.588-.862,2.491-2.416,2.644-.908,.09-1.833,.016-2.75,.02Z" fill="#fff"/>
              <path d="M118.23,84.172c-.917-.004-1.843,.08-2.749-.023-1.435-.162-2.266-1.037-2.307-2.494-.044-1.581,.81-2.585,2.36-2.688,1.909-.127,3.839-.128,5.748,.005,1.411,.098,2.288,1.03,2.326,2.486,.041,1.576-.843,2.514-2.379,2.695-.987,.117-1.998,.022-2.999,.018Z" fill="#fff"/>
              <path d="M87.12,115.411c0,1,.111,2.015-.026,2.996-.197,1.412-1.106,2.245-2.562,2.244-1.45,0-2.439-.818-2.568-2.232-.166-1.82-.125-3.666-.074-5.498,.046-1.648,.899-2.706,2.629-2.707,1.722,0,2.556,1.036,2.643,2.695,.043,.832,.008,1.668,.008,2.502h-.049Z" fill="#fff"/>
            </g>
          </g>
        </svg>
      )}

      {(string === 'logo') && (
         <svg className="logoSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1690.78 497.88">
           <g>
             <g className="bg">
               <rect x="124.86" width="1565.92" height="294.86" fill="#e500ff" />
               <rect y="190.17" width="1596.37" height="124.79" fill="#0ad400" />
               <rect x="71.08" y="203.03" width="1192.36" height="294.86" fill="#0ad400" />
             </g>
             <g className="level1">
               <rect x="152.69" y="144.34" width="68.81" height="11.62" fill="#00d9e5" />
               <rect x="1143.8" y="389.36" width="68.81" height="5.81" fill="#00d9e5" />
               <rect x="450.55" y="87.68" width="68.81" height="5.81" fill="#f0f" />
               <rect x="1462.48" y="140.04" width="68.81" height="5.81" fill="#f0f" />
               <rect x="1293.3" y="168.67" width="68.81" height="5.81" fill="#f0f" />
               <rect x="773.2" y="369.55" width="68.81" height="5.81" fill="blue" />
               <rect x="1168.77" y="229.41" width="68.81" height="5.81" fill="#f0f" />
               <rect x="1117.24" y="330.97" width="68.81" height="5.81" fill="#f0f" />
               <rect x="309.89" y="338.09" width="68.81" height="5.81" fill="#f0f" />
               <rect x="164.13" y="387.55" width="68.81" height="5.81" fill="#f0f" />
               <rect x="360.89" y="245.93" width="68.81" height="5.81" fill="#f0f" />
               <rect x="309.43" y="212.62" width="68.81" height="11.62" fill="#00d9e5" />
               <rect x="449.98" y="103.3" width="68.81" height="11.62" fill="#00d9e5" />
               <rect x="1006.99" y="147.55" width="68.81" height="11.62" fill="#00d9e5" />
               <rect x="1121.5" y="290.35" width="68.81" height="5.81" fill="#f0f" />
               <rect x="885.5" y="164.35" width="68.81" height="5.81" fill="#f0f" />
             </g>
             <g className="level2">
                <rect x="87.2" y="274.08" width="751.16" height="68.71" fill="#59c8ff" />
                <rect x="439.47" y="376.76" width="459.19" height="31.46" fill="#009245" />
                <rect x="571.5" y="321.44" width="459.19" height="40.28" fill="#39b54a" />
             </g>
             <g className="text">
               <path d="m270.07,132.2h-11.43v11.43h-56.92v-45.73h56.92v11.43h11.43v22.87Zm11.43-34.3v-11.43h-11.43v-11.43h-91.46v101.71h23.11v-10.26h68.35v-11.43h11.43v-11.43h11.43v-45.73h-11.43Z" fill="#fff" />
               <rect x="324.58" y="75.04" width="22.87" height="101.71" fill="#fff" />
               <rect x="424.67" y="212.47" width="3.05" height="22.62" fill="#fff" />
               <path d="m481.73,132.2h11.43v-11.43h11.43v-11.43h22.86v11.43h11.68v11.43h11.43v29.67h-68.84v-29.67Zm91.7,44.55v-55.98h-11.68v-11.43h-11.18v-11.43h-11.43v-11.43h-11.68v-11.43h-22.86v11.43h-11.43v11.43h-11.43v11.43h-11.43v11.43h-11.19v55.98h114.32Z" fill="#fff" />
               <polygon points="635.25 176.75 635.25 132.2 646.68 132.2 646.68 144.12 658.11 144.12 658.11 155.55 669.54 155.55 669.54 166.98 680.98 166.98 680.98 176.75 726.95 176.75 726.95 75.04 703.84 75.04 703.84 155.55 692.41 155.55 692.41 144.12 680.98 144.12 680.98 132.69 669.54 132.69 669.54 120.77 658.11 120.77 658.11 109.33 646.93 109.33 646.93 98.15 635.25 98.15 635.25 75.04 612.14 75.04 612.14 176.75 635.25 176.75" fill="#fff" />
               <polygon points="789 176.75 789 165.77 857.35 165.77 857.35 142.9 789 142.9 789 97.42 880.21 97.42 880.21 75.04 765.88 75.04 765.88 176.75 789 176.75" fill="#fff" />
               <polygon points="946.15 176.75 969.01 176.75 969.01 97.42 1014.5 97.42 1014.5 75.04 899.69 75.04 899.69 97.42 946.15 97.42 946.15 176.75" fill="#fff" />
               <path d="m1048.81,132.2h11.43v-11.43h11.43v-11.43h22.87v11.43h11.67v11.43h11.43v29.67h-68.84v-29.67Zm-21.48,44.55h113.18v-55.98h-11.68v-11.43h-11.19v-11.43h-11.43v-11.43h-11.67v-11.43h-22.87v11.43h-11.43v11.43h-11.43v11.43h-11.43v11.43h-11.19v114.32h1.15v-58.34Z" fill="#fff" />
               <path d="m1202.08,97.91h57.16v11.43h11.43v22.87h-11.43v11.18h-57.16v-45.48Zm0,72.76v-4.41h11.68v4.41h34.29v-4.41h22.63v-11.19h11.43v-11.43h11.43v-45.73h-11.43v-11.43h-11.43v-11.43h-91.46v95.63h22.86Z" fill="#fff" />
               <rect x="1293.42" y="212.23" width=".36" height="22.86" fill="#fff" />
               <polygon points="1353.16 170.67 1376.02 170.67 1376.02 97.91 1398.64 97.91 1398.64 75.04 1330.29 75.04 1330.29 97.91 1353.16 97.91 1353.16 170.67" fill="#fff" />
               <polygon points="1455.34 170.67 1455.34 109.34 1466.77 109.34 1466.77 97.91 1512.5 97.91 1512.5 109.34 1523.93 109.34 1523.93 170.67 1546.8 170.67 1546.8 97.91 1535.36 97.91 1535.36 86.47 1523.93 86.47 1523.93 75.04 1455.34 75.04 1455.34 86.47 1443.9 86.47 1443.9 97.91 1432.47 97.91 1432.47 170.67 1455.34 170.67" fill="#fff" />
               <polygon points="192.35 176.11 192.35 247.06 215.46 247.06 215.46 178.47 283.81 178.47 283.81 176.11 192.35 176.11" fill="#fff" />
               <polygon points="361.18 224.44 361.18 176.11 338.31 176.11 338.31 247.06 441.45 247.06 441.45 224.44 361.18 224.44" fill="#fff" />
               <polygon points="472.85 176.11 472.85 247.06 495.48 247.06 495.48 196.23 564.07 196.23 564.07 247.06 587.17 247.06 587.17 176.11 472.85 176.11" fill="#fff" />
               <rect x="625.87" y="176.12" width="23.11" height="70.95" fill="#fff" />
               <polygon points="683.28 176.11 683.28 178.95 694.71 178.95 694.71 190.39 706.15 190.39 706.15 201.82 717.58 201.82 717.58 247.06 740.69 247.06 740.69 176.11 683.28 176.11" fill="#fff" />
               <polygon points="802.73 177.74 871.08 177.74 871.08 176.12 779.62 176.12 779.62 247.06 893.95 247.06 893.95 224.44 802.73 224.44 802.73 177.74" fill="#fff" />
               <rect x="959.89" y="176.12" width="22.86" height="70.95" fill="#fff" />
               <rect x="913.43" y="87.01" width="1.35" height="22.38" fill="#fff" />
               <polygon points="1041.07 176.11 1041.07 132.74 1039.93 132.74 1039.93 247.06 1062.55 247.06 1062.55 196.23 1131.14 196.23 1131.14 247.06 1154.25 247.06 1154.25 176.11 1041.07 176.11" fill="#fff" />
               <polygon points="1296.08 224.2 1296.08 212.76 1284.66 212.76 1284.66 201.33 1273.22 201.33 1273.22 189.9 1261.79 189.9 1261.79 178.23 1284.41 178.23 1284.41 170.03 1192.95 170.03 1192.95 247.06 1215.82 247.06 1215.82 178.23 1227.49 178.23 1227.49 189.9 1238.92 189.9 1238.92 201.33 1250.35 201.33 1250.35 212.76 1261.79 212.76 1261.79 224.2 1273.22 224.2 1273.22 235.63 1284.66 235.63 1284.66 247.06 1307.52 247.06 1307.52 224.2 1296.08 224.2" fill="#fff" />
               <polygon points="1389.76 170.03 1366.89 170.03 1366.89 224.2 1344.03 224.2 1344.03 247.06 1412.38 247.06 1412.38 224.2 1389.76 224.2 1389.76 170.03" fill="#fff" />
               <polygon points="1537.67 170.03 1537.67 212.76 1526.24 212.76 1526.24 224.2 1480.51 224.2 1480.51 212.76 1469.08 212.76 1469.08 170.03 1446.21 170.03 1446.21 224.2 1457.64 224.2 1457.64 235.63 1469.08 235.63 1469.08 247.06 1537.67 247.06 1537.67 235.63 1549.1 235.63 1549.1 224.2 1560.53 224.2 1560.53 170.03 1537.67 170.03" fill="#fff" />
               <path d="m201.76,353.86h56.92v-11.43h11.43v-22.86h-11.43v-11.43h-56.92v45.73Zm-23.11,91.46v-160.05h91.46v11.43h11.43v11.43h11.43v45.73h-11.43v11.43h-11.43v11.43h-68.35v68.6h-23.11Z" fill="#fff" />
               <polygon points="438.94 427.01 438.94 449.63 324.62 449.63 324.62 289.58 438.94 289.58 438.94 311.96 347.72 311.96 347.72 357.44 416.08 357.44 416.08 380.31 347.72 380.31 347.72 427.01 438.94 427.01" fill="#fff" />
               <path d="m496.61,357.93h57.16v-11.19h11.43v-22.86h-11.43v-11.43h-57.16v45.49Zm-22.87,91.7v-160.05h91.46v11.43h11.43v11.43h11.43v45.73h-11.43v11.43h-11.43v11.19h-22.62v11.67h11.43v11.43h11.43v11.43h11.43v11.43h11.43v22.86h-22.86v-11.43h-11.43v-11.43h-11.43v-11.43h-11.43v-11.43h-11.43v-11.43h-11.43v-11.67h-11.67v68.84h-22.87Z" fill="#fff" />
               <path d="m704.85,415.33h11.43v-91.46h-11.43v-11.43h-56.92v114.32h56.92v-11.43Zm-80.03,34.3v-160.05h91.46v11.43h11.43v11.43h11.43v114.32h-11.43v11.43h-11.43v11.43h-91.46Z" fill="#fff" />
               <polygon points="800.95 438.19 789.51 438.19 789.51 426.76 778.08 426.76 778.08 289.58 800.95 289.58 800.95 415.33 812.38 415.33 812.38 426.76 858.11 426.76 858.11 415.33 869.55 415.33 869.55 289.58 892.16 289.58 892.16 426.76 880.97 426.76 880.97 438.19 869.55 438.19 869.55 449.63 800.95 449.63 800.95 438.19" fill="#fff" />
               <polygon points="962.24 449.63 962.24 311.96 915.78 311.96 915.78 289.58 1030.59 289.58 1030.59 311.96 985.11 311.96 985.11 449.63 962.24 449.63" fill="#fff" />
               <path d="m1077.31,415.33h11.43v11.43h45.73v-11.43h11.43v-91.46h-11.43v-11.43h-45.73v11.43h-11.43v91.46Zm-11.43,22.86v-11.43h-11.43v-114.32h11.43v-11.43h11.43v-11.43h68.59v11.43h11.43v11.43h11.43v114.32h-11.43v11.43h-11.43v11.43h-68.59v-11.43h-11.43Z" fill="#fff" />
             </g>
             <g className="level1">
                <rect x="800.78" y="400.08" width="98" height="8" fill="#004437" />
                <rect x="667.78" y="98.61" width="579" height="29.47" fill="#c1272d" opacity=".52" />
                <rect x="129.16" y="115.98" width="504.62" height="28.1" fill="#1b1464" opacity=".52" />
                <rect x="76.39" y="330.09" width="684.16" height="23.57" fill="#fcee21" opacity=".71" />
                <rect x="762.78" y="400.08" width="486" height="49" fill="aqua" opacity=".52" />
                <rect x="323.78" y="364.08" width="345" height="17" fill="#1b1464" opacity=".52" />
             </g>
           </g>
         </svg>
      )}

      {(string === 'ics') && (
        <svg className="icsSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 14.4">
          <g>
            <path className="d" d="M7.2,7.86c-.17,0-.34-.06-.47-.19L.19,1.13C-.06,.87-.06,.45,.19,.19S.87-.06,1.13,.19L7.67,6.73c.26,.26,.26,.68,0,.93-.13,.13-.3,.19-.47,.19Z"/>
            <path className="d" d="M.66,14.4c-.17,0-.34-.06-.47-.19-.26-.26-.26-.68,0-.93L6.74,6.73c.26-.26,.68-.26,.93,0s.26,.68,0,.93L1.13,14.21c-.13,.13-.3,.19-.47,.19Z"/>
          </g>
          <g>
            <path className="d" d="M7,7.86c.17,0,.34-.06,.47-.19L14.01,1.13c.26-.26,.26-.68,0-.93s-.68-.26-.93,0L6.53,6.73c-.26,.26-.26,.68,0,.93,.13,.13,.3,.19,.47,.19Z"/>
            <path className="d" d="M13.54,14.4c.17,0,.34-.06,.47-.19,.26-.26,.26-.68,0-.93L7.47,6.73c-.26-.26-.68-.26-.93,0s-.26,.68,0,.93l6.54,6.54c.13,.13,.3,.19,.47,.19Z"/>
          </g>
        </svg>
      )}

      {(string === 'freccia') && (
        <svg className="frecciaSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.83 14.4">
          <g>
            <path className="d" d="M56.16,7.86H.66c-.37,0-.66-.3-.66-.66s.3-.66,.66-.66H56.16c.37,0,.66,.3,.66,.66s-.3,.66-.66,.66Z"/>
            <path className="d" d="M56.16,7.86c-.17,0-.34-.06-.47-.19l-6.54-6.54c-.26-.26-.26-.68,0-.93s.68-.26,.93,0l6.54,6.54c.26,.26,.26,.68,0,.93-.13,.13-.3,.19-.47,.19Z"/>
            <path className="d" d="M49.62,14.4c-.17,0-.34-.06-.47-.19-.26-.26-.26-.68,0-.93l6.54-6.54c.26-.26,.68-.26,.93,0s.26,.68,0,.93l-6.54,6.54c-.13,.13-.3,.19-.47,.19Z"/>
          </g>
        </svg>
      )}

      {(string === 'volon') && (
      <svg className="svgVolon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.47 219.92">
        <g>
          <path d="M150.23,110.27c0,32.65-.03,65.29,.03,97.94,0,4.67-.99,8.69-5.58,10.76-4.68,2.11-8.58,.52-12.3-2.71-20.39-17.66-40.81-35.29-61.4-52.72-2.16-1.83-5.5-3.14-8.32-3.2-14.98-.31-29.98-.16-44.97-.15-11.07,.01-17.66-6.54-17.67-17.72-.02-21.49-.03-42.97,0-64.46,.02-11.91,6.3-18.19,18.2-18.21,14.66-.03,29.32-.1,43.97,.06,3.53,.04,6.22-.83,8.96-3.22,20.21-17.62,40.58-35.04,60.87-52.57,3.74-3.23,7.62-5.48,12.66-3.06,4.79,2.31,5.65,6.55,5.65,11.3-.04,23.99-.02,47.97-.03,71.96,0,8.66,0,17.32,0,25.98-.02,0-.04,0-.06,0Z"/>
          <path d="M282.47,109.96c-.85,39.89-14.52,74.5-41.77,103.63-.57,.61-1.16,1.19-1.77,1.76-3.14,2.91-6.49,3.35-9.81,.43-3.09-2.73-2.7-6.39,.31-10.18,6.72-8.44,14.14-16.5,19.71-25.66,32.08-52.74,24.22-120.16-18.69-164.53-4.14-4.28-4.6-8.22-1.32-11.28,3.04-2.84,7.07-2.37,10.91,1.66,27.79,29.18,41.55,64.09,42.43,104.15Z"/>
          <path d="M241.76,103.95c-.71,34.85-10.58,60.14-30.49,81.38-3.7,3.94-7.89,4.54-10.9,1.77-3.28-3.02-2.86-7.07,1.17-11.32,35.1-37.05,35.27-94.1,.37-131.23-.46-.49-.94-.95-1.38-1.44-2.87-3.15-3.33-6.51-.47-9.83,2.63-3.04,6.85-2.93,10.15,.4,11.68,11.78,20.59,25.47,25.28,41.35,3.32,11.24,5.02,22.96,6.27,28.92Z"/>
          <path d="M200.77,109.92c-.36,17.95-6.4,33.57-18.57,46.77-2.76,3-5.9,4.47-9.74,2.22-4.01-2.35-4.07-7.19-.17-11.49,11.25-12.42,16.25-26.94,14.48-43.63-1.29-12.18-6.51-22.59-14.77-31.57-3.54-3.85-3.52-8.38,.02-10.82,3.89-2.68,7.19-1.33,10.13,1.87,12.13,13.21,18.25,28.8,18.61,46.65Z"/>
        </g>
      </svg>
      )}

      {(string === 'voloff') && (
        <svg className="svgVoloff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.49 219.95">
          <g>
            <path d="M150.21,110.15c0,32.65-.03,65.3,.03,97.94,.01,5.1-1.25,9.22-6.44,11.2-4.52,1.73-7.98-.2-11.33-3.1-20.54-17.74-41.09-35.48-61.8-53.02-2.04-1.73-5.23-2.9-7.91-2.95-15.15-.28-30.31-.11-45.47-.15-10.63-.03-17.19-6.44-17.24-17.08-.09-21.99-.08-43.97,0-65.96,.04-10.94,6.48-17.19,17.58-17.22,14.99-.04,29.98,.14,44.97-.13,2.7-.05,5.92-1.17,7.97-2.9,20.46-17.32,40.75-34.84,61.02-52.39,3.87-3.35,7.78-5.85,13.04-3.45,5.38,2.45,5.62,7.25,5.61,12.26-.04,32.31-.02,64.63-.02,96.94h-.02Z"/>
            <path d="M207.58,109.99c6.36,6.2,12.42,12.1,18.46,18.01,1.43,1.4,2.89,2.77,4.19,4.28,3.08,3.59,2.99,7.77-.12,10.7-2.95,2.78-7.02,2.9-10.29-.24-6.73-6.45-13.25-13.12-19.85-19.69-.7-.7-1.43-1.37-2.85-2.72-6.07,6.23-12.06,12.37-18.06,18.5-1.4,1.43-2.76,2.9-4.29,4.18-3.41,2.84-7.43,2.68-10.31-.26-2.91-2.97-3.11-7.04,0-10.29,6.21-6.49,12.67-12.76,19-19.13,1.04-1.04,1.99-2.17,3.54-3.88-5.86-5.65-11.59-11.17-17.31-16.7-1.44-1.39-2.88-2.77-4.25-4.23-3.82-4.06-4.19-8.31-1.03-11.48,3.05-3.07,7.75-2.79,11.51,.89,6.07,5.94,12.03,11.98,18.05,17.96,1.06,1.05,2.15,2.06,3.88,3.7,7.01-7.22,13.95-14.34,20.85-21.5,2.81-2.92,6.01-4.27,9.85-2.33,4.71,2.38,5.19,8.27,.92,12.6-7.02,7.09-14.19,14.04-21.89,21.63Z"/>
          </g>
        </svg>
      )}

      {(string === 'logoMarimento') && (
         <svg className="logoMarimentoSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.93 38.9">
             <g>
               <path d="M102.62,24.72c0-3.33-.02-6.67,0-10,0-1.47.3-2.87,1.79-3.59,1.47-.71,2.79-.3,3.86.82.77.8,1.41.49,2.21.09,4.34-2.14,8.51-2.17,12.22,1.34.87.83,1.44.5,2.1-.14,2.05-1.98,4.57-2.6,7.34-2.56,4.99.06,8.17,2.68,8.9,7.66.81,5.55.56,11.14.39,16.72-.06,2.02-.93,3.6-3.31,3.56-2.44-.03-3.04-1.68-3.06-3.72-.03-4.25,0-8.5-.03-12.75-.04-4.26-1.12-5.86-3.99-6.05-3.07-.21-5.59,2.18-5.78,5.93-.21,4.24-.09,8.5-.12,12.74-.01,1.46-.19,2.88-1.72,3.6-2.21,1.04-4.37-.36-4.43-3-.1-4-.01-8-.05-12-.01-1.16-.11-2.33-.27-3.48-.27-1.86-1.06-3.38-3.13-3.7-2.15-.33-3.95.3-5.23,2.14-.94,1.36-1.21,2.9-1.21,4.51,0,3.92,0,7.83-.02,11.75,0,2.07-.37,3.91-2.98,4.06-2.12.12-3.39-1.26-3.46-3.96-.08-3.33-.02-6.67-.02-10Z"/>
               <path d="M0,24.65C0,21.32,0,17.99,0,14.66c0-1.49.28-2.84,1.8-3.54,1.39-.64,2.63-.28,3.63.78.72.76,1.34.66,2.2.22,4.3-2.19,8.47-2.35,12.19,1.21,1.14,1.09,1.73.18,2.5-.39,4.03-3,9.57-3.13,12.99-.33,2.32,1.9,3.11,4.56,3.21,7.33.18,5.07.1,10.16.07,15.23-.01,1.98-.8,3.42-3.06,3.45-2.04.03-3.01-1.11-3.09-3.64-.16-4.9.29-9.82-.3-14.72-.25-2.08-.93-3.79-3.27-4.08-2.34-.3-4.25.5-5.47,2.64-.69,1.2-.83,2.52-.83,3.86,0,3.41,0,6.83-.02,10.24,0,.75.02,1.5-.05,2.25-.23,2.34-1.49,3.66-3.4,3.49-2.16-.19-3-1.58-3.01-3.58-.03-4.75.01-9.49-.07-14.24-.04-2.2-.62-4.18-3.22-4.67-2.43-.46-4.89.88-5.86,3.25-.57,1.4-.75,2.89-.75,4.41.01,3.16,0,6.33,0,9.49,0,.67,0,1.33-.02,2-.04,2.08-.97,3.34-3.17,3.3-2.08-.04-2.97-1.28-2.99-3.23-.03-3.58,0-7.16,0-10.74,0,0,0,0,0,0Z"/>
               <path d="M160.7,26.86c-2.17,0-4.33,0-6.5,0-1.93,0-2.29.91-1.59,2.57,1.42,3.34,6,4.93,10.39,3.5,1.02-.33,1.99-.85,2.95-1.33,1.51-.76,2.94-.95,3.85.79.87,1.65.04,2.85-1.32,3.81-4.68,3.33-12.16,3.61-16.83.61-4.74-3.04-6.92-8.63-5.86-14.89.85-5.02,3.56-8.69,8.29-10.43,4.46-1.64,8.88-1.23,12.66,2.08,3.23,2.83,4.03,6.6,3.84,10.64-.09,1.84-1.5,2.51-3.14,2.57-2.25.07-4.5.02-6.75.02,0,.02,0,.04,0,.06ZM158.5,21.79c1.57,0,3.15-.02,4.72,0,1.45.02,1.65-.67,1.27-1.91-.78-2.6-2.64-3.97-5.57-4-3.11-.03-5.57,1.61-6.38,4.16-.4,1.24.05,1.72,1.24,1.73,1.57.01,3.15,0,4.72,0Z"/>
               <path d="M66.16,27.58c0,2.66.01,5.32,0,7.99-.01,1.24-.42,2.34-1.67,2.82-1.23.47-2.6.52-3.46-.57-.95-1.2-1.7-.75-2.75-.24-4.57,2.25-10.09,1.27-13.02-2.23-3.77-4.5-2.12-10.53,3.41-12.44,2.63-.91,5.36-1.06,8.11-1.18,1.21-.05,3.09.77,3.21-1.61.1-2.09-1.24-3.76-3.27-4.04-1.85-.25-3.65.01-5.39.68-1.01.39-1.99.84-3.02,1.19-1.46.5-2.67.11-3.34-1.32-.68-1.44-.19-2.69,1.13-3.41,4.47-2.43,9.25-3.48,14.18-1.86,3.92,1.29,5.64,4.5,5.84,8.48.13,2.57.02,5.16.02,7.74,0,0,.01,0,.02,0ZM54.04,33.7c2.88-.06,5.2-2.06,5.81-4.92.35-1.64-.21-2.4-1.91-2.26-1.57.13-3.16.17-4.72.4-2.83.42-3.8,1.44-3.54,3.51.25,1.96,2.05,3.32,4.35,3.27Z"/>
               <path d="M233.88,10.7c7.89,0,13.06,5.62,13.05,14.18-.01,8.2-5.39,13.93-13.09,13.99-7.67.05-13.2-5.88-13.23-14.2-.03-8.38,5.28-13.97,13.27-13.97ZM227,24.62c.14,1.15.17,2.33.44,3.44.89,3.58,3.09,5.31,6.54,5.28,3.22-.03,5.32-1.85,6.17-5.4.59-2.47.54-4.95-.21-7.38-.95-3.04-3.82-4.82-6.94-4.38-3.95.56-5.88,3.32-6.01,8.43Z"/>
               <path d="M174.62,24.55c0-3.24-.01-6.49,0-9.73,0-1.57.32-3.03,1.97-3.71,1.61-.66,2.95-.19,3.91,1.22.8,1.17,1.41.3,2.05-.11,3.12-1.97,6.57-2.03,9.87-1.04,4.18,1.26,5.95,4.7,6.1,8.83.19,5.07.09,10.15.06,15.22-.01,1.99-.84,3.42-3.1,3.39-2.23-.02-3.03-1.38-3.05-3.42-.05-4.57.05-9.16-.24-13.72-.26-4.21-1.51-5.41-4.67-5.4-3.62.01-6.22,2.35-6.37,6-.15,3.66-.05,7.32-.07,10.98,0,.83,0,1.67-.05,2.49-.12,1.79-.92,3.02-2.88,3.11-2.02.09-3.23-1.01-3.39-2.9-.21-2.65-.13-5.32-.16-7.98-.01-1.08,0-2.16,0-3.24,0,0,0,0,.01,0Z"/>
               <path d="M215.62,38.9c-6.19-.06-9.87-3.54-10.07-9.27-.13-3.58-.09-7.16-.03-10.74.02-1.6-.41-2.5-2.21-2.54-1.61-.04-3.05-.7-3.07-2.59-.02-1.88,1.34-2.57,3-2.66,1.86-.1,2.4-1.12,2.28-2.81-.07-.91-.04-1.84.1-2.74.26-1.65,1.36-2.44,2.98-2.43,1.61.01,2.65.78,3.02,2.42.37,1.67-.72,3.77.78,4.98,1.29,1.05,3.24.23,4.87.55,1.52.3,2.79.88,2.75,2.66-.04,1.73-1.28,2.39-2.82,2.62-.08.01-.17.02-.25.03q-5.25.61-5.24,5.98c0,2.25-.09,4.51.1,6.74.28,3.19,1.83,4.42,5.02,4.07,1.79-.2,3.63-.72,4.1,1.83.35,1.89-.88,3.15-3.51,3.69-.73.15-1.48.19-1.79.23Z"/>
               <path d="M71.96,24.73c0-3.49,0-6.98,0-10.47,0-1.42.34-2.65,1.84-3.21,1.41-.52,2.71-.28,3.56.94.76,1.09,1.38.78,2.25.22,1.72-1.11,3.63-1.59,5.69-1.52,1.52.05,2.59.65,2.8,2.25.2,1.53-.5,2.58-1.96,3.06-.63.21-1.29.32-1.94.43-4.08.67-5.91,2.71-6,6.91-.08,3.82,0,7.65-.04,11.47-.03,2.7-1.02,3.86-3.15,3.83-2.1-.03-3.02-1.18-3.05-3.93-.03-3.32,0-6.65,0-9.97Z"/>
               <path d="M96.69,24.87c0,3.32,0,6.64,0,9.96,0,2.12-.84,3.57-3.11,3.73-1.86.13-3.04-1.24-3.06-3.71-.05-6.72-.02-13.45-.02-20.17,0-2.1.78-3.61,3.08-3.73,1.89-.1,3.04,1.29,3.08,3.71.06,3.4.01,6.81.01,10.21Z"/>
               <path d="M97.34,3.32c.04,2.04-1.51,3.64-3.56,3.67-2.15.03-3.73-1.38-3.81-3.41-.09-2.17,1.24-3.51,3.53-3.57,2.26-.06,3.8,1.27,3.84,3.31Z"/>
             </g>
         </svg>
      )}

      {(string === 'logoMuseo') && (
        <svg className="logoMuseoSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.37 36.66">
          <g>
            <polygon className="d" points="1.07 .86 1.05 .86 1.05 5.22 0 5.22 0 .06 1.86 .06 2.44 4.12 2.45 4.12 3.03 .06 4.89 .06 4.89 5.22 3.81 5.22 3.86 .87 3.84 .87 3.08 5.22 1.72 5.22 1.07 .86"/>
            <path className="d" d="M8.63,3.99c0,1.15-.55,1.29-1.5,1.29s-1.49-.14-1.49-1.29V.06h1.18v3.97c0,.2,.08,.32,.31,.32s.31-.12,.31-.32V.06h1.18V3.99Z"/>
            <path className="d" d="M10.38,3.9c0,.35,0,.5,.35,.5,.27,0,.35-.09,.35-.31v-.56c0-.35-.08-.38-.7-.53-.91-.22-1.1-.44-1.1-1.36v-.31c0-1.21,.37-1.33,1.5-1.33,1.03,0,1.37,.28,1.37,1.01v.62h-1.06v-.25c0-.45-.03-.49-.33-.49s-.33,.12-.33,.37v.34c0,.33,.09,.39,.43,.47,1.26,.33,1.41,.42,1.41,1.75,0,1.24-.46,1.46-1.46,1.46-1.28,0-1.52-.3-1.52-1.33v-.43h1.1v.37Z"/>
            <polygon className="d" points="12.95 .06 15.23 .06 15.23 .98 14.13 .98 14.13 2.12 15.15 2.12 15.15 3 14.13 3 14.13 4.29 15.31 4.29 15.31 5.22 12.95 5.22 12.95 .06"/>
            <path className="d" d="M17.76,1.13c0-.19-.09-.24-.31-.24s-.31,.05-.31,.24v3.02c0,.2,.09,.24,.31,.24s.31-.05,.31-.24V1.13Zm-1.84-.07C15.91,.22,16.46,0,17.45,0s1.53,.22,1.53,1.06v3.15c0,.84-.54,1.06-1.53,1.06s-1.54-.22-1.54-1.06V1.06Z"/>
            <path className="d" d="M1.13,16.44c0,.36,0,.5,.35,.5,.26,0,.35-.08,.35-.31v-.55c0-.36-.08-.38-.7-.53C.21,15.34,.03,15.11,.03,14.19v-.32c0-1.2,.37-1.32,1.5-1.32,1.02,0,1.37,.28,1.37,1v.62H1.84v-.25c0-.45-.03-.49-.33-.49s-.34,.13-.34,.37v.35c0,.32,.09,.38,.44,.47,1.26,.33,1.4,.42,1.4,1.75,0,1.24-.46,1.46-1.46,1.46-1.28,0-1.52-.3-1.52-1.32v-.43H1.13v.37Z"/>
            <path className="d" d="M3.71,13.81c0-.82,.31-1.25,1.19-1.25h.44c1.03,0,1.35,.28,1.35,.88v.94h-1.14v-.68c0-.16-.11-.22-.3-.22-.21,0-.31,.07-.31,.22v2.98c0,.16,.11,.22,.31,.22s.3-.07,.3-.22v-.81h1.14v.76c0,.91-.35,1.2-1.35,1.2h-.44c-.88,0-1.19-.44-1.19-1.11v-2.92Z"/>
            <rect className="d" x="7.38" y="12.61" width="1.18" height="5.16"/>
            <polygon className="d" points="9.42 12.61 11.69 12.61 11.69 13.53 10.6 13.53 10.6 14.67 11.61 14.67 11.61 15.55 10.6 15.55 10.6 16.84 11.78 16.84 11.78 17.77 9.42 17.77 9.42 12.61"/>
            <polygon className="d" points="13.57 13.75 13.55 13.75 13.62 17.77 12.53 17.77 12.53 12.61 14.26 12.61 15.13 16.63 15.14 16.62 15.06 12.61 16.15 12.61 16.15 17.77 14.43 17.77 13.57 13.75"/>
            <polygon className="d" points="16.93 12.61 19.4 12.61 19.4 13.51 18.07 16.84 19.4 16.84 19.4 17.77 16.8 17.77 16.8 16.89 18.13 13.53 16.93 13.53 16.93 12.61"/>
            <path className="d" d="M21.55,13.32h-.01l-.28,2.32h.57l-.27-2.32Zm-.98-.72h1.93l.77,5.16h-1.2l-.15-1.24h-.77l-.16,1.24h-1.2l.77-5.16Z"/>
            <polygon className="d" points="5.16 19.81 4.33 19.81 4.33 18.89 7.17 18.89 7.17 19.81 6.34 19.81 6.34 24.05 5.16 24.05 5.16 19.81"/>
            <polygon className="d" points="7.69 18.89 9.96 18.89 9.96 19.81 8.87 19.81 8.87 20.95 9.88 20.95 9.88 21.83 8.87 21.83 8.87 23.12 10.04 23.12 10.04 24.05 7.69 24.05 7.69 18.89"/>
            <polygon className="d" points="0 18.89 2.27 18.89 2.27 19.81 1.18 19.81 1.18 20.95 2.19 20.95 2.19 21.83 1.18 21.83 1.18 23.12 2.36 23.12 2.36 24.05 0 24.05 0 18.89"/>
            <path className="d" d="M10.68,20.09c0-.83,.31-1.26,1.19-1.26h.44c1.04,0,1.35,.29,1.35,.89v.94h-1.14v-.68c0-.16-.11-.22-.3-.22-.21,0-.32,.06-.32,.22v2.98c0,.16,.11,.22,.31,.22s.3-.06,.3-.22v-.82h1.14v.77c0,.91-.35,1.19-1.35,1.19h-.44c-.88,0-1.19-.43-1.19-1.1v-2.92Z"/>
            <polygon className="d" points="15.43 20.03 15.42 20.03 15.49 24.05 14.4 24.05 14.4 18.89 16.12 18.89 16.99 22.91 17.01 22.9 16.92 18.89 18.02 18.89 18.02 24.05 16.29 24.05 15.43 20.03"/>
            <path className="d" d="M20.65,19.95c0-.19-.09-.24-.3-.24s-.31,.05-.31,.24v3.03c0,.19,.09,.24,.31,.24s.3-.05,.3-.24v-3.03Zm-1.84-.06c0-.84,.54-1.07,1.54-1.07s1.53,.22,1.53,1.07v3.15c0,.84-.54,1.06-1.53,1.06s-1.54-.22-1.54-1.06v-3.15Z"/>
            <polygon className="d" points="22.62 18.89 23.8 18.89 23.8 23.12 24.95 23.12 24.95 24.05 22.62 24.05 22.62 18.89"/>
            <path className="d" d="M27.32,19.95c0-.19-.08-.24-.31-.24s-.31,.05-.31,.24v3.03c0,.19,.09,.24,.31,.24s.31-.05,.31-.24v-3.03Zm-1.84-.06c0-.84,.54-1.07,1.53-1.07s1.53,.22,1.53,1.07v3.15c0,.84-.54,1.06-1.53,1.06s-1.53-.22-1.53-1.06v-3.15Z"/>
            <path className="d" d="M29.22,19.89c0-.84,.54-1.07,1.53-1.07,.93,0,1.53,.16,1.53,1.22v.51h-1.14v-.55c0-.23-.09-.3-.39-.3-.23,0-.31,.07-.31,.32v3c0,.13,.09,.19,.32,.19,.29,0,.38-.07,.38-.17v-.81h-.37v-.97h1.5v1.66c0,1-.39,1.18-1.53,1.18-.99,0-1.53-.22-1.53-1.06v-3.15Z"/>
            <rect className="d" x="33.1" y="18.89" width="1.18" height="5.16"/>
            <path className="d" d="M36.63,19.6h-.02l-.28,2.32h.57l-.28-2.32Zm-.97-.71h1.92l.78,5.16h-1.2l-.16-1.24h-.77l-.15,1.24h-1.2l.78-5.16Z"/>
            <polygon className="d" points="0 25.16 1.18 25.16 1.18 29.4 2.33 29.4 2.33 30.33 0 30.33 0 25.16"/>
            <polygon className="d" points="3.06 25.16 5.34 25.16 5.34 26.09 4.25 26.09 4.25 27.22 5.26 27.22 5.26 28.11 4.25 28.11 4.25 29.4 5.42 29.4 5.42 30.33 3.06 30.33 3.06 25.16"/>
            <path className="d" d="M7.9,26.23c0-.19-.08-.24-.3-.24s-.31,.05-.31,.24v3.02c0,.19,.09,.24,.31,.24s.3-.05,.3-.24v-3.02Zm-1.84-.07c0-.84,.55-1.06,1.54-1.06s1.53,.22,1.53,1.06v3.15c0,.84-.54,1.07-1.53,1.07s-1.54-.23-1.54-1.07v-3.15Z"/>
            <polygon className="d" points="10.81 26.3 10.79 26.3 10.87 30.33 9.78 30.33 9.78 25.16 11.5 25.16 12.37 29.19 12.38 29.17 12.3 25.16 13.4 25.16 13.4 30.33 11.67 30.33 10.81 26.3"/>
            <path className="d" d="M15.73,25.88h-.01l-.28,2.32h.57l-.28-2.32Zm-.97-.71h1.93l.78,5.16h-1.2l-.15-1.24h-.77l-.15,1.24h-1.21l.77-5.16Z"/>
            <path className="d" d="M19.18,27.66h.24c.42,0,.49-.11,.49-.45v-.78c0-.26-.11-.38-.36-.38h-.36v1.61Zm-1.18-2.49h1.46c1.27,0,1.63,.27,1.63,1.24v.62c0,.78-.31,.96-.81,1.05h0c.53,.13,.81,.22,.81,.86v1.41h-1.18v-1.48c0-.21-.12-.3-.52-.3h-.21v1.78h-1.18v-5.16Z"/>
            <path className="d" d="M23.05,29.4h.31c.26,0,.31-.11,.31-.5v-2.35c0-.39-.05-.5-.31-.5h-.31v3.35Zm-1.18-4.23h1.54c1.1,0,1.46,.24,1.46,1.94v1.29c0,1.7-.35,1.93-1.46,1.93h-1.54v-5.16Z"/>
            <path className="d" d="M27.38,26.23c0-.19-.08-.24-.3-.24s-.31,.05-.31,.24v3.02c0,.19,.09,.24,.31,.24s.3-.05,.3-.24v-3.02Zm-1.84-.07c0-.84,.54-1.06,1.53-1.06s1.53,.22,1.53,1.06v3.15c0,.84-.54,1.07-1.53,1.07s-1.53-.23-1.53-1.07v-3.15Z"/>
            <path className="d" d="M1.18,35.68h.31c.26,0,.31-.12,.31-.5v-2.36c0-.38-.06-.5-.31-.5h-.31v3.35ZM0,31.44H1.54c1.11,0,1.46,.24,1.46,1.94v1.29c0,1.7-.35,1.93-1.46,1.93H0v-5.16Z"/>
            <path className="d" d="M5.21,32.15h-.02l-.28,2.33h.57l-.28-2.33Zm-.97-.71h1.92l.78,5.16h-1.2l-.15-1.24h-.77l-.15,1.24h-1.2l.78-5.16Z"/>
            <polygon className="d" points="8.63 31.44 9.84 31.44 10.31 35.98 10.33 35.98 10.8 31.44 12.01 31.44 11.33 36.6 9.31 36.6 8.63 31.44"/>
            <rect className="d" x="12.53" y="31.44" width="1.18" height="5.16"/>
            <polygon className="d" points="15.65 32.58 15.63 32.58 15.7 36.61 14.61 36.61 14.61 31.44 16.34 31.44 17.21 35.47 17.22 35.45 17.14 31.44 18.24 31.44 18.24 36.61 16.51 36.61 15.65 32.58"/>
            <path className="d" d="M18.92,32.64c0-.83,.32-1.26,1.19-1.26h.44c1.03,0,1.35,.29,1.35,.88v.94h-1.15v-.67c0-.16-.1-.22-.29-.22-.21,0-.32,.06-.32,.22v2.98c0,.16,.11,.23,.31,.23s.3-.07,.3-.23v-.81h1.15v.76c0,.91-.35,1.2-1.35,1.2h-.44c-.88,0-1.19-.44-1.19-1.1v-2.92Z"/>
            <rect className="d" x="22.58" y="31.44" width="1.19" height="5.16"/>
            <polygon className="d" points="1.03 7.47 1.02 7.47 1.09 11.49 0 11.49 0 6.33 1.73 6.33 2.59 10.35 2.61 10.34 2.53 6.33 3.62 6.33 3.62 11.49 1.9 11.49 1.03 7.47"/>
            <path className="d" d="M5.92,7.05h-.02l-.28,2.33h.57l-.28-2.33Zm-.97-.71h1.93l.78,5.16h-1.2l-.16-1.24h-.77l-.15,1.24h-1.21l.78-5.16Z"/>
            <polygon className="d" points="8.22 6.33 10.68 6.33 10.68 7.23 9.36 10.57 10.68 10.57 10.68 11.49 8.07 11.49 8.07 10.61 9.41 7.26 8.22 7.26 8.22 6.33"/>
            <rect className="d" x="11.43" y="6.33" width="1.18" height="5.16"/>
            <path className="d" d="M15.21,7.4c0-.19-.09-.24-.31-.24s-.31,.05-.31,.24v3.02c0,.19,.08,.24,.31,.24s.31-.05,.31-.24v-3.02Zm-1.84-.07c0-.84,.54-1.06,1.53-1.06s1.53,.22,1.53,1.06v3.16c0,.84-.54,1.07-1.53,1.07s-1.53-.22-1.53-1.07v-3.16Z"/>
            <polygon className="d" points="18.23 7.47 18.22 7.47 18.29 11.49 17.19 11.49 17.19 6.33 18.92 6.33 19.79 10.35 19.81 10.34 19.72 6.33 20.81 6.33 20.81 11.49 19.09 11.49 18.23 7.47"/>
            <path className="d" d="M23.14,7.05h-.02l-.28,2.33h.57l-.28-2.33Zm-.97-.71h1.93l.78,5.16h-1.2l-.15-1.24h-.77l-.15,1.24h-1.21l.78-5.16Z"/>
            <polygon className="d" points="25.44 6.33 26.62 6.33 26.62 10.57 27.77 10.57 27.77 11.49 25.44 11.49 25.44 6.33"/>
            <polygon className="d" points="28.34 6.33 30.61 6.33 30.61 7.26 29.53 7.26 29.53 8.39 30.54 8.39 30.54 9.28 29.53 9.28 29.53 10.57 30.7 10.57 30.7 11.49 28.34 11.49 28.34 6.33"/>
          </g>
        </svg>
      )}

      {(string === 'loading') && (
        <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 100">
          <circle fill="#fff" stroke="none" cx="7" cy="50" r="6">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 15 ; 0 -15; 0 15"
               repeatCount="indefinite"
               begin="0.1"/>
          </circle>
          <circle fill="#fff" stroke="none" cx="31" cy="50" r="6">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 10 ; 0 -10; 0 10"
               repeatCount="indefinite"
               begin="0.2"/>
          </circle>
          <circle fill="#fff" stroke="none" cx="55" cy="50" r="6">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 5 ; 0 -5; 0 5"
               repeatCount="indefinite"
               begin="0.3"/>
          </circle>
        </svg>
      )}

      {(string === 'click') && (
      <svg className="svgClick" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
          <circle cx="22" cy="22" r="6" strokeOpacity="0">
            <animate attributeName="r" begin="1.5s" dur="3s" values="6;22" calcMode="linear" repeatCount="indefinite"></animate>
            <animate attributeName="stroke-opacity" begin="1.5s" dur="3s" values="1;0" calcMode="linear" repeatCount="indefinite"></animate>
            <animate attributeName="strokeWidth" begin="1.5s" dur="3s" values="2;0" calcMode="linear" repeatCount="indefinite"></animate>
          </circle>
          <circle cx="22" cy="22" r="6" strokeOpacity="0">
            <animate attributeName="r" begin="3s" dur="3s" values="6;22" calcMode="linear" repeatCount="indefinite"></animate>
            <animate attributeName="stroke-opacity" begin="3s" dur="3s" values="1;0" calcMode="linear" repeatCount="indefinite"></animate>
            <animate attributeName="strokeWidth" begin="3s" dur="3s" values="2;0" calcMode="linear" repeatCount="indefinite"></animate>
          </circle>
          <circle cx="22" cy="22" r="8">
            <animate attributeName="r" begin="0s" dur="1.5s" values="6;1;2;3;4;5;6" calcMode="linear" repeatCount="indefinite"></animate>
          </circle>
        </g>
        </svg>
      )}

      {(string === 'plus') && (
        <svg className="plusSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.18 73.18">
          <path className="plussvg-1" d="M36.59,72.68C84,71.87,84,1.3,36.59.5-10.85,1.31-10.84,71.88,36.59,72.68Z"/>
          <path className="plussvg-2" d="M36.59,15.8a3.1,3.1,0,0,0-3.1,3.1V57.59a3.1,3.1,0,1,0,6.2,0V18.9a3.1,3.1,0,0,0-3.1-3.1"/>
          <path className="plussvg-2" d="M59,38.25a3.1,3.1,0,0,0-3.1-3.1H17.25a3.1,3.1,0,1,0,0,6.2H55.93a3.1,3.1,0,0,0,3.1-3.1"/>
        </svg>
      )}

      {(string === 'facebook') && (
        <svg className="socialSvg facebookSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.06 19.06">
          <path className="socialPath" d="M19.06,15.66V3.4A3.4,3.4,0,0,0,15.66,0H3.4A3.4,3.4,0,0,0,0,3.4V15.66a3.4,3.4,0,0,0,3.4,3.4H7.6V11.38H6V8.77H7.6V6.71c0-1.93,1-2.94,3.33-2.94h2.13V6.45H11.39c-.58,0-.71.24-.71.85V8.77h2.38l-.23,2.58H10.68v7.71h5a3.4,3.4,0,0,0,3.4-3.4"/>
        </svg>
      )}

      {(string === 'instagram') && (
        <svg className="socialSvg instagramSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2">
          <path className="socialPath" d="M11.36,9.6A1.76,1.76,0,1,0,9.6,11.35,1.75,1.75,0,0,0,11.36,9.6M13,6.79a.63.63,0,1,1-.63-.63.63.63,0,0,1,.63.63M12.3,9.6A2.7,2.7,0,1,1,9.6,6.9a2.7,2.7,0,0,1,2.7,2.7m1.59,2.13c0-.56,0-.72,0-2.13s0-1.57,0-2.13a2.75,2.75,0,0,0-.19-1,1.68,1.68,0,0,0-1-1,2.59,2.59,0,0,0-1-.18c-.56,0-.72,0-2.13,0s-1.57,0-2.13,0a2.59,2.59,0,0,0-1,.18,1.64,1.64,0,0,0-.61.39,1.56,1.56,0,0,0-.39.61,2.82,2.82,0,0,0-.18,1c0,.56,0,.72,0,2.13s0,1.57,0,2.13a3,3,0,0,0,.18,1,1.6,1.6,0,0,0,.39.6,1.64,1.64,0,0,0,.61.39,2.75,2.75,0,0,0,1,.19c.56,0,.72,0,2.13,0s1.57,0,2.13,0a2.75,2.75,0,0,0,1-.19,1.56,1.56,0,0,0,.61-.39,1.6,1.6,0,0,0,.39-.6,2.88,2.88,0,0,0,.19-1m1-2.13c0,1.43,0,1.61,0,2.17a3.78,3.78,0,0,1-.24,1.28,2.73,2.73,0,0,1-1.54,1.54,3.78,3.78,0,0,1-1.28.24c-.56,0-.74,0-2.17,0s-1.61,0-2.17,0a3.78,3.78,0,0,1-1.28-.24,2.73,2.73,0,0,1-1.54-1.54,3.78,3.78,0,0,1-.24-1.28c0-.56,0-.74,0-2.17s0-1.61,0-2.17a3.78,3.78,0,0,1,.24-1.28A2.73,2.73,0,0,1,6.15,4.61a3.78,3.78,0,0,1,1.28-.24c.56,0,.74,0,2.17,0s1.61,0,2.17,0a3.78,3.78,0,0,1,1.28.24,2.73,2.73,0,0,1,1.54,1.54,3.78,3.78,0,0,1,.24,1.28c0,.56,0,.74,0,2.17m4.34,6.17V3.43A3.43,3.43,0,0,0,15.77,0H3.43A3.42,3.42,0,0,0,0,3.43V15.77A3.42,3.42,0,0,0,3.43,19.2H15.77a3.43,3.43,0,0,0,3.43-3.43"/>
        </svg>
      )}

      {(string === 'linkedin') && (
        <svg className="socialSvg linkedinSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2">
          <path className="socialPath" d="M14.68,13.88h-2V10.51c0-.93-.35-1.44-1.08-1.44s-1.21.54-1.21,1.44v3.37H8.5V7.46h1.91v.86a2.23,2.23,0,0,1,1.93-1.06,2.24,2.24,0,0,1,2.34,2.55ZM7.41,5.43A1.18,1.18,0,1,1,6.23,4.25,1.18,1.18,0,0,1,7.41,5.43m-2.16,2h2v6.42h-2Zm14,8.31V3.43A3.43,3.43,0,0,0,15.77,0H3.43A3.42,3.42,0,0,0,0,3.43V15.77A3.43,3.43,0,0,0,3.43,19.2H15.77a3.44,3.44,0,0,0,3.43-3.43"/>
        </svg>
      )}

      {(string === 'twitter') && (
        <svg className="socialSvg twitterSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2">
          <path className="socialPath" d="M13.51,6.76a4.52,4.52,0,0,0,1.21-.33,4.31,4.31,0,0,1-1.05,1.08,5.94,5.94,0,0,1-6,6.25,5.87,5.87,0,0,1-3.21-.95,4.13,4.13,0,0,0,3.1-.87,2.09,2.09,0,0,1-2-1.45,2.16,2.16,0,0,0,1,0A2.09,2.09,0,0,1,4.9,8.37a2.13,2.13,0,0,0,.95.26,2.09,2.09,0,0,1-.65-2.8A6,6,0,0,0,9.52,8,2.1,2.1,0,0,1,13.1,6.11a4.27,4.27,0,0,0,1.33-.51,2.08,2.08,0,0,1-.92,1.16m5.69,9V3.43A3.44,3.44,0,0,0,15.77,0H3.43A3.43,3.43,0,0,0,0,3.43V15.77A3.42,3.42,0,0,0,3.43,19.2H15.77a3.43,3.43,0,0,0,3.43-3.43"/>
        </svg>
      )}

      {(string === 'youtube') && (
        <svg className="socialSvg youtubeSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2">
          <path className="socialPath" d="M8,11.88l4-2.28L8,7.31ZM17.28,9.6A20.26,20.26,0,0,1,17,13.32a1.94,1.94,0,0,1-1.36,1.37,46,46,0,0,1-6,.32,46,46,0,0,1-6-.32,1.94,1.94,0,0,1-1.36-1.37A20.26,20.26,0,0,1,1.92,9.6a20.26,20.26,0,0,1,.32-3.72A1.94,1.94,0,0,1,3.6,4.51a46,46,0,0,1,6-.32,46,46,0,0,1,6,.32A1.94,1.94,0,0,1,17,5.88a20.26,20.26,0,0,1,.32,3.72m1.92,6.17V3.43A3.43,3.43,0,0,0,15.77,0H3.43A3.42,3.42,0,0,0,0,3.43V15.77A3.43,3.43,0,0,0,3.43,19.2H15.77a3.44,3.44,0,0,0,3.43-3.43"/>
        </svg>
      )}

      {(string === 'down') && (
        <svg className="arrowDown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.53 30.47">
          <g className="cls-2" >
            <path className="cls-3" d="M.79.66a2.27,2.27,0,0,0,0,3.2l12.3,12.3a2.26,2.26,0,0,0,3.2-3.2L4,.66a2.27,2.27,0,0,0-3.2,0" />
            <path className="cls-3" d="M.66,29.8a2.25,2.25,0,0,0,3.2,0l13-13a2.26,2.26,0,0,0-3.2-3.19l-13,13a2.26,2.26,0,0,0,0,3.19" />
          </g>
        </svg>
      )}

      {(string === 'play') && (
        <svg className="playSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.618 53.618">
          <path className="playPathSvg" d="M26.809,53.618A26.809,26.809,0,1,1,53.618,26.809,26.84,26.84,0,0,1,26.809,53.618ZM26.809,1A25.809,25.809,0,1,0,52.618,26.809,25.838,25.838,0,0,0,26.809,1Z" />
          <path className="playPathSvg" d="M18.5,26.845q0-6.03,0-12.058c0-1.672.925-2.215,2.364-1.385q10.466,6.041,20.926,12.084c1.313.759,1.315,1.884.011,2.637q-10.4,6-20.806,12.012c-.183.105-.363.218-.553.308a1.277,1.277,0,0,1-1.915-1.118c-.047-.962-.024-1.927-.025-2.89Q18.493,31.64,18.5,26.845Z" />
        </svg>
      )}

      {(string === 'link') && (
        <svg className="linkSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.618 53.618">
          <path className="linkPathSvg" d="M26.809,53.618A26.809,26.809,0,1,1,53.618,26.809,26.84,26.84,0,0,1,26.809,53.618ZM26.809,1A25.809,25.809,0,1,0,52.618,26.809,25.838,25.838,0,0,0,26.809,1Z"/>
          <path className="linkPathSvg" d="M25.468,39.356v-.005c1.966,0,3.931.024,5.9-.006a3.93,3.93,0,0,0,3.9-3.619c.112-3.036.051-6.078.032-9.118a1.278,1.278,0,0,0-1.309-1.237,1.319,1.319,0,0,0-1.32,1.227,6.038,6.038,0,0,0-.014.617c0,2.559,0,5.119,0,7.679,0,1.3-.532,1.823-1.836,1.824q-5.313,0-10.626,0c-1.3,0-1.844-.542-1.845-1.823q0-5.348,0-10.7c0-1.271.519-1.781,1.8-1.783,2.674,0,5.348,0,8.021-.008a1.354,1.354,0,0,0,1.522-1.316c.01-.815-.595-1.347-1.554-1.349q-4.113,0-8.226,0a4.077,4.077,0,0,0-4.2,4.2q-.015,5.622,0,11.245a4.05,4.05,0,0,0,4.147,4.169C21.721,39.369,23.6,39.356,25.468,39.356Zm10.47-22.344.076.191c-.218.2-.443.389-.652.6q-6.4,6.4-12.8,12.795a4.878,4.878,0,0,0-.38.395,1.327,1.327,0,0,0,1.868,1.866,5.268,5.268,0,0,0,.443-.429q6.469-6.471,12.941-12.941c.15-.149.313-.284.586-.532v.895c0,1.6-.012,3.2.007,4.8a1.315,1.315,0,1,0,2.63,0q.019-4.455,0-8.912a1.249,1.249,0,0,0-1.315-1.355q-4.488-.022-8.978,0A1.312,1.312,0,1,0,30.414,17c1.485.017,2.97.007,4.455.008Z" />
          <path className="linkPathSvg" d="M25.468,39.356c-1.873,0-3.747.013-5.621,0A4.05,4.05,0,0,1,15.7,35.184q-.013-5.622,0-11.245a4.077,4.077,0,0,1,4.2-4.195q4.113-.012,8.226,0c.959,0,1.564.534,1.554,1.349a1.354,1.354,0,0,1-1.522,1.316c-2.673.013-5.347,0-8.021.008-1.278,0-1.795.512-1.8,1.783q-.006,5.347,0,10.7c0,1.281.55,1.822,1.845,1.823q5.313,0,10.626,0c1.3,0,1.834-.526,1.836-1.824.005-2.56,0-5.12,0-7.679a6.038,6.038,0,0,1,.014-.617,1.319,1.319,0,0,1,1.32-1.227,1.278,1.278,0,0,1,1.309,1.237c.019,3.04.08,6.082-.032,9.118a3.93,3.93,0,0,1-3.9,3.619c-1.965.03-3.93.006-5.9.006Z" />
          <path className="linkPathSvg" d="M35.938,17.012H34.869c-1.485,0-2.97.009-4.455-.008a1.312,1.312,0,1,1-.046-2.624q4.489-.023,8.978,0a1.249,1.249,0,0,1,1.315,1.355q.018,4.456,0,8.912a1.315,1.315,0,1,1-2.63,0c-.019-1.6-.006-3.2-.007-4.8v-.895c-.273.248-.436.383-.586.532Q30.967,25.956,24.5,32.427a5.268,5.268,0,0,1-.443.429,1.327,1.327,0,0,1-1.868-1.866,4.878,4.878,0,0,1,.38-.395q6.4-6.4,12.8-12.795c.209-.208.434-.4.652-.6Z" />
        </svg>
      )}

      {(string === 'download') && (
        <svg className="downloadSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 808.901 910.666">
          <path className="downloadArrowSvg downloadPathSvg" d="M381.81,671.538v-9.147q0-268.962,0-537.925c0-32.65.586-65.314-.235-97.942C381.171,10.629,395.4-.147,406.256,0c13.1.179,25.126,11.765,25.052,26.787-.359,73.315-.157,146.633-.16,219.949q-.006,111.482-.021,222.963,0,96.237.012,192.473c0,1.427,0,2.853,0,5.426,3.009-2.741,5.233-4.6,7.273-6.65,16.33-16.393,32.589-32.857,48.942-49.227C502.291,596.77,517.344,581.93,532.3,567q41.529-41.454,83.022-82.943c6.964-6.943,19.221-8.39,28.316-3.575a24.357,24.357,0,0,1,12.769,25.475,27.083,27.083,0,0,1-8.293,15.445c-19.864,19.677-39.442,39.643-59.211,59.416-19.535,19.539-39.233,38.915-58.756,58.465q-42.519,42.576-84.9,85.287c-7.168,7.189-14.957,13.819-21.608,21.448-8.332,9.557-28.853,10.091-38.425.067-12.754-13.356-26.009-26.234-39.071-39.295q-24.18-24.178-48.39-48.326-42.831-42.947-85.6-85.95c-17.741-17.791-35.643-35.423-53.267-53.328-9.509-9.66-9.146-24.4.354-34.17,9.128-9.388,24.752-10.155,34.282-.98,14.751,14.2,29.075,28.841,43.56,43.314q32.687,32.661,65.345,65.348,37.606,37.62,75.217,75.239C378.662,668.957,379.83,669.836,381.81,671.538Z"/>
          <path className="downloadPathSvg" d="M758.215,859.4c0-10.19.011-19.826,0-29.463q-.1-75.234-.185-150.467c0-15.182,10.939-26.378,25.386-26.389,14.4-.011,25.456,11.363,25.463,26.384q.045,101.482-.008,202.965c-.012,17.612-10.482,27.985-27.951,27.985q-230.969,0-461.936.007-141.221,0-282.441,0c-2.664,0-5.335.013-7.993.177C11.252,911.652.038,901.13.016,883.611q-.06-47.979.007-95.959c0-34.954-.053-69.909.017-104.863.023-11.554,5.012-20.2,15.894-24.814,16.084-6.82,34.614,5.134,34.9,22.613.229,14.162.053,28.331.054,42.5q0,65.211,0,130.424V859.4Z"/>
        </svg>
      )}

    </>
  )
}
