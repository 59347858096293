import React from 'react'

import { CommonSvgContent } from "./content/CommonSvgContent"
import { CommonContent } from "./content/CommonContent"

import "./../assets/scss/Header.scss";


export const Header = (props) => {
   const [metaFace, setmetaFace] = React.useState('');
   const intervalRef = React.useRef(null);

   React.useEffect(() => {
     setmetaFace('');
     intervalRef.current = setTimeout(() => {
        setmetaFace(props.face);
     }, 100);

     return () => clearTimeout(intervalRef.current);
   }, [props.face])

    return(

      <div className={`header ${(props.mission > 8) ? "notime" : ""}`} >
         {(props.mission > 8) && (
            <CommonSvgContent stringToRet="logo" />
         )}

         {(props.mission <= 8) && (
            <div className={`face ${metaFace}`}>
              <CommonSvgContent stringToRet="face" />
              <div>
                  <h3>Meta Dendral</h3>
                  <h4><CommonContent stringToRet="ai" lang={props.lang} /></h4>
              </div>
            </div>
         )}
        {/*<div className={`vol ${props.sound ? "active" : ""}`}>
          <span className="label"><CommonSvgContent stringToRet="voloff" /></span>
          <label className="switch">
            <input type="checkbox" defaultChecked={props.lang === 'ita' ? false : true} onChange={props.onChangeSound} />
            <span className="slider round"></span>
          </label>
          <span className="label"><CommonSvgContent stringToRet="volon" /></span>
        </div> */ }
      </div>

    )
}
