import React from 'react'
import { CommonContent } from "./content/CommonContent"

export const Time = (props) => {

  let [state, setState] = React.useState({
     points: 0,
     anim: "",
     time: 0,
  })
  const intervalRef = React.useRef(null);
  const intervalRefClear = React.useRef(null);

  //const th = 1000* 60 * 60 * 1.5;
  const th = 1000* 60 * 60 * 1.5; //0.003

  function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${addZeroBefore(hours)}:${addZeroBefore(minutes)}:${addZeroBefore(seconds)}`;
  }

  React.useEffect(() => {
     // cancello il Timeout del reset
     clearTimeout(intervalRefClear.current);

     // imposto prima a vuoto e dopo un decimo di secondo al valore giusto in modo che l'animazione riparta ogni volta che cambia il punteggio
     setState(prevState => ({
      ...prevState,
      anim: ""
     }));

     intervalRef.current = setTimeout(() => {
        if (state.points > props.points){
           setState(prevState => ({
             ...prevState,
             anim: "red",
             points: props.points
           }));
        }
        else if (state.points < props.points){
           setState(prevState => ({
             ...prevState,
             anim: "green",
             points: props.points
           }));
        }
     }, 100);

     // dopo 3 secondi reimposto a "" anim in modo da riavere i punti in nero
     intervalRefClear.current = setTimeout(() => {
        setState(prevState => ({
        ...prevState,
        anim: ""
       }));
     }, 3000);


     return () => {
        clearTimeout(intervalRef.current);
        clearTimeout(intervalRefClear.current);
     }
}, [props.points])

  React.useEffect(() => {
    //console.log("starttime: ",props.starttime);
    intervalRef.current = setInterval(() => {
      let tl =  new Date().getTime() - props.starttime;

      setState(prevState => ({
        ...prevState,
        time: tl
      }));

      return () => clearInterval(intervalRef.current);
      // if (tl >= 0){
      //   setState(prevState => ({
      //     ...prevState,
      //     time: tl
      //   }));
      // }
      // else{
      //   clearInterval(intervalRef.current);
      //   props.onTimeIsOver();
      // }
    }, 500);

    return () => clearInterval(intervalRef.current);
  }, [props.starttime])


  const addZeroBefore = (n) => {
    return (n < 10 ? '0' : '') + n;
  }

  return(
     <div className={`time ${state.anim}`} >
      <div className="digital">
        {props.mk != "guest" &&
           <><CommonContent stringToRet="tempo" lang={props.lang} />: {convertMsToHM(state.time)}</>
        }
      </div>
      <div className="penality">
         <CommonContent stringToRet="punti" lang={props.lang} />: {props.points}
      </div>
   </div>
  )
}
