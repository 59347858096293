import React from 'react'
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CommonSvgContent } from "../content/CommonSvgContent"


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export const SuggestionContent = (props) => {
   const string = props.stringToRet;
   let lang = props.lang;

   return (
   <>

      {/* ******* SUGGERIMENTI ******** */}

      {(string === 's_1_mission1' && lang === 'ita') && (
         <p>Vi siete recati nelle Gallerie Leonardo da Vinci? Alzate gli occhi e nell’area 1 “Artisti o ingegneri?” troverete una versione gigante dell’oggetto contenuto nella borsa.</p>
      )}
      {(string === 's_2_mission1' && lang === 'ita') && (
         <p>Una volta sotto all’icosaedro esposto nelle Gallerie, dovete contare tutti i triangoli di cui è composto: dovrete sommare i triangoli che formano le facce del poliedro ai triangoli che si elevano da ciascuna faccia del poliedro.</p>
      )}
      {(string === 's_3_mission1' && lang === 'ita') && (
         <p>Mal di testa eh? I triangoli in totale sono 80. Un icosaedro è un poliedro formato da 20 facce (dal greco eikosi, che significa venti), dunque i triangoli che formano le facce del poliedro sono 20 a cui vanno aggiunti 3 triangoli che si elevano per ciascuna faccia (3x20) per un totale di 20+60=80 triangoli.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission1' && lang === 'eng') && (
         <p>Have you reached the Leonardo da Vinci Galleries? Look up and in area 1 “Artists or engineers?” you’ll see a giant version of the object in your kit.</p>
      )}
      {(string === 's_2_mission1' && lang === 'eng') && (
         <p>When you are standing under the icosahedron on display in the Galleries, you will need to count all the triangles of which it is composed: you will need to sum the triangles that form the faces of the polyhedron with those elevated from each face.</p>
      )}
      {(string === 's_3_mission1' && lang === 'eng') && (
         <p>Makes your head spin, doesn’t it? There are 80 triangles in all. An icosahedron is a polyhedron with 20 faces (icosa comes from the Greek eikosi, which means twenty), so there are 20 triangles forming the faces of the polyhedron, plus three elevated triangles on each face (3x20) for a total of 20+60=80 triangles.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission1' && lang === 'fra') && (
         <p>Avez-vous visité les Galeries Leonardo da Vinci ? Levez les yeux et dans la zone 1 « Artists or engineers? », vous trouverez une version géante de l'objet contenu dans le sac.</p>
      )}
      {(string === 's_2_mission1' && lang === 'fra') && (
         <p>Une fois en dessous de l'icosaèdre présenté dans les Galeries, vous devez compter tous les triangles qui le composent : vous devez additionner les triangles formant les faces du polyèdre aux triangles naissant de chaque face du polyèdre.</p>
      )}
      {(string === 's_3_mission1' && lang === 'fra') && (
         <p>Vous avez la migraine, hein ? Il y a 80 triangles au total. Un icosaèdre est un polyèdre formé de 20 faces (du grec eikosi, qui signifie vingt) ; les triangles qui forment les faces du polyèdre sont donc au nombre de 20 auxquels il faut ajouter 3 triangles qui s'élèvent de chaque face (3x20) pour un total de 20+60=80 triangles.</p>
      )}

      {/* SUGGESTIONS MISSION 2 */}

      {(string === 's_1_mission2' && lang === 'ita') && (
         <p>Il dettaglio è un particolare fotografato da molto vicino di un accessorio di un oggetto molto più grande esposto all’interno di una vetrina. Questo suggerimento è…calzante.</p>
      )}
      {(string === 's_2_mission2' && lang === 'ita') && (
         <p>Il dettaglio si riferisce alla decorazione sulla parte superiore della tomaia dello stivaletto della tuta sovietica Krechet per attività lunari. Il codice dell’inventario dell’oggetto si trova sul pannello descrittivo dell’oggetto: 155-D1421.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission2' && lang === 'eng') && (
         <p>The detail is an extremely close-up photograph taken of an accessory to a much larger object displayed behind glass. You can bet your boots this hint will help.</p>
      )}
      {(string === 's_2_mission2' && lang === 'eng') && (
         <p>The detail is the decoration on the top of the upper of the boot on the Krechet, the Soviet space suit designed for Moon operations. You will find the object’s inventory code on the panel describing it: 155-D1421.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission2' && lang === 'fra') && (
         <p>Il s'agit d'un détail photographié de près d'un accessoire d'un objet beaucoup plus grand présenté dans une vitrine.</p>
      )}
      {(string === 's_2_mission2' && lang === 'fra') && (
         <p>Le détail se réfère à la décoration sur la partie supérieure de la tige de la botte de la combinaison soviétique Krechet pour les activités lunaires. Le code d'inventaire de l'objet se trouve sur le panneau descriptif de l'objet : 155-D1421.</p>
      )}

      {/* SUGGESTIONS MISSION 3 */}

      {(string === 's_1_mission3' && lang === 'ita') && (
         <p>L’ordine in cui inserire le 3 sostanze contenute all’interno delle provette all’interno del tubo si scopre risolvendo l’indovinello. Gli elementi nello stemma dei Von Braun hanno colori differenti. Che ci sia una corrispondenza tra i colori degli elementi grafici e il colore delle sostanze contenute nelle provette?</p>
      )}
      {(string === 's_2_mission3' && lang === 'ita') && (
         <p>Vediamo strofa per strofa l’indovinello: “Prima di sua Maestà le corone”. La prima sostanza da versare è quella del colore della corona: il giallo. “Poi il campo dello scudo che protegge il Barone” significa che la seconda sostanza ha il colore del fondo dello scudo: il bianco. “Infine le losanghe somiglianti a margheritone” indicano la terza e ultima sostanza con il colore delle forme geometriche trapezioidali: il rosso. Ma attenzione: non inserite come risposta questi colori, dovete prima inserire in questo ordine le sostanze nella provetta grande e solo quello che osserverete nella provetta sarà la vostra risposta (bianco, rosso, giallo).</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission3' && lang === 'eng') && (
         <p>You’ll know the order in which to pour the three substances in the small test tubes into the large test tube by solving the riddle. The elements in Von Braun’s coat of arms have different colours. Could the colours in the coat of arms correspond to the colours of the substances in the test tubes?</p>
      )}
      {(string === 's_2_mission3' && lang === 'eng') && (
         <p>Let’s read the riddle line by line: “First comes the crown of times past”. So the first substance to pour into the big test tube is the one that is the same colour as the crown: yellow. “Next a shield for the Baron, safe and steadfast” must mean that the second substance is the same colour as the background of the shield: white. “Then the diamonds like daisies at long last” refers to the third and final substance, which must be the colour of the trapezoids: red. But be careful: these colours are not the final answer. They refer to the colours of the substances, which you should pour into the large test tube in this order and only then will you know the answer (white, red, yellow).</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission3' && lang === 'fra') && (
         <p>On découvre l’ordre dans lequel il faut placer les 3 substances dans les éprouvettes à l'intérieur du tube en résolvant la devinette. Les éléments dans les armoiries de Von Braun ont des couleurs différentes. Peut-être y a-t-il une correspondance entre les couleurs des éléments graphiques et la couleur des substances contenues dans les éprouvettes ?</p>
      )}
      {(string === 's_2_mission3' && lang === 'fra') && (
         <p>Examinons l'énigme strophe par strophe : « D’abord les couronnes de Sa Majesté ». La première substance à verser est de la couleur de la couronne : jaune. « Puis le champ du bouclier qui protège le baron » signifie que la seconde substance a la couleur du fond du bouclier : le blanc. « Enfin, les losanges qui ressemblent à une grande marguerite » indiquent la troisième et dernière substance de la couleur des formes géométriques trapézoïdales : le rouge. Mais attention : n'entrez pas ces couleurs comme réponse, vous devez d'abord insérer dans cet ordre les substances dans la grande éprouvette et votre réponse ne pourra être que ce que vous observerez dans l’éprouvette (blanc, rouge, jaune).</p>
      )}

      {/* SUGGESTIONS MISSION 4 */}

      {(string === 's_1_mission4' && lang === 'ita') && (
         <p>Il vostro obiettivo è aprire il cryptex contenuto nella borsa. Cos’è? È quel cilindro con 5 dischi rotanti con le lettere dell’alfabeto. È una specie di cassaforte: per aprirlo dovete conoscere la password che lo sblocca. Come vedete dai dischi, la password sarà formata da 5 lettere. Dovete cercarle osservando la Programma 101 e la lista di numeri. Non trovate alcun collegamento? Avete provato con le sequenze di numeri sul tastierino numerico?</p>
      )}
      {(string === 's_2_mission4' && lang === 'ita') && (
         <p>Avete tentato di cercare le sequenze di numeri sul tastierino numerico? Usate il pensiero laterale…il tastierino numerico della Programma 101 è formato da 12 tasti, provate a pensarli come fossero una matrice di quadretti da annerire o di pixel che si illuminano in corrispondenza dei numeri che formano le 5 sequenze numeriche…</p>
      )}

      {(string === 's_3_mission4' && lang === 'ita') && (
         <p>Cercate i numeri di una sequenza (es. 7 8 9 5 2) in questo ordine sul tastierino immaginandovi di “colorare” ogni tasto della sequenza…non vi “appare” il disegno di una lettera? Avete mai disegnato lettere o parole annerendo i quadretti di un foglio?<br />
         La password per il cryptex è “TCHOU”. Inserite queste lettere sui dischi combinatori e premete il pulsante… il cilindro si apre! Estraete il foglietto contenuto all’interno e proseguite.</p>
      )}

      {(string === 's_4_mission4' && lang === 'ita') && (
         <p>Guardate il pannello di controllo dell’Elea 9003 e provate a cercare la scritta “Cond Mem”. In corrispondenza trovate un’area azzurra formata da 6x3 tasti. Ci sono alcune lettere presenti sui tasti che corrispondono a due iniziali dei nomi della lista?</p>
      )}
      {(string === 's_5_mission4' && lang === 'ita') && (
         <p>Non avete trovato i due tasti? Il primo è contraddistinto da “Wd” e in lista corrisponde a Walter Dornberger; il secondo è indicato come “Ar” e corrisponde a Arthur Rudolph. Inserite come risposte “WD” e “AR”.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission4' && lang === 'eng') && (
         <p>Your objective is to open the cryptex inside your kit. What’s a cryptex? It is a cylinder with five dials bearing the letters of the alphabet. It is like a vault: to open it, you must know the password. As you can see from the dials, the password will be formed by five letters. You must find it by taking a very close look at the Programma 101 and the list of numbers. Can’t find a connection? Have you tried with the sequences of numbers on the numeric keyboard?</p>
      )}
      {(string === 's_2_mission4' && lang === 'eng') && (
         <p>Have you tried looking for the sequences of numbers on the numeric keyboard? The numeric keyboard is the smaller one on the side… the Programma 101’s numeric keyboard has 12 keys. Try to think of them as a matrix of squares to fill in or pixels that light up in correspondence with the numbers that form the five numeric sequences...</p>
      )}

      {(string === 's_3_mission4' && lang === 'eng') && (
         <p>Find the numbers in a sequence (for example, 7 8 9 5 2) in this order on the numeric keyboard, imagining you are “colouring in” each key in the sequence... do the keys create a letter? Have you ever drawn letters or words by filling in squares in a grid?<br />
         The password for the cryptex is “TCHOU”. Enter these letters on the rotary dials and press the button… the cylinder opens! Take out the piece of paper inside and proceed.</p>
      )}

      {(string === 's_4_mission4' && lang === 'eng') && (
         <p>Study the ELEA 9003 console and find where it says “Cond Mem”. You will see a blue area formed by 6x3 keys. Are there any letters on the keys that match the initials of two names on the list?</p>
      )}
      {(string === 's_5_mission4' && lang === 'eng') && (
         <p>Haven’t found the two keys? The first is “WD” for Walter Dornberger on the list. The second is “AR” for Arthur Rudolph. Enter “WD” and “AR” as your answer.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission4' && lang === 'fra') && (
         <p>Votre but est d'ouvrir le cryptex contenu dans le sac. Qu'est-ce que c'est ? Il s'agit du cylindre avec 5 disques rotatifs représentant les lettres de l'alphabet. C'est une sorte de coffre-fort : pour l'ouvrir, il faut connaître le mot de passe qui le déverrouille. Comme vous pouvez le voir sur les disques, le mot de passe sera composé de 5 lettres. Vous devez les rechercher en consultant le programme 101 et la liste des numéros. Vous ne trouvez pas de lien ? Avez-vous essayé des séquences de chiffres sur le pavé numérique ?</p>
      )}
      {(string === 's_2_mission4' && lang === 'fra') && (
         <p>Avez-vous essayé de rechercher des séquences de chiffres sur le pavé numérique ? Utilisez la pensée latérale... le pavé numérique du Programme 101 est composé de 12 touches, essayez de les considérer comme une matrice de carrés à noircir ou de pixels qui s'allument au niveau des numéros qui forment les 5 séquences de chiffres...</p>
      )}

      {(string === 's_3_mission4' && lang === 'fra') && (
         <p>Cherchez les numéros d'une séquence (par exemple 7 8 9 5 2) dans cet ordre sur le pavé en imaginant que vous « coloriez » chaque touche de la séquence...voyez-vous « apparaître » le dessin d'une lettre ? Avez-vous déjà dessiné des lettres ou des mots en noircissant des carrés sur une feuille de papier ?<br />
         Le mot de passe du cryptex est « TCHOU ». Insérez ces lettres sur les disques de combinaison et appuyez sur le bouton... le cylindre s'ouvre ! Retirez le feuillet qui se trouve à l'intérieur et continuez.</p>
      )}

      {(string === 's_4_mission4' && lang === 'fra') && (
         <p>Regardez le panneau de contrôle de l'Elea 9003 et essayez de rechercher « Cond Mem ». À ce niveau, vous trouverez une zone bleue formée par des 6x3 touches. Y a-t-il des lettres sur les touches qui correspondent à deux initiales des noms de la liste ?</p>
      )}
      {(string === 's_5_mission4' && lang === 'fra') && (
         <p>Vous n'avez pas trouvé les deux touches ? Le premier est marqué par les lettres « Wd » et correspond à Walter Dornberger dans la liste ; le second est indiqué par les lettres « Ar » et correspond à Arthur Rudolph. Saisissez les réponses « WD » et « AR ».</p>
      )}

      {/* SUGGESTIONS MISSION 5 */}

      {(string === 's_1_mission5' && lang === 'ita') && (
         <p>Vi trovate nei pressi del sottomarino Enrico Toti? Ora per risolvere l’enigma avete bisogno di un oggetto all’interno della borsa. È un oggetto che funziona grazie al magnetismo terrestre. Cosa sarà?</p>
      )}
      {(string === 's_2_mission5' && lang === 'ita') && (
         <p>Con la bussola in mano, avvicinatevi all’ancora del sottomarino e orientatevi in direzione sud-sud ovest. Guardando attraverso le maglie dell’ancora, dovreste vedere la parete con i pannelli di grafica. Un’immagine su un manifesto riproduce una catena. C’è un oggetto in quel manifesto che ha i…denti?</p>
      )}
      {(string === 's_3_mission5' && lang === 'ita') && (
         <p>Sul manifesto “Einiges Europa Tragt Wohlstand” sono raffigurate alcune bandiere, una spiga di frumento e la ruota di un ingranaggio. L’ingranaggio è composto da 16 denti.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission5' && lang === 'eng') && (
         <p>Are you near the Enrico Toti submarine? To solve the riddle you will need to use an object in your kit... it is something that uses the Earth’s magnetic field to work. What could it be?</p>
      )}
      {(string === 's_2_mission5' && lang === 'eng') && (
         <p>With the compass in hand, move closer to the submarine’s anchor and face south/southwest. As you look across the anchor chains you should see the wall with printed panels. An image on a poster shows a chain. Is there another object on that poster that has… teeth?</p>
      )}
      {(string === 's_3_mission5' && lang === 'eng') && (
         <p>Look at the “Einiges Europa Tragt Wohlstand” poster featuring some flags, a spike of grain and a gear. The gear has 16 teeth.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission5' && lang === 'fra') && (
         <p>Êtes-vous à proximité du sous-marin Enrico Toti ? Pour résoudre l'énigme, vous avez besoin d'un objet qui se trouve dans le sac. C'est un objet qui fonctionne grâce au magnétisme terrestre. Qu’est-ce que c’est donc ?</p>
      )}
      {(string === 's_2_mission5' && lang === 'fra') && (
         <p>Boussole en main, approchez-vous de l'ancre du sous-marin et orientez-vous en direction du sud-sud-ouest. En regardant les liens d'ancrage, vous devriez voir le mur avec les panneaux graphiques. Une image sur une affiche reproduit une chaîne. Y a-t-il un objet sur cette affiche qui a des... dents ?</p>
      )}
      {(string === 's_3_mission5' && lang === 'fra') && (
         <p>Sur l'affiche « Einiges Europa Tragt Wohlstand » sont représentés quelques drapeaux, un épi de blé et la roue d'un engrenage. L'engrenage est composé de 16 dents.</p>
      )}

      {/* SUGGESTIONS MISSION 6 */}

      {(string === 's_1_mission6' && lang === 'ita') && (
         <p>Provate a ruotare il disco interno: la finestrella più interna a spicchio svela i nomi di componenti preceduti da numeri. Osservate i componenti disegnati sul bordo esterno, sono identificati da numeri e dai colori della bandiera. Qual è il codice segreto racchiuso nella bandiera americana? Non vi soffermate sulle scritte a penna, ma sulla struttura della bandiera. Riuscite a “trasformare” gli elementi della bandiera in 4 numeri?</p>
      )}
      {(string === 's_2_mission6' && lang === 'ita') && (
         <p>Osservate la bandiera, non considerate le scritte: da quali elementi è costituita? Strisce orizzontali in 2 colori differenti, stelle e un grande quadrato che le contiene. Sono 4 elementi, proprio come il numero di componenti che dobbiamo trovare sul disco.</p>
      )}
      {(string === 's_3_mission6' && lang === 'ita') && (
         <p>Osservate la bandiera: è fatta da 1 quadrato blu, 6 strisce orizzontali bianche, 7 strisce orizzontali rosse, 50 stelle bianche. I numeri e i colori che vi servono sono proprio questi: 1 blu, 6 bianco, 7 rosso, 50 bianco. Cercate i nomi dei componenti corrispondenti a questi numeri nel disco e inseriteli come risposta (senza includere il numero): power distributor, water accumulator, +28V Battery, gb heat exchanger.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission6' && lang === 'eng') && (
         <p>Try rotating the inner wheel: the most central window reveals the names of the components preceded by numbers. Study the components drawn on the edge. They are identified by numbers and the colours of the flag. What is the secret code hidden in the American flag? Don’t focus on the writing, but on the structure of the flag. Can you “transform” the elements of the flag into four numbers?</p>
      )}
      {(string === 's_2_mission6' && lang === 'eng') && (
         <p>Look closely at the flag, but not at the writing: what are the elements of the flag? Horizontal stripes in two different colours, stars and their large square background. There are four elements, the same number as the components that we must find on the wheel.</p>
      )}
      {(string === 's_3_mission6' && lang === 'eng') && (
         <p>Look closely at the flag: it is made up of one blue square, six white stripes, seven red stripes and 50 white stars. These are the numbers and colours that you need: 1 blue, 6 white, 7 red, 50 white. Look for the names of the components corresponding to these numbers on the wheel and enter them as your answer (without the numbers): power distributor, water accumulator, +28V battery, gb heat exchanger.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission6' && lang === 'fra') && (
         <p>Essayez de faire tourner le disque interne : la fenêtre interne en relief révèle les noms des composants précédés de chiffres. Observez les composants dessinés sur le bord extérieur, ils sont identifiés par des numéros et les couleurs du drapeau. Quel est le code secret présent sur le drapeau américain ? Ne vous attardez pas sur les inscriptions au stylo, mais sur la structure du drapeau. Pouvez-vous « transformer » les éléments du drapeau en 4 chiffres ?</p>
      )}
      {(string === 's_2_mission6' && lang === 'fra') && (
         <p>Regardez le drapeau, sans tenir compte du lettrage : de quels éléments est-il composé ? Des rayures horizontales de 2 couleurs différentes, des étoiles et un grand carré qui les contient. Il y a 4 éléments, tout comme le nombre de composants que nous devons trouver sur le disque.</p>
      )}
      {(string === 's_3_mission6' && lang === 'fra') && (
         <p>Regardez le drapeau : il est composé de 1 carré bleu, de 6 bandes horizontales blanches, de 7 bandes horizontales rouges et de 50 étoiles blanches. Voici les chiffres et les couleurs dont vous avez besoin : 1 bleu, 6 blanc, 7 rouge, 50 blanc. Recherchez les noms des composants correspondant à ces numéros sur le disque et saisissez-les en réponse (sans inclure le numéro) : power distributor, water accumulator, +28V Battery, gb heat exchanger.</p>
      )}

      {/* SUGGESTIONS MISSION 7 */}

      {(string === 's_1_mission7' && lang === 'ita') && (
         <p>Recatevi a poppa del brigantino-goletta Ebe e cercate il nome della nave “EBE”. Il fregio che racchiude il nome raffigura pesci-draghi marini e due nodi marinari uguali. Si tratta di un tipo di nodo molto comune, tra i più semplici. La risposta all’enigma è il nome del nodo. Cercate su “Google Immagini” es. “nodi marinareschi” e confrontate ciò che trovate con l’immagine nel fregio. Questo nodo non ama…la velocità.</p>
      )}
      {(string === 's_2_mission7' && lang === 'ita') && (
         <p>Il nodo nel fregio si chiama “piano”. È uno dei più comuni e semplici nodi di giunzione. È usato generalmente per unire due corde di uguali dimensioni. È inoltre utilizzato per terminare le legature.</p>
      )}
      {(string === 's_3_mission7' && lang === 'ita') && (
         <p>Osservate attentamente tutto il fregio sul soffitto e cercate nelle rappresentazioni la figura di un drago a più teste. Fate attenzione: il drago ha 2 teste anche nella parte posteriore del corpo, ma l’enigma vi chiede di enumerare solo quelle più grandi, ossia quelle posizionate nella parte frontale del mostro.</p>
      )}
      {(string === 's_4_mission7' && lang === 'ita') && (
         <p>Per trovare le polene, uscite dalla sala da ballo e recatevi sotto al catamarano Luna Rossa. Guardando in direzione delle vetrine della biblioteca, identificate le polene esposte e cercate il pannello informativo. Se scorrete i nomi delle polene troverete anche quello – in «versione Romana» – della Dea Atena.</p>
      )}
      {(string === 's_5_mission7' && lang === 'ita') && (
         <p>Per trovare l’oggetto volante dovete posizionarvi di fronte alla polena di Minerva (l’ultima sulla destra) e guardare verso il livello superiore del Padiglione Aeronavale. Dal punto in cui vi trovate, dovreste vedere spuntare dalla balconata un modellino di aereo sospeso. Salite al primo piano e recatevi nell’area dedicata agli elicotteri. L’ultimo oggetto esposto sul fondo è un modello di Convertiplano.</p>
      )}
      {(string === 's_6_mission7' && lang === 'ita') && (
         <p>Avete un oggetto nella borsa che produce luce? Avete fatto caso che sul lato inferiore della carlinga del modello di convertiplano c’è un’apertura? Provate a illuminare l’interno. Osservate bene in tutte le direzioni, puntando la torcia nella direzione muso-coda troverete una grande scritta “NO. 1”. Il numero per rispondere all’enigma è “1”.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission7' && lang === 'eng') && (
         <p>Head to the stern of the brigantine-schooner “Ebe” and look for the ship’s name “EBE”. The frieze bearing its name also depicts sea dragons and two identical nautical knots. These are very common knots of the simplest kind. The answer to the riddle lies in the name of the knot. Try searching “nautical knots” on “Google Images” and compare the images you see with that in the frieze. This knot is not round but...</p>
      )}
      {(string === 's_2_mission7' && lang === 'eng') && (
         <p>The knot in the frieze is called a “square knot”. It is one of the most common and simple knots. It is generally used for binding two identical lines and to tie the two ends of a single line together.</p>
      )}
      {(string === 's_3_mission7' && lang === 'eng') && (
         <p>Look carefully at the entire frieze on the ceiling and the dragon with many heads. Pay close attention: although the dragon also has two small heads behind its body, the riddle asks you to count only the larger ones in front.</p>
      )}
      {(string === 's_4_mission7' && lang === 'eng') && (
         <p>To find the figureheads, exit the ballroom and stand directly under the Luna Rossa catamaran. Looking towards the library display cases, notice the figureheads on display and the information panel. If you read the names of the figureheads, you will see the Goddess Athena’s Roman name.</p>
      )}
      {(string === 's_5_mission7' && lang === 'eng') && (
         <p>To find the flying object, stand in front of the figurehead depicting Minerva (on the far right) and look up towards the upper level of the Air and Water Transport Building. From the place where you stand, you should be able to see a model of a suspended aeroplane sticking out from the balcony. Go to the area dedicated to helicopters on the upper level. The last object displayed at the back is a tiltrotor aircraft model.</p>
      )}
      {(string === 's_6_mission7' && lang === 'eng') && (
         <p>Do you have an object in your kit that shines light? Have you noticed that there is an opening in the bottom of the aircraft’s cockpit? Try shining the light inside. Study it from all directions, directing your flashlight towards the nose-tail, where you will see the writing “NO. 1”. The number that solves this riddle is “1”.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission7' && lang === 'fra') && (
         <p>Rendez-vous à la poupe du brigantin-goélette Ebe et cherchez le nom du navire « EBE ». La frise entourant le nom représente des poissons-dragons marins et deux nœuds marins identiques. Il s'agit d'un type de nœud très courant, parmi les plus simples. La réponse à l'énigme est le nom du nœud. Recherchez sur « Google Images » par exemple « nœuds marins » et comparez ce que vous trouvez avec l’image dans la frise. Ce nœud n'aime pas... l’épaisseur.</p>
      )}
      {(string === 's_2_mission7' && lang === 'fra') && (
         <p>Le nœud de la frise est appelé « plat ». C'est l'un des nœuds de jonction les plus courants et les plus simples. Il est généralement utilisé pour joindre deux cordes de taille égale. Il est également utilisé pour la finition des ligatures.</p>
      )}
      {(string === 's_3_mission7' && lang === 'fra') && (
         <p>Observez attentivement l'ensemble de la frise du plafond et cherchez la figure d'un dragon à plusieurs têtes dans les représentations. Attention : le dragon possède également 2 têtes à l'arrière de son corps, mais l'énigme vous demande de n'énumérer que les plus grosses, c'est-à-dire celles situées à l'avant du monstre.</p>
      )}
      {(string === 's_4_mission7' && lang === 'fra') && (
         <p>Pour trouver les figures de proue, quittez la salle de bal et passez sous le catamaran Luna Rossa. En regardant en direction des fenêtres de la bibliothèque, identifiez les figures de proue exposées et cherchez le panneau d'information. Si vous parcourez les noms des figures de proue, vous trouverez également celui - en « version Romaine » - de la déesse Athéna.</p>
      )}
      {(string === 's_5_mission7' && lang === 'fra') && (
         <p>Pour trouver l'objet volant, il faut se placer devant la figure de proue de Minerve (la dernière à droite) et regarder vers le niveau supérieur du Pavillon Aéronavale. De l'endroit où vous vous trouvez, vous devriez voir un modèle d'avion suspendu dépasser du balcon. Montez au premier étage et rendez-vous dans la zone dédiée aux hélicoptères. Le dernier objet exposé dans la partie inférieure est un modèle de Convertiplane.</p>
      )}
      {(string === 's_6_mission7' && lang === 'fra') && (
         <p>Avez-vous dans votre sac un objet qui produit de la lumière ? Avez-vous remarqué qu'il y a une ouverture du côté inférieur de la carlingue du modèle de convertiplane ? Essayez d'éclairer l'intérieur. Regardez bien dans toutes les directions, en pointant la torche dans la direction nez-queue, vous trouverez un grand panneau « N° 1 ». Le chiffre qui répond à l'énigme est « 1 ».</p>
      )}

      {/* SUGGESTIONS MISSION 8 */}

      {(string === 's_1_mission8' && lang === 'ita') && (
         <p>Hai raggiunto le Gallerie Leonardo da Vinci? Ora osserva la mappa contenuta nella borsa e il colore della macchia in corrispondenza di una sala evidenziata sulla mappa. Come avrai notato, le sale tematiche dedicate a Leonardo hanno colori delle pareti differenti. Cerca la sala dipinta con il colore che trovi sulla mappa.</p>
      )}
      {(string === 's_2_mission8' && lang === 'ita') && (
         <p>Dovete identificare quale coppia di ruote dentate uguali esposte e posizionate sotto alla macchina per filettare le viti (due grandi, due piccole) va montata sulla macchina al posto di quelle presenti (quelle due nere ai lati della macchina ingranate con la manovella) per ottenere una filettatura che corrisponde (circa) a quella sulla barra presente nella borsa. Leggete come funziona la macchina nella didascalia.</p>
      )}
      {(string === 's_3_mission8' && lang === 'ita') && (
         <p>Dovete determinare il rapporto fra le velocità di rotazione della ruota dentata centrale con le due ruote laterali. Come regola generale, quando si ha a che fare con ruote dentate come nel nostro sistema, se le ruote condotte (le due ruote esterne) sono più piccole della ruota motrice (quella centrale che riceve la forza rotante dalla manovella) allora gireranno più velocemente. Se sono più grandi, gireranno più lentamente. Per ottenere la barra filettata con passo largo, dovremo quindi montare sulla macchina le due ruote più piccole (a,b) rispetto a quelle attuali, così il carrello procederà più rapidamente e la lama produrrà una vite con un passo largo, cioè con una filettatura molto rada. A velocità costante di rotazione della barra, la porzione di legno da incidere avanzerà più rapidamente in un giro e la “spirale” sarà più larga.</p>
      )}

      {/* ENG */}

      {(string === 's_1_mission8' && lang === 'eng') && (
         <p>Have you reached the Leonardo da Vinci Galleries? Now look at the map in your kit and the colour of the dot on a room highlighted on the map. As you might have noticed, the walls of the thematic rooms dedicated to Leonardo have been painted different colours. Find the room painted the same colour as the one on the map.</p>
      )}
      {(string === 's_2_mission8' && lang === 'eng') && (
         <p>You must identify which pair of identical gears displayed and arranged under the thread rolling machine (you must choose between two large and two small gears) should be mounted on the machine in place of the pair that is already there (the two black gears at the sides of the machine that turn with the crank) in order to thread a screw that (more or less) matches the thread on the screw in your kit. Read the caption to learn how the machine works.</p>
      )}
      {(string === 's_3_mission8' && lang === 'eng') && (
         <p>You must figure out the relationship between the rotational speed of the central gear and the rotational speed of the two lateral gears. In general, when you're dealing with gears, as in our case, if the driven gears (the two external gears) are smaller than the driving gear (the central gear that receives the drive from the crank), then they will turn faster. And if they’re bigger than the driving gear, they will turn more slowly. This means that to make a screw with a wide thread, we should mount the two gears that are smaller (a,b) than the ones on the machine now, so the carriage will advance more quickly and the blade will create a screw with a wide thread, i.e., a screw with more space between the threading. While the wooden cylinder turns at the same speed, the part of the wood to be cut advances more rapidly as it turns, and the "spiral" cut into it is wider.</p>
      )}

      {/* FRA */}

      {(string === 's_1_mission8' && lang === 'fra') && (
         <p>Avez-vous atteint les Galeries Leonardo da Vinci ? Observez maintenant la carte contenue dans le sac et la couleur de la tache au niveau d’une salle mise en évidence sur la carte. Comme vous l'avez peut-être remarqué, les murs des salles thématiques consacrées à Leonardo ont des couleurs différentes. Cherchez la pièce peinte avec la couleur que vous avez trouvée sur la carte.</p>
      )}
      {(string === 's_2_mission8' && lang === 'fra') && (
         <p>Vous devez identifier quelle paire de roues dentées identiques exposées et placées sous la machine à fileter les vis (deux grandes, deux petites) doit être montée sur la machine à la place de celles présentes (les deux noires sur les côtés de la machine engrenées avec la manivelle) pour obtenir un filetage qui correspond (environ) à celui sur la barre présente dans le sac. Lisez le fonctionnement de la machine dans la légende.</p>
      )}
      {(string === 's_3_mission8' && lang === 'fra') && (
         <p>Vous devez déterminer le rapport entre la vitesse de rotation de la roue dentée centrale et des deux roues latérales. En général, si les roues mises en rotation (les deux roues externes) sont plus petites que la roue motrice (la roue centrale mise en rotation par la force appliquée à la manivelle), elle tourneront plus vite. Si elles sont plus grandes, elle tourneront plus lentement. Pour obtenir une barre filetée avec un pas de vis large, nous devrons installer sur la machine les deux roues (a et b), plus petites que celles présentes actuellement, afin que le chariot aille plus vite et que la lame produise un pas de vis large. A vitesse de rotation constante de la barre, le bout de bois à creuser avancera plus rapidement en un seul tour et la “spirale” sera plus large.</p>
      )}
</>
)
}
