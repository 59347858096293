import React from 'react'
import { BalloonContent } from "./content/BalloonContent"
import { HelpContent } from "./content/HelpContent"
import { MissionContent } from "./content/MissionContent"
import { CommonContent } from "./content/CommonContent"


import "./../assets/scss/Input.scss";

export const Input = (props) => {
   const langList = ["u_1_mission9"];
   const ticketList = ["u_2_mission9"];
   const loginList = ["u_3_mission9"];
   const startList = ["u_4_mission9"];
   const nickList = ["u_5_mission9"];

   const approList = ["u_8_mission0", "u_8_mission1", "u_8_mission2", "u_8_mission3", "u_8_mission4", "u_8_mission5", "u_8_mission6", "u_8_mission7", "u_8_mission8", "u_10_mission4", "u_10_mission7", "u_11_mission7"];
   const menuList = ["u_9_mission0", "u_9_mission1", "u_9_mission2", "u_9_mission3", "u_9_mission4", "u_9_mission5", "u_9_mission6", "u_9_mission7", "u_9_mission8"];

   const answerList = ["u_2_mission1", "u_2_mission2", "u_2_mission4", "u_2_mission5", "u_2_mission7", "u_4_mission7", "u_7_mission7", "u_2_mission8"];
   const goHaedList = ["u_1_mission0", "u_1b_mission0", "u_2_mission0", "u_1_mission1", "u_0a_mission2", "u_0b_mission2", "u_1_mission2", "u_0a_mission3", "u_0b_mission3", "u_0_mission3", "u_1_mission4", "u_3_mission4", "u_1_mission5", "u_1_mission6", "u_1_mission7", "u_1a_mission7", "u_5_mission7", "u_5a_mission7", "u_6_mission7", "u_1_mission8", "u_1_mission10", "u_2_mission10", "u_98_mission1", "u_98_mission2", "u_98_mission3", "u_98_mission4", "u_97_mission4", "u_98_mission5", "u_98_mission6", "u_96_mission7", "u_97_mission7", "u_98_mission7", "u_98_mission8", "u_0_mission10", "u_2_mission10", "u_3_mission10", "u_0_mission11", "u_1_mission11"];
   const answerList2 = ["u_4_mission4"];
   const answerList3 = ["u_1_mission3"];
   const answerList4 = ["u_2_mission6"];

   //const conteList = [];
   const conteList = ["u_3_mission7"];


   const [isActive, setIsActive] = React.useState([false,false,false,false,false,false,false,false]);

   const handleAccordionClick = (e, ind) => {
      let newIsActive = [false,false,false,false,false,false,false,false];
      newIsActive[ind] = !isActive[ind];
      setIsActive(newIsActive);
   }

   return(

      <div className={`input
         ${props.input}
         ${langList.includes(`${props.input}_mission${props.mission}`) ? "lang" : ""}
      `}>

         {(props.answerfeedback.includes("w") && false) && (
           <div className="wrong"></div>
        )}



          {(ticketList.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
                <form className="oneline" onSubmit={(e) => props.onSubmitTicket(e)}>
                  <input type="text" onChange={(e) => props.onChangeTicket(e)} className="" className={`form-control ${(props.loginfeedback == "empty" || props.loginfeedback == "code") ? "wrong" : ""}`} name="code" id="code" aria-describedby="emailHelp" placeholder="Code" value={props.ticket} />
                  <input type="submit" value="OK" />
                </form>
             </div>
          )}

          {(nickList.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
               <p>{props.loginfeedback}</p>
                <form className="oneline" onSubmit={(e) => props.onSubmitNick(e)}>
                  <input maxLength="15" type="text" onChange={(e) => props.onChangeNick(e)} className="form-control" name="nickname" id="nickname" aria-describedby="emailHelp" placeholder="Nickname" value={props.nick} />
                  <input type="submit" value="OK" />
                </form>
             </div>
          )}

          {(answerList.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
                <form className="oneline" onSubmit={(e) => props.onSubmitAnswer(e, props.mission, props.input)}>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[0] == "w" ? "wrong" : ""}`}  placeholder="" value={props.answer} onChange={(e) => props.onChangeAnswer(e)} />
                  </label>
                  <input type="submit" value="OK" />
                </form>


             </div>
          )}

          {(answerList2.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
                <div className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}_sugg`} /></div>
                <form className="multiline" onSubmit={(e) => props.onSubmitAnswer(e, props.mission, props.input)}>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}a_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[0] == "w" ? "wrong" : ""}`} placeholder="1." value={props.answer} onChange={(e) => props.onChangeAnswer(e, 1)} />
                  </label>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}b_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[1] == "w" ? "wrong" : ""}`} placeholder="2." value={props.answer2} onChange={(e) => props.onChangeAnswer(e, 2)} />
                  </label>
                  <input type="submit" value="OK" />
                </form>
             </div>
          )}

          {(answerList3.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
                <div className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}_sugg`} /></div>
                <form className="multiline" onSubmit={(e) => props.onSubmitAnswer(e, props.mission, props.input)}>

                  <input type="text" className={`form-control ${props.answerfeedback[0] == "w" ? "wrong" : ""}`} value={props.answer} onChange={(e) => props.onChangeAnswer(e, 1)} />
                  <input type="text" className={`form-control ${props.answerfeedback[1] == "w" ? "wrong" : ""}`} value={props.answer2} onChange={(e) => props.onChangeAnswer(e, 2)} />
                  <input type="text" className={`form-control ${props.answerfeedback[2] == "w" ? "wrong" : ""}`} value={props.answer3} onChange={(e) => props.onChangeAnswer(e, 3)} />
                  <input type="submit" value="OK" />
                </form>
             </div>
          )}

          {(answerList4.includes(`${props.input}_mission${props.mission}`)) && (
             <div className="rispostaCont">
                <div className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}_sugg`} /></div>
                <form className="multiline" onSubmit={(e) => props.onSubmitAnswer(e, props.mission, props.input)}>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}a_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[0] == "w" ? "wrong" : ""}`} placeholder="1." value={props.answer} onChange={(e) => props.onChangeAnswer(e, 1)} />
                  </label>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}b_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[1] == "w" ? "wrong" : ""}`} placeholder="2." value={props.answer2} onChange={(e) => props.onChangeAnswer(e, 2)} />
                  </label>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}c_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[2] == "w" ? "wrong" : ""}`} placeholder="3." value={props.answer3} onChange={(e) => props.onChangeAnswer(e, 3)} />
                  </label>
                  <label className="sugg"><BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}d_sugg`} />
                     <input type="text" className={`form-control ${props.answerfeedback[3] == "w" ? "wrong" : ""}`} placeholder="4." value={props.answer4} onChange={(e) => props.onChangeAnswer(e, 4)} />
                  </label>
                  <input type="submit" value="OK" />
                </form>
             </div>
          )}

          {(goHaedList.includes(`${props.input}_mission${props.mission}`)) && (
             <button className="btn" onClick={props.onGoHaed}>
               <BalloonContent lang={props.lang} stringToRet={`${props.input}_mission${props.mission}`} />
             </button>
          )}

          {(conteList.includes(`${props.input}_mission${props.mission}`)) && (
             <>
                <button className="btn" onClick={(e) => props.onConteAccessible(e, true)}>
                  <BalloonContent lang={props.lang} stringToRet={`${props.input}#a_mission${props.mission}`} />
                </button>
                <button className="btn" onClick={(e) => props.onConteAccessible(e, false)}>
                  <BalloonContent lang={props.lang} stringToRet={`${props.input}#b_mission${props.mission}`} />
                </button>
             </>
          )}

          {(startList.includes(`${props.input}_mission${props.mission}`)) && (
             <button className="btn" onClick={props.onStartMission}>
               <p>GO</p>
             </button>
          )}

          {(loginList.includes(`${props.input}_mission${props.mission}`) && props.mk == 'guest') && (
             <>
                <button className="btn" onClick={(e) => props.onWannaBeMaster(e, "loginGuest")}>
                   <BalloonContent lang={props.lang} stringToRet={`${props.input}g_mission${props.mission}`} />
                </button>
                <button className="btn" onClick={(e) => props.onWannaBeMaster(e, "loginMaster")}>
                   <BalloonContent lang={props.lang} stringToRet={`${props.input}m_mission${props.mission}`} />
                </button>
             </>
          )}

          {(approList.includes(`${props.input}_mission${props.mission}`)) && (
             <>
                <button className="btn" onClick={(e) => props.onOpenAppro(e, true)}>
                   <BalloonContent lang={props.lang} stringToRet={`${props.input}#a_mission${props.mission}`} />
                </button>
                <button className="btn" onClick={(e) => props.onOpenAppro(e, false)}>
                  <BalloonContent lang={props.lang} stringToRet={`${props.input}#b_mission${props.mission}`} />
                </button>
             </>
          )}

          {(langList.includes(`${props.input}_mission${props.mission}`)) && (
             <>
                <button className="btn" onClick={(e) => props.onChangeLang(e, "ita", true)}>
                  <p>ITA</p>
                </button>
                <button className="btn" onClick={(e) => props.onChangeLang(e, "fra", true)}>
                  <p>FRA</p>
                </button>
                <button className="btn" onClick={(e) => props.onChangeLang(e, "eng", true)}>
                  <p>ENG</p>
                </button>
             </>
          )}

          {(menuList.includes(`${props.input}_mission${props.mission}`)) && (
             <>
                {isActive.map((k, ind) => (

                   <div className={`missionList ${props.mission == ind+1 ? "now":  props.mdone[ind+1] ? "hide": ""}`} key={ind}>
                     <div className="accBlock">
                        <button
                           className={`clickAccbtn ${isActive[ind] ? "clicked": ""}`}
                           {...((!props.mdone[ind+1] && props.mission !== ind+1) ? { onClick:(e) => {handleAccordionClick(e, ind)}} : {})}>
                           <p><MissionContent lang={props.lang} stringToRet={`title_mission${ind+1}`} /></p>
                           {(!props.mdone[ind+1] && props.mission !== ind+1) && (
                              <span>></span>
                           )}
                        </button>
                      </div>
                      <div className={`missionDesc ${isActive[ind] ? "visible": ""}`}>
                        <MissionContent lang={props.lang} stringToRet={`desc_mission${ind+1}`} />
                        <button className="enter" onClick={() => props.onChangeMission(ind+1)}><CommonContent stringToRet="entra" lang={props.lang} /></button>
                      </div>
                    </div>
                 ))}
             </>
          )}

          {(props.suggestions && props.suggestions.length > 0) && (
             <>
               {props.suggestions.map((k, ind) => (
                   <button key={ind} className="btn" onClick={(e) => props.onSuggest(e, `${k}`)}>
                     <HelpContent lang={props.lang} stringToRet={`${k}_mission${props.mission}`} />
                   </button>
                ))}
             </>
          )}

      </div>


    )
}
