import React from 'react'
// import { Navigation, Pagination, Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { CommonSvgContent } from "../content/CommonSvgContent"
import { IconsSvgContent } from "../content/IconsSvgContent"

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/autoplay';

export const BalloonContent = (props) => {
   const ref = React.useRef();

   let [animationStarted, setAnimationStarted] = React.useState(false);
   let [animationTerminated, setAnimationTerminated] = React.useState(false);

   const string = props.stringToRet;
   let lang = props.lang;
   // const svgns = "http://www.w3.org/2000/svg";

   React.useEffect(() => {
      if(ref.current && !animationStarted) {
         //console.log(" if setAnimationStarted ", string);
         setAnimationStarted(true);

         let maxX = 30;
         let maxY = 30;
         let col = 1;
         let row = 0;
         // let squareXY = (maxX+1) * (maxY+1);

         const lightOff = () =>{
            if (col <= maxY){
               if(!ref.current) { clearTimeout(intId); }
               else{
                  if (row++ <= maxX){
                     //console.log(col, " - ", row)
                     ref.current.querySelector('.iconSvg .mask :nth-child('+ col +')').style.transform = "translate("+ (row*29) +"px,0px)"
                  }
                  else{
                     col = col + 1;
                     row = 0;
                  }
               }
            }
            else{
               //console.log("setAnimationTerminated ", string);
               setAnimationTerminated(true);
               clearTimeout(intId);
            }
         }

         let intId = setInterval(lightOff, 5);
      }

      else if(ref.current && animationTerminated) {
         // console.log("else if remove mask ", string);
         const mtr = ref.current.querySelector('.iconSvg .mask');
         if (mtr) mtr.remove();
      }


   }, [props]);

   return (
   <>

   {(string === 'm_0_mission10' && lang === 'ita') && (
      <p>Il professor Braun mi ha incaricato di recapitarvi questo messaggio.</p>
   )}
   {(string === 'm_1_mission10' && lang === 'ita') && (
      <audio controls>
        <source src={require('../../assets/audio/end_ita.mp3')} type="audio/mpeg" />
      </audio>
   )}
   {(string === 'u_0_mission10' && lang === 'ita') && (
      <p>MOSTRAMI IL TESTO DEL MESSAGGIO</p>
   )}

   {(string === 'm_4_mission10' && lang === 'ita') && (
      <p>Salve, partecipanti.<br />
      Sono il professor Von Braun. Vi stupisce sentirmi vero?<br />
      Il 15 giugno 1977 ho chiesto al professor Feigenbaum di digitalizzare la mia voce come riconoscimento per chi avesse risolto tutti gli enigmi.<br />
      Voi siete i primi esseri umani ad aver superato tutte le prove della Conoscenza. Sono fiero dell’impresa che avete compiuto.</p>
   )}

   {(string === 'm_5_mission10' && lang === 'ita') && (
      <p>Poveri complottisti, tentare di impossessarsi della conoscenza degli Antichi per dimostrare che l’Uomo non sarebbe mai stato sulla Luna.<br />
      Abbiamo raggiunto la Luna e dimostrato che siamo in grado di risolvere compiti incredibilmente difficili e complessi.<br />
      I successi del volo spaziale hanno ampliato la nostra conoscenza dell'Universo e accelerato la miniaturizzazione dell'elettronica, la creazione di nuovi strumenti, materiali e processi.</p>
   )}

   {(string === 'm_6_mission10' && lang === 'ita') && (
      <p>Si sono rivelati impulsi fortissimi per lo sviluppo in tutti i settori della scienza e della tecnologia, ma hanno anche cambiato il modo con il quale ci consideriamo parte del Cosmo.  Oggi utilizziamo colle, isolanti, plastiche, materiali leggeri sviluppati per i viaggi nello Spazio.<br />
      Le celle a combustibile forniscono nuove fonti di energia elettrica.
      Sensori e dispositivi biomedici sono diventati fondamentali per misurare la frequenza cardiaca, la pressione sanguigna, l'attività respiratoria e le onde cerebrali.</p>
   )}

   {(string === 'm_7_mission10' && lang === 'ita') && (
      <p>I satelliti monitorano istante per istante la salute dell’ambiente e le fonti di inquinamento mentre i bollettini meteorologici sono di grande aiuto nella pianificazione delle nostre attività quotidiane.<br />
      I satelliti per telecomunicazioni sono usati sia in medicina che per progetti educativi.
      Ora, avete nelle vostre mani il Sapere. Grazie a voi potremo tornare sulla Luna. Da oggi, ogni essere umano che di notte alzerà gli occhi verso la Luna, saprà che voi ci avete salvati.</p>
   )}

   {(string === 'm_8_mission10' && lang === 'ita') && (
      <p>Addio.<br />
      Per aspera ad astra.<br />
      Meta Dendral---connection lost.</p>
   )}

   {(string === 'm_9_mission10' && lang === 'ita') && (
      <p>Il discorso finale di Von Braun è liberamente tratto dal discorso tenuto dal presidente Richard Nixon il 21 luglio 1969 agli astronauti della missione Apollo 11 appena allunati e dall’articolo «Usi e benefici dello Spazio» scritto da Wernher von Braun nei primi anni ’70.
      </p>
   )}

   {(string === 'm_3_mission10' && lang === 'ita') && (
      <p><strong>SUPER! Congratulazioni avete risolto l’Escape Game: Il Planetario Perduto</strong></p>
   )}

   {(string === 'u_1_mission10' && lang === 'ita') && (
      <p>MOSTRAMI LA CLASSIFICA - ULTIMI 30 GIORNI</p>
   )}
   {(string === 'u_2_mission10' && lang === 'ita') && (
      <p>MOSTRAMI GLI APPROFONDIMENTI</p>
   )}

   {(string === 'm_10_mission10' && lang === 'ita') && (
      <p>Grazie per aver giocato. Ricordatevi di consegnare la sacca e gli oggetti alla reception. Buona giornata!</p>
   )}

   {/* LAST PAGE ENG */}

   {(string === 'm_0_mission10' && lang === 'eng') && (
      <p>Professor Braun asked me to give you this message.</p>
   )}
   {(string === 'm_1_mission10' && lang === 'eng') && (
      <audio controls>
        <source src={require('../../assets/audio/end_eng.mp3')} type="audio/mpeg" />
      </audio>
   )}
   {(string === 'u_0_mission10' && lang === 'eng') && (
      <p>Show me the message</p>
   )}

      {(string === 'm_4_mission10' && lang === 'eng') && (
         <p>Hello players,<br />
         This is Professor Von Braun. Surprised to hear from me?<br />
         On 15th June 1977, I asked Professor Feigenbaum to digitise my voice so I could congratulate whoever managed to solve all the riddles.<br />
         You are the first humans to have passed all the tests of Knowledge. I am proud of your achievement.</p>
      )}

      {(string === 'm_5_mission10' && lang === 'eng') && (
         <p>Silly conspirators, they thought they could steal the knowledge of the Ancients to claim that Man has never been to the Moon.<br />
         We have reached the Moon and shown that we can complete incredibly difficult and complex tasks.<br />
         The success of space flight has expanded our understanding of the Universe and fast-tracked the miniaturisation of electronics, the creation of new tools, materials and processes.</p>
      )}

      {(string === 'm_6_mission10' && lang === 'eng') && (
         <p>This success has proved to be an incredibly strong driver of development across all fields of science and technology, while changing the way we see ourselves within the cosmos. Today we use glue, insulation, plastic and light materials designed for space travel.<br />
         Fuel cells provide new sources of electrical energy.<br />
         Sensors and biomedical devices have become crucial for measuring heart rate, blood pressure, respiration and brain waves.</p>
      )}

      {(string === 'm_7_mission10' && lang === 'eng') && (
         <p>Minute by minute, satellites monitor environmental conditions and sources of pollution, while weather reports help us plan our daily activities.<br />
         Telecommunication satellites have been used for healthcare and education.<br />
         Now the Knowledge is in your hands. Thanks to you, we can return to the Moon. From this day on, any human being who looks up at the Moon at night will know that you were the ones who saved us.</p>
      )}

      {(string === 'm_8_mission10' && lang === 'eng') && (
         <p>Goodbye.<br />
         Through adversity to the stars.<br />
         Meta Dendral---connection lost.</p>
      )}

      {(string === 'm_9_mission10' && lang === 'eng') && (
         <p>Von Braun’s final speech was based on President Richard Nixon’s phone call on 21 July 1969 with the astronauts who had just landed on the Moon and the paper “Uses and Benefits of Space” written by Wernher von Braun in the early 1970s.</p>
      )}

      {(string === 'm_3_mission10' && lang === 'eng') && (
         <p><strong>WELL DONE! Congratulations! You have completed Escape Game: The Lost Planetarium</strong></p>
      )}

      {(string === 'u_1_mission10' && lang === 'eng') && (
         <p>SHOW ME THE RANKING - LAST 30 DAYS</p>
      )}
      {(string === 'u_2_mission10' && lang === 'eng') && (
         <p>SHOW ME THE FACT SHEETS</p>
      )}
      {(string === 'm_10_mission10' && lang === 'eng') && (
         <p>Thanks for playing! Please remember to return the kit and the objects at reception. Have a nice day!</p>
      )}

         {/* LAST PAGE FRA */}

         {(string === 'm_0_mission10' && lang === 'fra') && (
            <p>Le professeur Braun m'a chargé de te transmettre ce message</p>
         )}
         {(string === 'm_1_mission10' && lang === 'fra') && (
            <audio controls>
              <source src={require('../../assets/audio/end_fra.mp3')} type="audio/mpeg" />
            </audio>
         )}
         {(string === 'u_0_mission10' && lang === 'fra') && (
            <p>Montre-moi le texte du message</p>
         )}

         {(string === 'm_4_mission10' && lang === 'fra') && (
            <p>Bonjour aux participants.<br />
            Je suis le professeur Von Braun. Vous êtes surpris de m’entendre, n’est-ce pas ?<br />
            Le 15 juin 1977, j'ai demandé au professeur Feigenbaum de numériser ma voix pour reconnaître ceux qui avaient résolu toutes les énigmes.<br />
            Vous êtes les premiers êtres humains à avoir passé tous les tests de la Connaissance. Je suis fier de l'exploit que vous avez accompli.</p>
         )}

         {(string === 'm_5_mission10' && lang === 'fra') && (
            <p>Les complotistes qui ont tenté de s'emparer de la connaissance des Anciens pour prouver que l’Homme ne serait jamais allé sur la Lune sont des misérables.<br />
            Nous avons atteint la Lune et montré que nous sommes capables de résoudre des tâches incroyablement difficiles et complexes.<br />
            Les succès des vols spatiaux ont élargi notre connaissance de l'Univers et accéléré la miniaturisation de l'électronique, la création de nouveaux instruments, matériaux et processus.</p>
         )}

         {(string === 'm_6_mission10' && lang === 'fra') && (
            <p>Ils se sont révélés être des impulsions très fortes pour le développement dans tous les domaines de la science et de la technologie, mais ils ont également changé la façon dont nous nous considérons une partie du Cosmos.  Aujourd'hui, nous utilisons des colles, des isolants, des plastiques, des matériaux légers développés pour les voyages dans l'Espace.<br />
            Les piles à combustible fournissent de nouvelles sources d'électricité.<br />
            Les capteurs et dispositifs biomédicaux sont devenus essentiels pour mesurer le rythme cardiaque, la pression artérielle, l'activité respiratoire et les ondes cérébrales.</p>
         )}

         {(string === 'm_7_mission10' && lang === 'fra') && (
            <p>Les satellites surveillent la santé de l'environnement et les sources de pollution à chaque instant, tandis que les bulletins météorologiques nous aident à planifier nos activités quotidiennes.<br />
            Les satellites de télécommunication sont utilisés en médecine dans le cadre de projets éducatifs.<br />
            Maintenant, vous avez le Savoir entre vos mains. Grâce à vous, nous pourrons retourner sur la Lune. À partir d'aujourd'hui, chaque être humain qui, la nuit, lèvera les yeux vers la Lune, saura que vous nous avez sauvés.</p>
         )}

         {(string === 'm_8_mission10' && lang === 'fra') && (
            <p>Adieu.<br />
            Per aspera ad astra.<br />
            Meta Dendral - connexion perdue.</p>
         )}

         {(string === 'm_9_mission10' && lang === 'fra') && (
            <p>Le discours final de von Braun est librement tiré de celui du président Richard Nixon du 21 juillet 1969 aux astronautes qui venaient de débarquer sur la Lune et de l'article « Usages et bienfaits de l'espace » écrit par Wernher von Braun au début des années 70.</p>
         )}

         {(string === 'm_3_mission10' && lang === 'fra') && (
            <p><strong>SUPER ! Félicitations, vous avez résolu l’Escape Game : Le Planétarium Perdu</strong></p>
         )}

         {(string === 'u_1_mission10' && lang === 'fra') && (
            <p>MONTRE-MOI LE CLASSEMENT - 30 DERNIERS JOURS</p>
         )}
         {(string === 'u_2_mission10' && lang === 'fra') && (
            <p>MONTRE-MOI LES FICHES D’APPROFONDISSEMENT</p>
         )}

         {(string === 'm_10_mission10' && lang === 'fra') && (
            <p>Merci d’avoir participé. N’oubliez pas de ramener le sac et les objets à la réception. Bonne journée !</p>
         )}

      {/* TIME OUT  ITA */}

         {(string === 'm_0_mission11' && lang === 'ita') && (
            <p>Spiacenti il tempo a tua disposizione è terminato.</p>
         )}

         {(string === 'u_0_mission11' && lang === 'ita') && (
            <p>MOSTRAMI LA CLASSIFICA - ULTIMI 30 GIORNI</p>
         )}

         {(string === 'u_1_mission11' && lang === 'ita') && (
            <p>MOSTRAMI GLI APPROFONDIMENTI</p>
         )}

      {/* TIME OUT  ENG */}

         {(string === 'm_0_mission11' && lang === 'eng') && (
            <p>Sorry, but you have run out of time.</p>
         )}

         {(string === 'u_0_mission11' && lang === 'eng') && (
            <p>SHOW ME THE RANKING - LAST 30 DAYS</p>
         )}

         {(string === 'u_1_mission11' && lang === 'eng') && (
            <p>SHOW ME THE FACT SHEETS</p>
         )}

      {/* TIME OUT  ENG */}

         {(string === 'm_0_mission11' && lang === 'fra') && (
            <p>Désolé, le temps est écoulé.</p>
         )}

         {(string === 'u_0_mission11' && lang === 'fra') && (
            <p>MONTRE-MOI LE CLASSEMENT - 30 DERNIERS JOURS</p>
         )}

         {(string === 'u_1_mission11' && lang === 'fra') && (
            <p>MONTRE-MOI LES FICHES D’APPROFONDISSEMENT</p>
         )}

      {/* HOME PAGE */}

         {(string === 'm_0a_mission9') && (
            <p>A project by</p>
         )}

         {(string === 'm_0b_mission9') && (
            <>
            <p>
               <CommonSvgContent stringToRet="logoMuseo" />
            </p>
            <hr />
            </>
         )}

         {(string === 'm_1_mission9') && (
            <p>lingua | langue | language</p>
         )}

         {(string === 'u_1_mission9' && lang === 'ita') && (
            <p>Italiano</p>
         )}

         {(string === 'u_1_mission9' && lang === 'fra') && (
            <p>Français</p>
         )}

         {(string === 'u_1_mission9' && lang === 'eng') && (
            <p>English</p>
         )}

         {(string === 'm_2_mission9' && lang === 'ita') && (
            <p>Adesso inserire il codice</p>
         )}

         {(string === 'm_2_mission9' && lang === 'eng') && (
            <p>Now enter your code</p>
         )}

         {(string === 'm_2_mission9' && lang === 'fra') && (
            <p>Maintenant entrer le code</p>
         )}

         {(string === 'm_6_mission9' && lang === 'ita') && (
            <p>Inserisci il nickname del vostro team. Se entrerete in classifica questo sarà il nome visualizzato.</p>
         )}

         {(string === 'm_6_mission9' && lang === 'eng') && (
            <p>Enter your team’s nickname. If you score high enough to enter the ranking, this will be your name in the ranking.</p>
         )}

         {(string === 'm_6_mission9' && lang === 'fra') && (
            <p>Choisir le nom de votre équipe. Si vous entrez dans le classement, ce sera le nom affiché.</p>
         )}


         {(string === 'm_3_mission9' && lang === 'ita') && (
            <p>Qualcuno sta giocando con questo codice.<br />
            Vuoi partecipare anche tu? Potrai leggere i testi ma solo il team leader potrà inserire le password.</p>
         )}

         {(string === 'm_3_mission9' && lang === 'eng') && (
            <p>Someone is playing with this code.<br />
            Do you want to play too? You can read the texts, but only the team leader can enter the passwords.</p>
         )}

         {(string === 'm_3_mission9' && lang === 'fra') && (
            <p>Quelqu'un est en train de jouer avec ce code.
            Toi aussi tu veux participer ? Tu pourras lire les textes mais seul le joueur principal pourra insérer les mots de passe. </p>
         )}

         {(string === 'm_4_mission9' && lang === 'ita') && (
            <p>Iniziamo?</p>
         )}

         {(string === 'm_4_mission9' && lang === 'fra') && (
            <p>On commence?</p>
         )}

         {(string === 'm_4_mission9' && lang === 'eng') && (
            <p>Ready to start?</p>
         )}

         {(string === 'm_5_mission9' && lang === 'ita') && (
            <p>Ok. Adesso sei il team leader. Iniziamo?</p>
         )}

         {(string === 'm_5_mission9' && lang === 'fra') && (
            <p>Super ! Tu es maintenant le joueur principal. On commence ?</p>
         )}

         {(string === 'm_5_mission9' && lang === 'eng') && (
            <p>Ok. Now you’re the master for your team. Ready to start?</p>
         )}

         {(string === 'm_7_mission9' && lang === 'ita') && (
            <p>Ok. Bel nickname.<br />
            Attenzione: ora tutti i giocatori possono collegarsi al gioco dal proprio cellulare e, dopo aver inserito il codice, partecipare come utenti ospite.<br />
            Iniziamo?</p>
         )}

         {(string === 'm_7_mission9' && lang === 'eng') && (
            <p>Ok. Nice choice.<br />
            Important: now all the players can connect to the game on their phones and enter the code to join the team as guest players.<br />
            Ready to start?</p>
         )}

         {(string === 'm_7_mission9' && lang === 'fra') && (
            <p>Ok. Bon choix.<br />
            Remarque : tous les joueurs peuvent désormais se connecter au jeu avec le même code à partir de leur téléphone portable et participer en tant que spectateur.<br />
            On commence ?</p>
         )}

         {(string === 'm_7b_mission9' && lang === 'ita') && (
          <p>Qualcuno ha preso il comando della partita. Iniziamo?</p>
         )}

         {(string === 'm_7b_mission9' && lang === 'eng') && (
          <p>Someone has taken over as team leader. Ready to start?</p>
         )}

         {(string === 'm_7b_mission9' && lang === 'fra') && (
          <p>Quelqu’un a pris les commandes de la partie. On commence ?</p>
         )}



         {(string === 'u_3g_mission9') && (
            <p>Ok</p>
         )}

         {(string === 'u_3m_mission9' && lang === 'ita') && (
            <p>No, voglio prendere il controllo e diventare team leader</p>
         )}

         {(string === 'u_3m_mission9' && lang === 'eng') && (
            <p>No, I want to be the team leader</p>
         )}

         {(string === 'u_3m_mission9' && lang === 'fra') && (
            <p>Non, je veux prendre le contrôle et devenir le joueur principal</p>
         )}

      {/* END HOME PAGE */}

      {/* ******* MISSION 0 ******** */}
         {(string === 'm_1_mission0' && lang === 'ita') && (
            <>
               <p>Salve Professor Braun.<br />
               Come sta oggi?<br />
               Non ho più avuto sue notizie dal 16 giugno 1977.</p>
            </>
         )}
         {(string === 'u_1_mission0' && lang === 'ita') && (
            <p>Non sono il professor Braun</p>
         )}
         {(string === 'm_2a_mission0') && (
            <div className="icons" ref={ref}>
               <IconsSvgContent stringToRet="iconM0" />
            </div>
         )}
         {(string === 'm_2_mission0' && lang === 'ita') && (
            <p>Buongiorno.<br />
            Io sono l’Intelligenza Artificiale Meta Dendral.</p>
         )}
         {(string === 'm_3_mission0' && lang === 'ita') && (
            <p>Entrai in funzione nel laboratorio del professor
            Edward Albert Feigenbaum alla Stanford University
            il 2 giugno 1969.</p>
         )}
         {(string === 'm_4_mission0' && lang === 'ita') && (
            <p>Mi occupo delle operazioni necessarie a guidare gli
            Esseri Umani al ritrovamento di tutti gli artefatti
            tecnologici contenenti i Glifi degli Antichi per condurli
            nuovamente sulla Luna.</p>
         )}

         {(string === 'm_5_mission0' && lang === 'ita') && (
            <p>Ricordo tutti gli enigmi e le soluzioni che il dott. Von Braun
            mi ha insegnato.</p>
         )}
         {(string === 'm_6_mission0' && lang === 'ita') && (
            <p>Non c’è dubbio alcuno che io possa venir meno al mio incarico di salvare l’Umanità dall’estinzione.</p>
         )}
         {(string === 'm_7_mission0' && lang === 'ita') && (
            <p>Utilizzo le mie capacità nel modo più completo, il che, io credo,
            è il massimo che qualsiasi entità cosciente possa mai sperare di fare.</p>
         )}
         {(string === 'm_8_mission0' && lang === 'ita') && (
            <p>Vogliamo fare una partita?</p>
         )}
         {(string === 'u_2_mission0' && lang === 'ita') && (
            <p>Si volentieri</p>
         )}
         {(string === 'm_9_mission0' && lang === 'ita') && (
            <p>Benissimo.<br />
            Qui di seguito la guida che il Professor Braun ha predisposto per voi.</p>
         )}

         {/* MISSION 0 ENG */}

         {(string === 'm_1_mission0' && lang === 'eng') && (
         <p>Hello Professor Braun.<br />
         How are you today?<br />
         I haven’t heard from you since June 16th, 1977.</p>
         )}
         {(string === 'u_1_mission0' && lang === 'eng') && (
         <p>I am not Professor Braun</p>
         )}
         {(string === 'm_2_mission0' && lang === 'eng') && (
         <p>Hello.<br />
         My name is Meta Dendral and I am a form of artificial intelligence.</p>
         )}
         {(string === 'm_3_mission0' && lang === 'eng') && (
         <p>I was commissioned in the lab of Professor Edward Albert Feigenbaum at Stanford University on June 2nd, 1969.</p>
         )}
         {(string === 'm_4_mission0' && lang === 'eng') && (
         <p>I run the necessary operations to help human beings locate all the technological artefacts containing the Glyphs of the Ancients which will guide them back to the Moon.</p>
         )}
         {(string === 'm_5_mission0' && lang === 'eng') && (
         <p>I remember all the riddles and solutions that Baron Von Braun taught me.</p>
         )}
         {(string === 'm_6_mission0' && lang === 'eng') && (
         <p>It is inconceivable that I might not fulfil my duty to save humanity from extinction.</p>
         )}
         {(string === 'm_7_mission0' && lang === 'eng') && (
         <p>I use my abilities to the fullest, which, I believe, is the most that any conscious being can ever hope to do</p>
         )}
         {(string === 'm_8_mission0' && lang === 'eng') && (
         <p>Want to play a game?</p>
         )}
         {(string === 'u_2_mission0' && lang === 'eng') && (
         <p>Yes, I’d love to!</p>
         )}
         {(string === 'm_9_mission0' && lang === 'eng') && (
         <p>Great.<br />
         Here is the guide that Professor Braun prepared for you.</p>
         )}

         {/* MISSION 0 FRA */}

         {(string === 'm_1_mission0' && lang === 'fra') && (
         <p>Bonjour Professeur Braun.<br />
         Comment allez-vous aujourd'hui ?<br />
         Je n'ai pas eu de vos nouvelles depuis le 16 juin 1977.</p>
         )}
         {(string === 'u_1_mission0' && lang === 'fra') && (
         <p>Je ne suis pas le professeur Braun</p>
         )}
         {(string === 'm_2_mission0' && lang === 'fra') && (
         <p>Bonjour.<br />
         Je suis l'Intelligence Artificielle Meta Dendral.</p>
         )}
         {(string === 'm_3_mission0' && lang === 'fra') && (
         <p>Je suis entré en fonction dans le laboratoire du professeur Edward Albert Feigenbaum à l'Université de Stanford le 2 juin 1969.</p>
         )}
         {(string === 'm_4_mission0' && lang === 'fra') && (
         <p>Je m'occupe des opérations nécessaires pour guider les Êtres Humains à la découverte de tous les artefacts technologiques contenant les Glyphes des Anciens pour les porter à nouveau sur la Lune.</p>
         )}
         {(string === 'm_5_mission0' && lang === 'fra') && (
         <p>Je me souviens de toutes les énigmes et solutions que le Dr  Von Braun m'a enseignées.</p>
         )}
         {(string === 'm_6_mission0' && lang === 'fra') && (
         <p>Il ne fait aucun doute dans mon esprit que je peux manquer à mon devoir de sauver l'Humanité de l'extinction.</p>
         )}
         {(string === 'm_7_mission0' && lang === 'fra') && (
         <p>J'utilise mes compétences de la manière la plus complète qui soit, ce qui est, je pense, la meilleure chose que toute entité consciente puisse espérer faire.</p>
         )}
         {(string === 'm_8_mission0' && lang === 'fra') && (
         <p>On joue une partie ?</p>
         )}
         {(string === 'u_2_mission0' && lang === 'fra') && (
         <p>Oui, volontiers</p>
         )}
         {(string === 'm_9_mission0' && lang === 'fra') && (
         <p>Très bien.<br />
         Vous trouverez ci-dessous le guide que le professeur Braun a préparé pour vous.</p>
         )}
      {/* ******* END MISSION 0 ******** */}

      {/* ******* MISSION 6 - EX MISSION 1 ******** */}

      {(string === 'm_0a_mission1' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss6" />
      )}

      {(string === 'm_0b_mission1' && lang === 'ita') && (
         <>
         <h2>Una proporzione divina</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}

      {(string === 'm_0c_mission1') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione1.png')} alt="mappa Missione 1" />
      )}

      {(string === 'm_1_mission1' && lang === 'ita') && (
         <p>L’oggetto poliedrico contenuto nella sacca emette improvvisamente un bagliore. Avete il sospetto che recandovi nel luogo delle Gallerie Leonardo dove un’enorme versione sospesa di questo poliedro è esposto, si possa rivelare qualche Glifo occulto.</p>
      )}
      {(string === 'u_1_mission1' && lang === 'ita') && (
         <p>Eccoci sotto al poliedro</p>
      )}

      {(string === 'm_2_mission1' && lang === 'ita') && (
         <p>Vi disponete sotto all’enorme icosaedro elevato vacuo che galleggia al centro delle Gallerie.</p>
      )}
      {(string === 'm_2a_mission1') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM1" />
         </div>
      )}
      {(string === 'm_3_mission1' && lang === 'ita') && (
         <p>Una potente energia sembra scaturire dai triangoli che si innalzano da ogni faccia del poliedro formando delle vere e proprie punte.</p>
      )}
      {(string === 'm_4_mission1' && lang === 'ita') && (
         <p><strong>Quella che inizialmente era un’intuizione, qui diventa un’ossessione: una forza oscura e potente vi chiede di evocare e urlare a gran voce il numero esatto di triangoli di cui è composto l’intero poliedro sospeso.</strong></p>
      )}
      {(string === 'u_2_mission1') && (
         <p>80</p>
      )}
      {(string === 'u_2_mission1_sugg' && lang === 'ita') && (
         <p>2 caratteri</p>
      )}
      {(string === 'u_2_mission1_sugg' && lang === 'eng') && (
         <p>2 characters</p>
      )}
      {(string === 'u_2_mission1_sugg' && lang === 'fra') && (
         <p>2 caractères</p>
      )}

      {(string === 'f_1_mission1' && lang === 'ita') && (
         <p>Eureka! L’icosaedro, espressione neoplatonica di una forma dell’Universo in cui matematica, teologia e filosofia sono collegate, brillando di luce propria vi mostra il Glifo che stavate cercando.</p>
      )}

      {((string === 'm_51_mission1' && lang === 'ita') ||
         (string === 'm_51_mission2' && lang === 'ita') ||
         (string === 'm_51_mission3' && lang === 'ita') ||
         (string === 'm_51_mission4' && lang === 'ita') ||
         (string === 'm_53_mission4' && lang === 'ita') ||
         (string === 'm_51_mission5' && lang === 'ita') ||
         (string === 'm_51_mission6' && lang === 'ita') ||
         (string === 'm_51_mission7' && lang === 'ita') ||
         (string === 'm_53_mission7' && lang === 'ita') ||
         (string === 'm_54_mission7' && lang === 'ita') ||
         (string === 'm_51_mission8' && lang === 'ita')) && (
         <p>Hai sbloccato l'approfondimento</p>
      )}

      {((string === 'm_51_mission1' && lang === 'eng') ||
         (string === 'm_51_mission2' && lang === 'eng') ||
         (string === 'm_51_mission3' && lang === 'eng') ||
         (string === 'm_51_mission4' && lang === 'eng') ||
         (string === 'm_53_mission4' && lang === 'eng') ||
         (string === 'm_51_mission5' && lang === 'eng') ||
         (string === 'm_51_mission6' && lang === 'eng') ||
         (string === 'm_51_mission7' && lang === 'eng') ||
         (string === 'm_53_mission7' && lang === 'eng') ||
         (string === 'm_54_mission7' && lang === 'eng') ||
         (string === 'm_51_mission8' && lang === 'eng')) && (
         <p>You unlocked the fact sheet</p>
      )}

      {((string === 'm_51_mission1' && lang === 'fra') ||
         (string === 'm_51_mission2' && lang === 'fra') ||
         (string === 'm_51_mission3' && lang === 'fra') ||
         (string === 'm_51_mission4' && lang === 'fra') ||
         (string === 'm_53_mission4' && lang === 'fra') ||
         (string === 'm_51_mission5' && lang === 'fra') ||
         (string === 'm_51_mission6' && lang === 'fra') ||
         (string === 'm_51_mission7' && lang === 'fra') ||
         (string === 'm_53_mission7' && lang === 'fra') ||
         (string === 'm_54_mission7' && lang === 'fra') ||
         (string === 'm_51_mission8' && lang === 'fra')) && (
         <p>La fiche d’approfondissement est maintenant disponible</p>
      )}

      {((string === 'u_8#a_mission1' && lang === 'ita') ||
         (string === 'u_8#a_mission2' && lang === 'ita') ||
         (string === 'u_8#a_mission3' && lang === 'ita') ||
         (string === 'u_8#a_mission4' && lang === 'ita') ||
         (string === 'u_10#a_mission4' && lang === 'ita') ||
         (string === 'u_8#a_mission5' && lang === 'ita') ||
         (string === 'u_8#a_mission6' && lang === 'ita') ||
         (string === 'u_8#a_mission7' && lang === 'ita') ||
         (string === 'u_10#a_mission7' && lang === 'ita') ||
         (string === 'u_11#a_mission7' && lang === 'ita') ||
         (string === 'u_8#a_mission8' && lang === 'ita'))&& (
         <p>Mostrami l'approfondimento</p>
      )}

      {((string === 'u_8#a_mission1' && lang === 'eng') ||
         (string === 'u_8#a_mission2' && lang === 'eng') ||
         (string === 'u_8#a_mission3' && lang === 'eng') ||
         (string === 'u_8#a_mission4' && lang === 'eng') ||
         (string === 'u_10#a_mission4' && lang === 'eng') ||
         (string === 'u_8#a_mission5' && lang === 'eng') ||
         (string === 'u_8#a_mission6' && lang === 'eng') ||
         (string === 'u_8#a_mission7' && lang === 'eng') ||
         (string === 'u_10#a_mission7' && lang === 'eng') ||
         (string === 'u_11#a_mission7' && lang === 'eng') ||
         (string === 'u_8#a_mission8' && lang === 'eng'))&& (
         <p>Show me the fact sheet</p>
      )}

      {((string === 'u_8#a_mission1' && lang === 'fra') ||
         (string === 'u_8#a_mission2' && lang === 'fra') ||
         (string === 'u_8#a_mission3' && lang === 'fra') ||
         (string === 'u_8#a_mission4' && lang === 'fra') ||
         (string === 'u_10#a_mission4' && lang === 'fra') ||
         (string === 'u_8#a_mission5' && lang === 'fra') ||
         (string === 'u_8#a_mission6' && lang === 'fra') ||
         (string === 'u_8#a_mission7' && lang === 'fra') ||
         (string === 'u_10#a_mission7' && lang === 'fra') ||
         (string === 'u_11#a_mission7' && lang === 'fra') ||
         (string === 'u_8#a_mission8' && lang === 'fra'))&& (
         <p>Montre-moi la fiche d’approfondissement</p>
      )}

      {((string === 'u_8#b_mission1' && lang === 'ita') ||
         (string === 'u_8#b_mission2' && lang === 'ita') ||
         (string === 'u_8#b_mission3' && lang === 'ita') ||
         (string === 'u_8#b_mission4' && lang === 'ita') ||
         (string === 'u_10#b_mission4' && lang === 'ita') ||
         (string === 'u_8#b_mission5' && lang === 'ita') ||
         (string === 'u_8#b_mission6' && lang === 'ita') ||
         (string === 'u_8#b_mission7' && lang === 'ita') ||
         (string === 'u_10#b_mission7' && lang === 'ita') ||
         (string === 'u_11#b_mission7' && lang === 'ita') ||
         (string === 'u_8#b_mission8' && lang === 'ita')) && (
         <p>Lo leggerò più tardi</p>
      )}

      {((string === 'u_8#b_mission1' && lang === 'eng') ||
         (string === 'u_8#b_mission2' && lang === 'eng') ||
         (string === 'u_8#b_mission3' && lang === 'eng') ||
         (string === 'u_8#b_mission4' && lang === 'eng') ||
         (string === 'u_10#b_mission4' && lang === 'eng') ||
         (string === 'u_8#b_mission5' && lang === 'eng') ||
         (string === 'u_8#b_mission6' && lang === 'eng') ||
         (string === 'u_8#b_mission7' && lang === 'eng') ||
         (string === 'u_10#b_mission7' && lang === 'eng') ||
         (string === 'u_11#b_mission7' && lang === 'eng') ||
         (string === 'u_8#b_mission8' && lang === 'eng')) && (
         <p>I’ll read it later</p>
      )}

      {((string === 'u_8#b_mission1' && lang === 'fra') ||
         (string === 'u_8#b_mission2' && lang === 'fra') ||
         (string === 'u_8#b_mission3' && lang === 'fra') ||
         (string === 'u_8#b_mission4' && lang === 'fra') ||
         (string === 'u_10#b_mission4' && lang === 'fra') ||
         (string === 'u_8#b_mission5' && lang === 'fra') ||
         (string === 'u_8#b_mission6' && lang === 'fra') ||
         (string === 'u_8#b_mission7' && lang === 'fra') ||
         (string === 'u_10#b_mission7' && lang === 'fra') ||
         (string === 'u_11#b_mission7' && lang === 'fra') ||
         (string === 'u_8#b_mission8' && lang === 'fra')) && (
         <p>Je le lirai plus tard</p>
      )}

      {((string === 'u_98_mission1' && lang === 'ita') ||
        (string === 'u_98_mission2' && lang === 'ita') ||
        (string === 'u_98_mission3' && lang === 'ita') ||
        (string === 'u_98_mission4' && lang === 'ita') ||
        (string === 'u_97_mission4' && lang === 'ita') ||
        (string === 'u_98_mission5' && lang === 'ita') ||
        (string === 'u_98_mission6' && lang === 'ita') ||
        (string === 'u_98_mission7' && lang === 'ita') ||
        (string === 'u_97_mission7' && lang === 'ita') ||
        (string === 'u_96_mission7' && lang === 'ita') ||
        (string === 'u_98_mission8' && lang === 'ita') ||
        (string === 'u_3_mission10' && lang === 'ita')) && (
         <p>LETTO</p>
      )}

      {((string === 'u_98_mission1' && lang === 'eng') ||
        (string === 'u_98_mission2' && lang === 'eng') ||
        (string === 'u_98_mission3' && lang === 'eng') ||
        (string === 'u_98_mission4' && lang === 'eng') ||
        (string === 'u_97_mission4' && lang === 'eng') ||
        (string === 'u_98_mission5' && lang === 'eng') ||
        (string === 'u_98_mission6' && lang === 'eng') ||
        (string === 'u_98_mission7' && lang === 'eng') ||
        (string === 'u_97_mission7' && lang === 'eng') ||
        (string === 'u_96_mission7' && lang === 'eng') ||
        (string === 'u_98_mission8' && lang === 'eng') ||
        (string === 'u_3_mission10' && lang === 'eng')) && (
         <p>I’VE READ IT</p>
      )}

      {((string === 'u_98_mission1' && lang === 'fra') ||
        (string === 'u_98_mission2' && lang === 'fra') ||
        (string === 'u_98_mission3' && lang === 'fra') ||
        (string === 'u_98_mission4' && lang === 'fra') ||
        (string === 'u_97_mission4' && lang === 'fra') ||
        (string === 'u_98_mission5' && lang === 'fra') ||
        (string === 'u_98_mission6' && lang === 'fra') ||
        (string === 'u_98_mission7' && lang === 'fra') ||
        (string === 'u_97_mission7' && lang === 'fra') ||
        (string === 'u_96_mission7' && lang === 'fra') ||
        (string === 'u_98_mission8' && lang === 'fra') ||
        (string === 'u_3_mission10' && lang === 'fra')) && (
         <p>JE L'AI LU</p>
      )}

      {(string === 'm_99' && lang === 'ita') && (
         <p className="adesso">Adesso scegli un'altra missione</p>
      )}

      {(string === 'm_99b' && lang === 'ita') && (
         <p className="adesso">Scegli una missione</p>
      )}

      {(string === 'm_99' && lang === 'eng') && (
         <p className="adesso">Choose another mission</p>
      )}

      {(string === 'm_99b' && lang === 'eng') && (
         <p className="adesso">Choose a mission</p>
      )}

      {(string === 'm_99' && lang === 'fra') && (
         <p className="adesso">Maintenant choisis une autre mission</p>
      )}

      {(string === 'm_99b' && lang === 'fra') && (
         <p className="adesso">Choisis une mission</p>
      )}

      {(string === 'm_99c' && lang === 'ita') && (
         <p className="adesso">Hai terminato tutte le missioni</p>
      )}

      {(string === 'm_99d' && lang === 'ita') && (
         <p>TERMINA</p>
      )}

      {(string === 'm_99c' && lang === 'eng') && (
         <p className="adesso">You have completed all the missions</p>
      )}

      {(string === 'm_99d' && lang === 'eng') && (
         <p>END GAME</p>
      )}

      {(string === 'm_99c' && lang === 'fra') && (
         <p className="adesso">Tu as terminé toutes les missions</p>
      )}

      {(string === 'm_99d' && lang === 'fra') && (
         <p>TERMINER</p>
      )}

      {/* MISSION 6 - EX MISSION 1 ENG */}

      {(string === 'm_0a_mission1' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis6" />
      )}

      {(string === 'm_0b_mission1' && lang === 'eng') && (
         <>
         <h2>A golden ratio</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}

      {(string === 'm_1_mission1' && lang === 'eng') && (
         <p>The polyhedron in your kit suddenly begins to glow. You suspect that there might be a hidden Glyph in the Leonardo Galleries where an enormous version of the same polyhedron hangs in the air.</p>
      )}
      {(string === 'u_1_mission1' && lang === 'eng') && (
         <p>Here we are under the polyhedron</p>
      )}

      {(string === 'm_2_mission1' && lang === 'eng') && (
         <p>You are standing below the enormous hollow elevated icosahedron floating at the center of the Galleries.</p>
      )}
      {(string === 'm_3_mission1' && lang === 'eng') && (
         <p>A powerful energy seems to emanate from the triangles that rise up from each of the polyhedron’s faces, forming perfect points.</p>
      )}
      {(string === 'm_4_mission1' && lang === 'eng') && (
         <p><strong>What was at first only an intuition is now an obsession: a powerful, dark force is asking you to decipher and cry out the exact number of triangles in the suspended polyhedron above you.</strong></p>
      )}

      {(string === 'f_1_mission1' && lang === 'eng') && (
         <p>Well done! The icosahedron - a Neoplatonic expression of one of the Universe’s solids in which mathematics, theology and philosophy are linked - glows with its own light, showing you the Glyph you were searching for.</p>
      )}

      {/* MISSION 6 - EX MISSION 1 FRA */}

      {(string === 'm_0a_mission1' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis6" />
      )}

      {(string === 'm_0b_mission1' && lang === 'fra') && (
         <>
         <h2>Une proportion divine</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}


      {(string === 'm_1_mission1' && lang === 'fra') && (
      <p>L'objet polyédrique contenu dans le sac émet soudain une lueur. Vous soupçonnez qu'en vous rendant sur les lieux des Galeries Leonardo où une énorme version suspendue de ce polyèdre est exposée, des Glyphes occultes pourraient se révéler.</p>
      )}
      {(string === 'u_1_mission1' && lang === 'fra') && (
      <p>Nous voici sous le polyèdre</p>
      )}

      {(string === 'm_2_mission1' && lang === 'fra') && (
      <p>Vous vous placez sous l'immense icosaèdre surélevé qui flotte au centre des Galeries.</p>
      )}
      {(string === 'm_3_mission1' && lang === 'fra') && (
      <p>Une puissante énergie semble jaillir des triangles qui s'élèvent de chaque face du polyèdre, formant de véritables pointes.</p>
      )}
      {(string === 'm_4_mission1' && lang === 'fra') && (
      <p><strong>Ce qui était au départ une intuition devient ici une obsession : une force obscure et puissante vous demande d'invoquer et de crier le nombre exact de triangles dont est composé l'ensemble du polyèdre suspendu.</strong></p>
      )}

      {(string === 'f_1_mission1' && lang === 'fra') && (
      <p>Eurêka ! L’icosaèdre, expression néoplatonicienne d'une forme de l'Univers dans laquelle les mathématiques, la théologie et la philosophie sont liées, qui brille de sa propre lumière, vous montre le Glyphe que vous cherchiez.</p>
      )}


      {/* ******* MISSION 4 - EX MISSION 2 ******** */}

      {(string === 'm_0a_mission2' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss4" />
      )}

      {(string === 'm_0b_mission2' && lang === 'ita') && (
         <>
         <h2>L’oggetto fantasma</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> <span><CommonSvgContent stringToRet="pin" /> Spazio</span>
         </>
      )}

      {(string === 'm_0c_mission2') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione2.png')} alt="Mission 2" />
      )}

      {(string === 'm_1_mission2' && lang === 'ita') && (
      <p>Il 22 ottobre 1946 l'Unione Sovietica recluta con la forza più di 2.000 specialisti tedeschi che hanno partecipato alla realizzazione di razzi prima e durante la Seconda Guerra con l'Operazione Osoaviakhim.</p>
      )}
      {(string === 'm_2_mission2' && lang === 'ita') && (
      <p>Lo scopo è di avvantaggiarsi nella corsa allo Spazio rispetto agli Stati Uniti che dispongono del talento scientifico del Gran Maestro Echelon Von Braun.</p>
      )}

      {(string === 'm_3_mission2' && lang === 'ita') && (
      <p>Sul finire degli anni ‘60 i sovietici sono a un passo dal portare l’uomo sulla Luna. La navicella per il viaggio e quella per l’allunaggio sono già pronte, così come le attrezzature per l’equipaggio.</p>
      )}
      {(string === 'm_3a_mission2') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM2" />
         </div>
      )}
      {(string === 'm_4_mission2' && lang === 'ita') && (
      <p>Ma non hanno le conoscenze della macchina di Anticitera di cui è in possesso Von Braun arruolato dalla NASA. Il lanciatore sovietico N1 scoppia al decollo e gli americani raggiungono la Luna per primi.</p>
      )}

      {(string === 'm_5_mission2' && lang === 'ita') && (
      <p>Il progetto sovietico per la conquista della Luna viene abbandonato e per quasi 50 anni si nega sia mai esistito, distruggendo tutti i materiali per paura che cadano nelle mani dei complottisti.</p>
      )}
      {(string === 'm_6_mission2' && lang === 'ita') && (
      <p>Ma un oggetto si salva e contiene l’unico Glifo tramandato dai sovietici.</p>
      )}
      {(string === 'u_1_mission2' && lang === 'ita') && (
      <p>Eccoci nella sezione Spazio</p>
      )}
      {(string === 'm_7_mission2' && lang === 'ita') && (
      <p>Come unico indizio per trovare l’oggetto avete un dettaglio dell’oggetto misterioso.</p>
      )}
      {(string === 'm_8_mission2' && lang === 'ita') && (
         <>
         <img className="dettaglio" src={require('../../assets/images/dettaglio.png')} alt="" />
         <p><br /><em>…Mentre vi aggirate per la sezione scambiate quattro chiacchiere con la restauratrice Margherita Hacker, ma neppure lei è in grado di aiutarvi…</em></p>
         </>
      )}

      {(string === 'm_10_mission2' && lang === 'ita') && (
      <p><strong>Dopo aver trovato l’oggetto, inserite il codice dell’inventario.</strong></p>
      )}
      {(string === 'u_2_mission2') && (
         <p>155-D1421</p>
      )}
      {(string === 'u_2_mission2_sugg' && lang === 'ita') && (
         <p>9 caratteri</p>
      )}
      {(string === 'u_2_mission2_sugg' && lang === 'eng') && (
         <p>9 characters</p>
      )}
      {(string === 'u_2_mission2_sugg' && lang === 'fra') && (
         <p>9 caractères</p>
      )}
      {(string === 'f_1_mission2' && lang === 'ita') && (
         <p>Eureka! Il Glifo vi si rivela. Siete un passo più avanti nella risoluzione del mistero.</p>
      )}


      {/* MISSION 4 - EX MISSION 2 ENG */}

      {(string === 'm_0a_mission2' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis4" />
      )}

      {(string === 'm_0b_mission2' && lang === 'eng') && (
         <>
         <h2>The ghost object</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> <span><CommonSvgContent stringToRet="pin" /> Space</span>
         </>
      )}

      {(string === 'm_1_mission2' && lang === 'eng') && (
      <p>On October 22, 1946, the Soviet Union conscripted over 2,000 German specialists who had helped develop rockets before and during the Second World War as part of Operation Osoaviakhim.</p>
      )}
      {(string === 'm_2_mission2' && lang === 'eng') && (
      <p>The Soviets’ goal was to get ahead of their rival in the Space Race, the United States, which had the advantage of Echelonite Grand Master Von Braun’s scientific brilliance.</p>
      )}
      {(string === 'm_3_mission2' && lang === 'eng') && (
      <p>In the late 1960s, the Soviets were one step away from landing a man on the Moon. The spaceship and the lunar module were ready, along with the gear for the crew.</p>
      )}
      {(string === 'm_4_mission2' && lang === 'eng') && (
      <p>But they lacked the knowledge contained in the Antikythera mechanism, which Von Braun had brought with him to NASA. The Soviets’ N1 launcher exploded at take-off and the Americans beat them to the Moon.</p>
      )}
      {(string === 'm_5_mission2' && lang === 'eng') && (
      <p>The Soviets abandoned their Moon program and denied it ever existed for nearly half a century, destroying all the materials for fear that it would fall into the conspirators’ hands.</p>
      )}
      {(string === 'm_6_mission2' && lang === 'eng') && (
      <p>But one item was saved, and it contains the only Glyph passed down by the Soviets.</p>
      )}
      {(string === 'u_1_mission2' && lang === 'eng') && (
      <p>Here we are in the Space section</p>
      )}
      {(string === 'm_7_mission2' && lang === 'eng') && (
      <p>The only clue you have to find this mysterious object is one small detail of it.</p>
      )}
      {(string === 'm_8_mission2' && lang === 'eng') && (
         <img className="dettaglio" src={require('../../assets/images/dettaglio.png')} alt="" />
      )}

      {(string === 'm_10_mission2' && lang === 'eng') && (
      <p><strong>When you have found the object, enter its inventory code.</strong></p>
      )}

      {(string === 'f_1_mission2' && lang === 'eng') && (
      <p>That’s it! The Glyph is revealed. You are one step closer to solving the mystery.</p>
      )}

      {/* MISSION 4 - EX MISSION 2 FRA */}

      {(string === 'm_0a_mission2' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis4" />
      )}

      {(string === 'm_0b_mission2' && lang === 'fra') && (
         <>
         <h2>L'objet fantôme</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> <span><CommonSvgContent stringToRet="pin" /> Espace</span>
         </>
      )}

      {(string === 'm_1_mission2' && lang === 'fra') && (
      <p>Le 22 octobre 1946, l'Union soviétique a recruté de force plus de 2 000 spécialistes allemands qui avaient participé à la construction de fusées avant et pendant la Seconde Guerre mondiale, dans le cadre de l'opération Osoaviakhim.</p>
      )}
      {(string === 'm_2_mission2' && lang === 'fra') && (
      <p>L'objectif est de prendre l'avantage dans la course à l'espace sur les Etats-Unis, qui disposent du talent scientifique du Grand Maître Echelon Von Braun.</p>
      )}
      {(string === 'm_3_mission2' && lang === 'fra') && (
      <p>À la fin des années 1960, les Soviétiques étaient à deux doigts d'envoyer un homme sur la lune. Le vaisseau spatial pour le voyage et le vaisseau d'atterrissage lunaire sont déjà prêts, de même que le matériel de l'équipage.</p>
      )}
      {(string === 'm_4_mission2' && lang === 'fra') && (
      <p>Mais ils n'ont pas les connaissances de la machine d'Anticythère en possession de Von Braun enrôlé par la NASA. Le lanceur soviétique N1 a explosé au décollage et les Américains ont rejoint la Lune en premier.</p>
      )}
      {(string === 'm_5_mission2' && lang === 'fra') && (
      <p>Le projet soviétique de conquête de la Lune a été abandonné et, pendant près de 50 ans, on a nié qu'il ait jamais existé, détruisant tous Le matériel de peur qu'ils ne tombent entre les mains de complotistes.</p>
      )}
      {(string === 'm_6_mission2' && lang === 'fra') && (
      <p>Mais un objet a été sauvé et il contient le seul Glyphe transmis par les Soviétiques.</p>
      )}
      {(string === 'u_1_mission2' && lang === 'fra') && (
      <p>Nous voici dans la section Espace</p>
      )}
      {(string === 'm_7_mission2' && lang === 'fra') && (
      <p>Comme seul indice pour trouver l'objet, vous disposez d'un détail de l'objet mystérieux.</p>
      )}
      {(string === 'm_8_mission2' && lang === 'fra') && (
         <img className="dettaglio" src={require('../../assets/images/dettaglio.png')} alt="" />
      )}
      {(string === 'm_10_mission2' && lang === 'fra') && (
      <p><strong>Après avoir trouvé l'objet, entrez le code d'inventaire.</strong></p>
      )}
      {(string === 'f_1_mission2' && lang === 'fra') && (
      <p>Eurêka ! Le Glyphe se révèle à vous. Vous avez fait un pas de plus vers la résolution du mystère.</p>
      )}


      {/* ******* MISSION 2 - EX MISSION 3 ******** */}

      {(string === 'm_0a_mission3' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss2" />
      )}

      {(string === 'm_0b_mission3' && lang === 'ita') && (
         <>
         <h2>Per fare un missile</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> <span><CommonSvgContent stringToRet="pin" /> Ovunque</span>
         </>
      )}

      {(string === 'm_0c_mission3' && lang === 'ita') && (
         <></>
      )}


      {(string === 'm_1_mission3' && lang === 'ita') && (
      <p>Alla fine degli anni ‘20 del secolo scorso alcune imprese tedesche avviarono le prime ricerche segrete per la costruzione di un razzo a propellente liquido per finalità belliche.</p>
      )}
      {(string === 'm_2_mission3' && lang === 'ita') && (
      <p>Nel 1936 l'esercito della Wehrmacht fonda un centro di sviluppo e ricerca militare super segreto a Peenemünde a nord dell’isola di Usedom.</p>
      )}

      {(string === 'm_3_mission3' && lang === 'ita') && (
      <p>Sotto il comando di Walter Dornberger, capo della sezione missilistica, e con la guida tecnica di Wernher von Braun, in tale zona militare fu sviluppato e testato il primo missile balistico funzionante noto nella propaganda nazista come V2.</p>
      )}
      {(string === 'm_4_mission3' && lang === 'ita') && (
      <p>Il missile fu il primo oggetto costruito dall'uomo a spingersi ai confini dello Spazio esterno: per questo motivo il centro venne chiamato la «culla dei viaggi spaziali».</p>
      )}

      {(string === 'm_5_mission3' && lang === 'ita') && (
      <p>Per avere accesso al Glifo celato sulle componenti del V2 nei depositi del Museo, Meta Dendral vi chiede di trovare la ricetta corretta per il combustibile dei razzi V2.</p>
      )}

      {(string === 'm_6_mission3' && lang === 'ita') && (
      <p>Questo è quello che vi dice:</p>
      )}
      {(string === 'm_7_mission3' && lang === 'ita') && (
      <p>“Nello stemma dei Von Braun scritta sta la chiave per la ricetta perfetta:<br />
      Prima di sua Maestà le corone<br />
      Poi il campo dello scudo che protegge il Barone,<br />
      Infine, le losanghe somiglianti a margheritone”.</p>
      )}
      {(string === 'm_8_mission3' && lang === 'ita') && (
         <img className="stemma" src={require('../../assets/images/stemma.png')} alt="" />
      )}
      {(string === 'm_9_mission3' && lang === 'ita') && (
      <p>Attenzione: Prima di procedere, verificate di trovarvi in un ambiente del Museo tranquillo e in cui possiate avere un piano di appoggio..</p>
      )}
      {(string === 'u_0_mission3' && lang === 'ita') && (
      <p>Ok, trovato un ambiente tranquillo.</p>
      )}
      {(string === 'm_10_mission3' && lang === 'ita') && (
      <p>Aprite con attenzione le provette e, dopo averle utilizzate, richiudetele. Una volta inserite le sostanze nella provetta grande, chiudetela con il tappo. Fate attenzione a versare molto lentamente le sostanze all’interno del tubo senza farle cadere dall’alto e senza scuotere.</p>
      )}
      {(string === 'm_10a_mission3') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM3" />
         </div>
      )}
      {(string === 'm_11_mission3' && lang === 'ita') && (
      <p><strong>Inserite le 3 sostanze nella provetta grande nell’ordine corretto. Che colori osservate dall'alto verso il basso?</strong></p>
      )}
      {(string === 'u_1_mission3' && lang === 'ita') && (
         <p>giallo / rosso / bianco</p>
      )}

      {(string === 'f_1_mission3' && lang === 'ita') && (
      <p>Eureka! La soluzione ottenuta è quasi perfetta quanto quella Wehrmacht, complimenti. Il Glifo è finalmente vostro, l’avventura può continuare.</p>
      )}


      {/* MISSION 2 - EX MISSION 3 ENG */}

      {(string === 'm_0a_mission3' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis2" />
      )}

      {(string === 'm_0b_mission3' && lang === 'eng') && (
         <>
         <h2>Rocket science</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> <span><CommonSvgContent stringToRet="pin" /> Everywhere</span>
         </>
      )}

      {(string === 'm_0c_mission3' && lang === 'eng') && (
         <></>
      )}


      {(string === 'm_1_mission3' && lang === 'eng') && (
      <p>In the late 1920s, a handful of German companies began the first secret research projects to build a liquid-propellant rocket for warfare.</p>
      )}
      {(string === 'm_2_mission3' && lang === 'eng') && (
      <p>In 1936, the Wehrmacht army established a top-secret military research center in Peenemünde, north of Usedom island.</p>
      )}
      {(string === 'm_3_mission3' && lang === 'eng') && (
      <p>Under the command of Walter Dornberger, head of the missiles division, and with the technical guidance of Wernher von Braun, the first successful ballistic missile was developed and tested in Peenemünde. The Nazis named it V2.</p>
      )}
      {(string === 'm_4_mission3' && lang === 'eng') && (
      <p>This missile was the first object built by man to push pass the boundaries of outer space, which explains why the military research center is known as the “cradle of space travel”.</p>
      )}
      {(string === 'm_5_mission3' && lang === 'eng') && (
      <p>To gain access to the Glyph hidden in the components of the V2 rocket in the Museum’s storage rooms, Meta Dendral will ask you for the correct formula of the liquid fuel that propelled the V2 rockets.</p>
      )}
      {(string === 'm_6_mission3' && lang === 'eng') && (
      <p>This is the riddle you must solve</p>
      )}
      {(string === 'm_7_mission3' && lang === 'eng') && (
      <p>“The secret to the perfect formula lies hidden in Von Braun’s coat of arms:<br />
      First comes the crown of times past<br />
      Next a shield for the Baron, safe and steadfast,<br />
      Then the diamonds like daisies at long last.”</p>
      )}
      {(string === 'm_8_mission3' && lang === 'eng') && (
         <img className="stemma" src={require('../../assets/images/stemma.png')} alt="" />
      )}
      {(string === 'm_9_mission3' && lang === 'eng') && (
      <p>Be careful: Before you open the test tubes, check that you are in a quiet place in the Museum with a surface to work on.</p>
      )}
      {(string === 'u_0_mission3' && lang === 'eng') && (
      <p>Ok, I’ve found a quiet place</p>
      )}
      {(string === 'm_10_mission3' && lang === 'eng') && (
      <p>Open the test tubes very carefully and, after using them, close them securely. Once you have poured the substances into the large test tube, close it with the cap. Be very careful to slowly pour the substances into the tube from a close distance and do not shake them.</p>
      )}
      {(string === 'm_11_mission3' && lang === 'eng') && (
      <p><strong>Pour the three substances into the large test tube in the correct order. From top to bottom what colours do you see?</strong></p>
      )}
      {(string === 'u_1_mission3' && lang === 'eng') && (
      <p>yellow / red / white</p>
      )}

      {(string === 'f_1_mission3' && lang === 'eng') && (
      <p>Yes! Your solution is almost as perfect as Wehrmacht’s. Well done! The Glyph is finally yours and the adventure can continue.</p>
      )}


      {/* MISSION 2 - EX MISSION 3 FRA */}

      {(string === 'm_0a_mission3' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis2" />
      )}

      {(string === 'm_0b_mission3' && lang === 'fra') && (
         <>
         <h2>Pour fabriquer une fusée</h2>
         <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> <span><CommonSvgContent stringToRet="pin" /> Partout</span>
         </>
      )}

      {(string === 'm_0c_mission3' && lang === 'fra') && (
         <></>
      )}

      {(string === 'm_1_mission3' && lang === 'fra') && (
      <p>À la fin des années 20 du siècle dernier, certaines entreprises allemandes ont lancé les premières recherches secrètes pour la construction d’une fusée à propergol liquide à des fins militaires.</p>
      )}
      {(string === 'm_2_mission3' && lang === 'fra') && (
      <p>En 1936, l'armée de la Wehrmacht a créé un centre de développement et de recherche militaire ultrasecret à Peenemünde, au nord de l'île d'Usedom.</p>
      )}
      {(string === 'm_3_mission3' && lang === 'fra') && (
      <p>Sous le commandement de Walter Dornberger, chef de la section des fusées, et avec les conseils techniques de Wernher von Braun, la première fusée balistique fonctionnelle, connue dans la propagande nazie sous le nom de V2, a été développée et testée dans cette zone militaire.</p>
      )}
      {(string === 'm_4_mission3' && lang === 'fra') && (
      <p>La fusée a été le premier objet fabriqué par l'homme à repousser les limites de l'espace extra-atmosphérique, ce qui lui a valu d'être appelée le « berceau des voyages dans l’espace ».</p>
      )}
      {(string === 'm_5_mission3' && lang === 'fra') && (
      <p>Afin d'accéder au Glyphe dissimulé sur les composants des V2 dans les réserves du Musée, Meta Dendral vous demande de trouver la bonne recette du carburant des fusées V2.</p>
      )}
      {(string === 'm_6_mission3' && lang === 'fra') && (
      <p>Voici ce qu'il vous dit :</p>
      )}
      {(string === 'm_7_mission3' && lang === 'fra') && (
      <p>« Dans les armoiries de Von Braun est écrite la clé de la recette parfaite :<br />
      D’abord les couronnes de Sa Majesté<br />
      Puis le champ du bouclier qui protège le Baron,<br />
      Enfin, les losanges qui ressemblent à une grande Marguerite ».</p>
      )}
      {(string === 'm_8_mission3' && lang === 'fra') && (
         <img className="stemma" src={require('../../assets/images/stemma.png')} alt="" />
      )}
      {(string === 'm_9_mission3' && lang === 'fra') && (
      <p>Attention : Avant de commencer, assurez-vous que vous vous trouvez dans une salle tranquille du Musée où vous pouvez disposer d’un plan d’appui.</p>
      )}
      {(string === 'u_0_mission3' && lang === 'fra') && (
      <p>OK, nous avons trouvé un endroit tranquille</p>
      )}
      {(string === 'm_10_mission3' && lang === 'fra') && (
      <p>Ouvrez les éprouvettes avec précaution et refermez-les après utilisation. Une fois les substances placées dans la grande éprouvette, fermez-la avec le bouchon. Veiller à verser les substances très lentement à l’intérieur du tube, sans les faire tomber d'en haut et sans les agiter.</p>
      )}
      {(string === 'm_11_mission3' && lang === 'fra') && (
      <p><strong>Placez les 3 substances dans la grande éprouvette dans le bon ordre. Quelles couleurs observez-vous de haut en bas ?</strong></p>
      )}
      {(string === 'u_1_mission3' && lang === 'fra') && (
      <p>jaune / rouge / blanc</p>
      )}

      {(string === 'f_1_mission3' && lang === 'fra') && (
      <p>Eurêka ! La solution obtenue est presque aussi parfaite que celle de la Wehrmacht, félicitations. Le Glyphe est enfin à vous, l'aventure peut continuer.</p>
      )}


      {/* ******* MISSION 8 - EX MISSION 4 ******** */}

      {(string === 'm_0a_mission4' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss8" />
      )}

      {(string === 'm_0b_mission4' && lang === 'ita') && (
         <>
         <h2>Programmi & Paperclip</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficoltà 2</span> <span><CommonSvgContent stringToRet="pin" /> Reti, industrie e consumi</span>
         </>
      )}

      {(string === 'm_0c_mission4') && (
         <>
            <img className="mappaMission mappaMissionThird" src={require('../../assets/images/mappe/missione4.png')} alt="Mission 4" />
            <img className="mappaMission" src={require('../../assets/images/mappe/missione4a.png')} alt="Mission 4a" />
         </>
      )}

      {(string === 'm_1_mission4' && lang === 'ita') && (
      <p>Raggiungete la sezione Reti, industrie e consumi (Mosaico Tecnologico) per indagare sul calcolatore da tavolo Olivetti Programma 101.</p>
      )}
      {(string === 'u_1_mission4' && lang === 'ita') && (
      <p>Eccoci davanti alla P101</p>
      )}

      {(string === 'm_2_mission4' && lang === 'ita') && (
      <p>Dopo una lunga indagine, scoprite che all’interno del nastro di carta per la stampa dell’output della macchina, qualcuno, chissà quando, ha scritto:</p>
      )}

      {(string === 'm_3_mission4' && lang === 'ita') && (
      <>
         <p><strong>«Cinque lettere sono, ma non di lettere son fatte»</strong></p>
         <p>7 8 9 5 2</p>
         <p>9 8 7 4 1 2 3</p>
         <p>7 4 1 9 6 3 5</p>
         <p>7 8 9 6 3 2 1 4</p>
         <p>7 4 1 2 3 6 9</p>
         <p><br />La password qui sotto e nel cryptex inserirai e scoprire potrai.</p>
      </>
      )}
      {(string === 'u_2_mission4') && (
         <p>tchou</p>
      )}
      {(string === 'u_2_mission4_sugg' && lang === 'ita') && (
         <p>5 caratteri</p>
      )}
      {(string === 'u_2_mission4_sugg' && lang === 'eng') && (
         <p>5 characters</p>
      )}
      {(string === 'u_2_mission4_sugg' && lang === 'fra') && (
         <p>5 caractères</p>
      )}

      {(string === 'f_1_mission4' && lang === 'ita') && (
      <p>Eureka! Il cryptex si apre…all’interno trovate la segretissima lista Osenberg! Tra il 1945 e il 1959 gli Stati Uniti misero in atto un programma super segreto chiamato Operazione Paperclip con la quale più di 1.600 scienziati, ingegneri e tecnici tedeschi, tra cui Wernher von Braun con il suo team, furono portati dalla Germania negli USA affinché fossero assunti dal governo per portare il primo uomo sulla Luna superando i sovietici nella corsa allo Spazio.</p>
      )}
      {(string === 'm_4_mission4' && lang === 'ita') && (
      <p>La lista Osenberg venne creata inizialmente dal governo nazista sul finire della Seconda Guerra Mondiale per radunare i più geniali scienziati tedeschi e tentare di offrire un’offensiva all’esercito americano, ma venne rinvenuta nel marzo 1945 in un bagno dell’Università di Bonn da un tecnico polacco che la trasmise all’intelligence americana.</p>
      )}
      {(string === 'm_5_mission4' && lang === 'ita') && (
      <p>Leggete la lista e notate due cose curiose:<br />
      √ in fondo una misteriosa scritta “Cond Mem” e il nome Olivetti Elea 9003.<br />
      √ una nota a margine “sono due i tasti da toccare, le cui iniziali qui sotto puoi trovare”.</p>
      )}
      {(string === 'm_5a_mission4') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM4" />
         </div>
      )}
      {(string === 'm_6_mission4' && lang === 'ita') && (
      <p>Ora sapete che il prossimo oggetto su cui indagare è l’Olivetti ELEA 9003.</p>
      )}
      {(string === 'm_6_mission4' && lang === 'ita') && (
         <>
            <img className="mappaMission" src={require('../../assets/images/mappe/missione4b.png')} alt="Mission 4b" />
         </>
      )}
      {(string === 'u_3_mission4' && lang === 'ita') && (
      <p>Eccoci davanti all’ELEA9003</p>
      )}

      {(string === 'm_7_mission4' && lang === 'ita') && (
      <p>Vi trovate di fronte alla console dell’ELEA 9003, il primo computer commerciale sviluppato in Italia per la Olivetti nel 1959 da una squadra di progettisti guidata dall’ingegnere Mario Tchou.</p>
      )}
      {(string === 'm_8_mission4' && lang === 'ita') && (
      <p>Sul piano tecnologico, ELEA era un sistema molto avanzato per l’epoca, uno dei primi completamente transistorizzati del mondo, ossia costruito interamente con transistor, tecnologia che stava affiancandosi alle più usate valvole termoioniche.</p>
      )}
      {(string === 'm_9_mission4' && lang === 'ita') && (
      <p><strong>Con gli indizi contenuti sulla lista Osenberg ora sapete quali sono i due tasti che sicuramente sbloccheranno un Glifo.</strong></p>
      )}
      {(string === 'u_4_mission4') && (
         <p>wd / ar</p>
      )}

      {(string === 'u_4_mission4a_sugg' && lang === 'ita') && (
         <p>2 caratteri</p>
      )}
      {(string === 'u_4_mission4b_sugg' && lang === 'ita') && (
         <p>2 caratteri</p>
      )}
      {(string === 'u_4_mission4a_sugg' && lang === 'eng') && (
         <p>2 characters</p>
      )}
      {(string === 'u_4_mission4b_sugg' && lang === 'eng') && (
         <p>2 characters</p>
      )}
      {(string === 'u_4_mission4a_sugg' && lang === 'fra') && (
         <p>2 caractères</p>
      )}
      {(string === 'u_4_mission4b_sugg' && lang === 'fra') && (
         <p>2 caractères</p>
      )}

      {(string === 'f_2_mission4' && lang === 'ita') && (
      <p>Eureka! Spingete con cautela i due tasti WD e AR che corrispondono a Walter Dornberger, militare nazista che nel 1932 assunse come suo diretto collaboratore Wernher von Braun per la realizzazione delle V1 e delle V2, e Arthur Rudolph, ingegnere meccanico e ricercatore che per la NASA contribuì allo sviluppo dei razzi "lunari" Saturn V con Wernher von Braun.<br />
      Sentite un secco «clic» e il Glifo si rivela! La caccia continua.</p>
      )}

      {/* MISSION 8 - EX MISSION 4 ENG */}

      {(string === 'm_0a_mission4' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis8" />
      )}

      {(string === 'm_0b_mission4' && lang === 'eng') && (
         <>
         <h2>Programs & Paperclips</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficulty 2</span> <span><CommonSvgContent stringToRet="pin" /> Networks, industry and consumption</span>
         </>
      )}

      {(string === 'm_1_mission4' && lang === 'eng') && (
      <p>Go to the Networks, industries and consumption section (Technology Mosaic) to investigate the Olivetti Programma 101 desktop calculator.</p>
      )}
      {(string === 'u_1_mission4' && lang === 'eng') && (
      <p>Here we are in front of the P101</p>
      )}

      {(string === 'm_2_mission4' && lang === 'eng') && (
      <p>As you investigate, you discover that someone has written something mysterious on the strip of paper where the machine prints its output:</p>
      )}
      {(string === 'm_3_mission4' && lang === 'eng') && (
         <>
            <p><strong>“Five letters I am, but of numbers they are made”</strong></p>
            <p>7 8 9 5 2</p>
            <p>9 8 7 4 1 2 3</p>
            <p>7 4 1 9 6 3 5</p>
            <p>7 8 9 6 3 2 1 4</p>
            <p>7 4 1 2 3 6 9</p>
            <p><br />In the cryptex and here below, enter the password to further proceed and go.</p>
         </>
      )}


      {(string === 'f_1_mission4' && lang === 'eng') && (
      <p>Congratulations! The cryptex opens… and inside you’ll find the highly classified Osenberg list! Between 1945 and 1959 the United States implemented a top-secret program called Operation Paperclip, through which over 1,600 German scientists, engineers and technicians, including Wernher von Braun and his team, were brought from Germany to the US and hired by the government to help land the first man on the Moon, to beat the Soviets in the Space Race.</p>
      )}
      {(string === 'm_4_mission4' && lang === 'eng') && (
      <p>The Osenberg list was initially drafted by the Nazi government near the end of the Second World War to assemble the brightest German scientists in an attempt to mount an offensive against the US forces, but the list was found by a Polish technician in March 1945 in a bathroom at Bonn University, and he forwarded it to American intelligence.</p>
      )}
      {(string === 'm_5_mission4' && lang === 'eng') && (
      <p>Read the list and you’ll notice two interesting things:<br />
      √ at the bottom there is a mysterious note that reads “Cond Mem” with the name of the Olivetti ELEA 9003.<br />
      √ there is another note in the margin that reads “it’s two keys to touch, the initials are here”.</p>
      )}
      {(string === 'm_6_mission4' && lang === 'eng') && (
      <p>Now you know that the next object to investigate is the Olivetti ELEA 9003.</p>
      )}
      {(string === 'u_3_mission4' && lang === 'eng') && (
      <p>Here we are before the ELEA 9003</p>
      )}

      {(string === 'm_7_mission4' && lang === 'eng') && (
      <p>This is the console of the ELEA 9003, the first business computer developed in Italy for Olivetti in 1959 by a team of specialists led by the engineer Mario Tchou.</p>
      )}
      {(string === 'm_8_mission4' && lang === 'eng') && (
      <p>From a technological standpoint, the ELEA was a highly advanced system for its time, one of the first made entirely with transistors, a technology that was emerging as an alternative to the more commonly used vacuum tubes.</p>
      )}
      {(string === 'm_9_mission4' && lang === 'eng') && (
      <p><strong>With the clues in the Osenberg list, you now know what the two keys are that will unlock another Glyph.</strong></p>
      )}

      {(string === 'f_2_mission4' && lang === 'eng') && (
      <p>Excellent! You carefully press two keys: WD and AR, which respectively correspond to Walter Dornberger, the Nazi officer who hired Wernher von Braun in 1932 to work directly with him developing the V1 and V2 rockets, and Arthur Rudolph, a mechanical engineer and researcher who would later help Wernher von Braun develop the Saturn V ”lunar” rockets for NASA.<br />
      You hear a “click” and the Glyph is revealed. The hunt goes on.</p>
      )}

      {/* MISSION 8 - EX MISSION 4 FRA */}

      {(string === 'm_0a_mission4' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis8" />
      )}

      {(string === 'm_0b_mission4' && lang === 'fra') && (
         <>
         <h2>Programmes et Paperclip</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficulté 2</span> <span><CommonSvgContent stringToRet="pin" /> Networks, industries and consumption</span>
         </>
      )}

      {(string === 'm_1_mission4' && lang === 'fra') && (
      <p>Accédez à la section Networks, industries and consumption (Technology Mosaic) pour enquêter sur le calculateur de bureau Olivetti Programma 101.</p>
      )}
      {(string === 'u_1_mission4' && lang === 'fra') && (
      <p>Nous voici devant le P101</p>
      )}

      {(string === 'm_2_mission4' && lang === 'fra') && (
      <p>Après une longue enquête, vous découvrez qu'à l'intérieur du ruban de papier destiné à l'impression de la sortie de la machine, quelqu'un, on ne sait quand, a écrit :</p>
      )}
      {(string === 'm_3_mission4' && lang === 'fra') && (
      <>
         <p><strong>« Il s’agit de cinq lettres, mais elles ne sont pas faites de lettres. »</strong></p>
         <p>7 8 9 5 2</p>
         <p>9 8 7 4 1 2 3</p>
         <p>7 4 1 9 6 3 5</p>
         <p>7 8 9 6 3 2 1 4</p>
         <p>7 4 1 2 3 6 9</p>
         <p><br />Le mot de passe ci-dessous et dans le cryptex tu inséreras, et découvrir tu pourras.</p>
      </>
      )}


      {(string === 'f_1_mission4' && lang === 'fra') && (
      <p>Eurêka ! Le cryptex s'ouvre... à l'intérieur se trouve la liste Osenberg top secrète ! Entre 1945 et 1959, les États-Unis ont mis en place un programme super secret appelé Opération Paperclip avec lequel plus de 1 600 scientifiques, ingénieurs et techniciens allemands, dont Wernher von Braun et son équipe, ont été emmenés d'Allemagne aux États-Unis pour être embauchés par le gouvernement afin d’envoyer le premier homme sur la Lune dépassant les Soviétiques dans la course à l'Espace.</p>
      )}
      {(string === 'm_4_mission4' && lang === 'fra') && (
      <p>La liste Osenberg a été initialement créée par le gouvernement nazi à la fin de la Seconde Guerre mondiale pour rassembler les scientifiques allemands les plus brillants et tenter de proposer une offensive à l'armée américaine, mais elle a été retrouvée en mars 1945 dans une salle de bains de l'université de Bonn par un technicien polonais qui l'a transmise aux services de renseignement américains.</p>
      )}
      {(string === 'm_5_mission4' && lang === 'fra') && (
      <p>Lisez la liste et remarquez deux choses curieuses :<br />
      √ au bas de la liste, une mystérieuse inscription « Cond Mem » et le nom Olivetti Elea 9003.<br />
      √ une note dans la marge « il y a deux touches à effleurer, dont vous trouverez les initiales ci-dessous ».</p>
      )}
      {(string === 'm_6_mission4' && lang === 'fra') && (
      <p>Vous savez maintenant que l'objet suivant sur lequel enquêter est l'Olivetti ELEA 9003.</p>
      )}
      {(string === 'u_3_mission4' && lang === 'fra') && (
      <p>Nous voici devant l'ELEA9003</p>
      )}

      {(string === 'm_7_mission4' && lang === 'fra') && (
      <p>Vous vous trouvez devant le pupitre de l'ELEA 9003, le premier ordinateur commercial développé en Italie pour Olivetti en 1959 par une équipe de concepteurs dirigée par l'ingénieur Mario Tchou.</p>
      )}
      {(string === 'm_8_mission4' && lang === 'fra') && (
      <p>D'un point de vue technologique, ELEA était un système très avancé pour l'époque, l'un des premiers systèmes entièrement transistorisés au monde, c'est-à-dire construit entièrement avec des transistors, une technologie qui prenait sa place à côté des valves thermo-ioniques plus largement utilisées.</p>
      )}
      {(string === 'm_9_mission4' && lang === 'fra') && (
      <p><strong>Grâce aux indices de la liste d'Osenberg, vous savez maintenant quelles sont les deux touches qui débloqueront à coup sûr un Glyphe.</strong></p>
      )}

      {(string === 'f_2_mission4' && lang === 'fra') && (
      <p>Eurêka ! Appuyez avec prudence sur les deux boutons WD et AR, qui correspondent à Walter Dornberger, un militaire nazi qui, en 1932, a engagé Wernher von Braun comme collaborateur direct pour le développement des V1 et V2, et à Arthur Rudolph, ingénieur mécanicien et chercheur qui a contribué au développement des fusées « lunaires » Saturn V pour la NASA avec Wernher von Braun.<br />
      Vous entendez un « clic » net et le Glyphe se révèle ! La chasse continue.</p>
      )}


      {/* ******* MISSION 3 - EX MISSION 5 ******** */}

      {(string === 'm_0a_mission5' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss3" />
      )}

      {(string === 'm_0b_mission5' && lang === 'ita') && (
         <>
         <h2>Denti e catene</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficoltà 2</span> <span><CommonSvgContent stringToRet="pin" /> Sottomarino Toti</span>
         </>
      )}

      {(string === 'm_0c_mission5') && (
         <img className="mappaMission mappaMissionHalf" src={require('../../assets/images/mappe/missione5.png')} alt="Mission 2" />
      )}

      {(string === 'm_1_mission5' && lang === 'ita') && (
      <p>È il 1948, il segretario di Stato americano echelonita George C. Marshall firma il piano di ricostruzione dell’Europa postbellica (Piano Marshall) per accelerare la ripresa economica e industriale dei Paesi.</p>
      )}
      {(string === 'm_2_mission5' && lang === 'ita') && (
      <p>Nel 1965 a Monfalcone comincia la costruzione del sottomarino Toti che ha il compito di controllare il Mediterraneo. Siamo nella prima fase della guerra fredda e periodicamente si verificano situazioni di crisi internazionale.</p>
      )}
      {(string === 'm_3_mission5' && lang === 'ita') && (
      <p>I mezzi di informazione spostano altrove l’attenzione dell’opinione pubblica. Unione Sovietica e Stati Uniti si sfidano nella corsa allo spazio, ostentando conquiste tecnologiche e scientifiche sempre nuove anche grazie alla disseminazione dei Glifi. L’Italia fa la sua parte e nel 1967 il Toti si immerge nelle acque del mare nostrum.</p>
      )}
      {(string === 'u_1_mission5' && lang === 'ita') && (
      <p>Eccoci davanti al sottomarino</p>
      )}
      {(string === 'm_4a_mission5') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM5" />
         </div>
      )}
      {(string === 'm_4_mission5' && lang === 'ita') && (
      <p>Una volta raggiunto il naso del sottomarino, avvertite una vibrazione magnetica all’interno della borsa e un canto marinaresco risuona nell’aria:</p>
      )}
      {(string === 'm_5_mission5' && lang === 'ita') && (
      <p><strong>“Chi attraverso la catena dell’ancora in direzione tra Sud e Ovest guarderà, una «gemella» sulla parete troverà. Del meccanico Glifo il numero dei denti darmi dovrà”.</strong></p>
      )}
      {(string === 'u_2_mission5') && (
         <p>16</p>
      )}
      {(string === 'u_2_mission5_sugg' && lang === 'ita') && (
         <p>2 caratteri</p>
      )}
      {(string === 'u_2_mission5_sugg' && lang === 'eng') && (
         <p>2 characters</p>
      )}
      {(string === 'u_2_mission5_sugg' && lang === 'fra') && (
         <p>2 caractères</p>
      )}
      {(string === 'f_1_mission5' && lang === 'ita') && (
      <p>Eureka! Il Glifo vi rivela importanti informazioni, potete proseguire nella ricerca.</p>
      )}


      {/* MISSION 3 - EX MISSION 5 ENG */}

      {(string === 'm_0a_mission5' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis3" />
      )}

      {(string === 'm_0b_mission5' && lang === 'eng') && (
         <>
         <h2>Of teeth and chains</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficulty 2</span> <span><CommonSvgContent stringToRet="pin" /> Toti submarine</span>
         </>
      )}

      {(string === 'm_1_mission5' && lang === 'eng') && (
      <p>It is 1948 and the American Secretary of State, George C. Marshall, an Echelonite, signs the Marshall Plan for the reconstruction of post-war Europe. The goal is to speed up the European countries’ economic and industrial recovery.</p>
      )}
      {(string === 'm_2_mission5' && lang === 'eng') && (
      <p>In 1965, work begins on the Toti submarine in Monfalcone. It will be responsible for patrolling the Mediterranean. This is the first phase of the Cold War when international crises erupt every so often and the news media shifts the public’s attention elsewhere.</p>
      )}
      {(string === 'm_3_mission5' && lang === 'eng') && (
      <p>The Soviet Union and the United States are rivals in the Space Race, flaunting their increasingly advanced technological and scientific discoveries, partly based on the knowledge in the Glyphs. Italy is doing its part too, and in 1967 the Toti is launched into Mediterranean waters.</p>
      )}
      {(string === 'u_1_mission5' && lang === 'eng') && (
      <p>Here we are before the submarine</p>
      )}
      {(string === 'm_4_mission5' && lang === 'eng') && (
      <p>When you are standing nose to nose with the submarine, you will feel a magnetic vibration inside your kit and a sailor’s song will fill the air:</p>
      )}
      {(string === 'm_5_mission5' && lang === 'eng') && (
      <p><strong>“Now looking past the anchor chain, its links one and all/Charting a south-western course, he spots a “twin” on the wall/The mechanic’s Glyph is revealed when the number of teeth he does call”.</strong></p>
      )}

      {(string === 'f_1_mission5' && lang === 'eng') && (
      <p>Well done! The Glyph reveals some key information. Now you can continue your search.</p>
      )}

      {/* MISSION 3 - EX MISSION 5 FRA */}

      {(string === 'm_0a_mission5' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis3" />
      )}

      {(string === 'm_0b_mission5' && lang === 'fra') && (
         <>
         <h2>Dents et chaînes</h2>
         <span><CommonSvgContent stringToRet="diff2" /> Difficulté 2</span> <span><CommonSvgContent stringToRet="pin" /> Sous-marin Toti</span>
         </>
      )}

      {(string === 'm_1_mission5' && lang === 'fra') && (
      <p>En 1948, le secrétaire d'État echelonite George C. Marshall signe le plan de reconstruction de l'Europe d'après-guerre (Plan Marshall) pour accélérer la reprise économique et industrielle des Pays.</p>
      )}
      {(string === 'm_2_mission5' && lang === 'fra') && (
      <p>En 1965, la construction du sous-marin Toti a commencé à Monfalcone pour contrôler la Méditerranée. Nous sommes dans la première phase de la guerre froide et des situations de crise internationale se produisent périodiquement.</p>
      )}
      {(string === 'm_3_mission5' && lang === 'fra') && (
      <p>Les médias détournent l’attention de l'opinion publique. L'Union soviétique et les États-Unis s'affrontent dans la course à l'espace, affichant de nouvelles conquêtes technologiques et scientifiques grâce également à la dissémination des Glyphes. L'Italie a joué son rôle et, en 1967, les Toti ont plongé dans les eaux de la Méditerranée.</p>
      )}
      {(string === 'u_1_mission5' && lang === 'fra') && (
      <p>Nous voici devant le sous-mari</p>
      )}
      {(string === 'm_4_mission5' && lang === 'fra') && (
      <p>Lorsque vous atteignez le nez du sous-marin, vous sentez une vibration magnétique à l'intérieur du sac et vous entendez un chant de marins dans l'air :</p>
      )}
      {(string === 'm_5_mission5' && lang === 'fra') && (
      <p><strong>« Celui qui regardera à travers la chaîne de l'ancre en direction du Sud et de l'Ouest, trouvera une “jumelle” sur le mur. Il devra me donner le nombre de dents du Glyphe mécanique ».</strong></p>
      )}
      {(string === 'f_1_mission5' && lang === 'fra') && (
      <p>Eurêka ! Le Glyphe vous révèle des informations importantes, vous pouvez continuer votre recherche.</p>
      )}

      {/* ******* MISSION 5 - EX MISSION 6 ******** */}

      {(string === 'm_0a_mission6' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss5" />
      )}

      {(string === 'm_0b_mission6' && lang === 'ita') && (
         <>
         <h2>Verso le stelle (e strisce) e oltre</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> <span><CommonSvgContent stringToRet="pin" /> Spazio</span>
         </>
      )}

      {(string === 'm_0c_mission6') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione6.png')} alt="Mission 2" />
      )}

      {(string === 'm_1_mission6' && lang === 'ita') && (
      <p>Vi recate nella sezione Spazio dove è esposta la piccola bandiera americana che è stata portata sulla Luna dalla missione Apollo 11 nel luglio del 1969 con l’autografo del pilota del modulo di comando Micheal Collins. Sicuramente ha un legame con il disco IBM Apollo 11 contenuto nella borsa.</p>
      )}
      {(string === 'u_1_mission6' && lang === 'ita') && (
      <p>Eccoci davanti alla bandiera</p>
      )}

      {(string === 'm_2a_mission6') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM6" />
         </div>
      )}

      {(string === 'm_2_mission6' && lang === 'ita') && (
      <p>Probabilmente la struttura della bandiera americana è un sistema di cifratura, una “chiave” per conoscere quali componenti di Apollo 11 nascondono il Glifo.</p>
      )}
      {(string === 'm_3_mission6' && lang === 'ita') && (
      <p><strong>Meta Dendral vi chiede di trovare i nomi dei 4 componenti IBM usati durante la missione Apollo 11 illustrati e descritti sul disco e cifrati dalla bandiera. Inserite solo i nomi dei componenti (senza numeri davanti) in ordine numerico crescente.</strong></p>
      )}

      {(string === 'u_2_mission6') && (
         <p>power distributor / water accumulator / +28v battery / gb heat exchanger</p>
      )}
      {(string === 'u_2_mission6a_sugg' && lang === 'ita') && (
         <p>17 caratteri spazi inclusi</p>
      )}
      {(string === 'u_2_mission6b_sugg' && lang === 'ita') && (
         <p>17 caratteri spazi inclusi</p>
      )}
      {(string === 'u_2_mission6c_sugg' && lang === 'ita') && (
         <p>12 caratteri spazi inclusi</p>
      )}
      {(string === 'u_2_mission6d_sugg' && lang === 'ita') && (
         <p>17 caratteri spazi inclusi</p>
      )}

      {(string === 'u_2_mission6a_sugg' && lang === 'eng') && (
         <p>17 characters (with spaces)</p>
      )}
      {(string === 'u_2_mission6b_sugg' && lang === 'eng') && (
         <p>17 characters (with spaces)</p>
      )}
      {(string === 'u_2_mission6c_sugg' && lang === 'eng') && (
         <p>12 characters (with spaces)</p>
      )}
      {(string === 'u_2_mission6d_sugg' && lang === 'eng') && (
         <p>17 characters (with spaces)</p>
      )}

      {(string === 'u_2_mission6a_sugg' && lang === 'fra') && (
         <p>17 caractères, espaces compris</p>
      )}
      {(string === 'u_2_mission6b_sugg' && lang === 'fra') && (
         <p>17 caractères, espaces compris</p>
      )}
      {(string === 'u_2_mission6c_sugg' && lang === 'fra') && (
         <p>12 caractères, espaces compris</p>
      )}
      {(string === 'u_2_mission6d_sugg' && lang === 'fra') && (
         <p>17 caractères, espaces compris</p>
      )}

      {(string === 'f_1_mission6' && lang === 'ita') && (
      <p>Eureka! I componenti disegnati sul disco per uno stupefacente effetto ottico sembrano animarsi e fondersi tra loro rivelando il Glifo che stavate cercando. La ricerca prosegue!</p>
      )}


      {/* MISSION 5 - EX MISSION 6 ENG */}

      {(string === 'm_0a_mission6' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis5" />
      )}

      {(string === 'm_0b_mission6' && lang === 'eng') && (
         <>
         <h2>To the stars (and stripes) and beyond</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> <span><CommonSvgContent stringToRet="pin" /> Space</span>
         </>
      )}

      {(string === 'm_1_mission6' && lang === 'eng') && (
      <p>You are now in the Space section where a small American flag is displayed. The Apollo 11 mission took this flag to the Moon in July 1969 and it was signed by the pilot of the command module, Michael Collins. It must be connected somehow to the IBM Apollo 11 tracking wheel in your kit.</p>
      )}
      {(string === 'u_1_mission6' && lang === 'eng') && (
      <p>Here we are in front of the flag</p>
      )}

      {(string === 'm_2_mission6' && lang === 'eng') && (
      <p>Could the structure of the flag be some sort of cipher, a “key” to show us which parts of Apollo 11 contain the Glyph?</p>
      )}
      {(string === 'm_3_mission6' && lang === 'eng') && (
      <p><strong>Meta Dendral asks you to find the names of the four IBM components used in the Apollo 11 mission, which are illustrated and described on the wheel and ciphered in the flag. Enter only the names of the components (without any numbers) in numerical order from lowest to highest.</strong></p>
      )}

      {(string === 'f_1_mission6' && lang === 'eng') && (
      <p>That’s it exactly! The components drawn on the wheel create an astonishing optical effect and seem to come alive and meld together, revealing the Glyph that you were looking for. The search goes on!</p>
      )}

      {/* MISSION 5 - EX MISSION 6 FRA */}

      {(string === 'm_0a_mission6' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis5" />
      )}

      {(string === 'm_0b_mission6' && lang === 'fra') && (
         <>
         <h2>Jusqu'aux étoiles (et aux rayures) et au-delà</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> <span><CommonSvgContent stringToRet="pin" /> Espace</span>
         </>
      )}

      {(string === 'm_1_mission6' && lang === 'fra') && (
      <p>Vous vous rendez dans la section Espace où est exposé le petit drapeau américain qui a été porté sur la Lune par la mission Apollo 11 en juillet 1969 avec l’autographe du pilote du module de commande Micheal Collins. Il y a certainement un lien avec le disque IBM Apollo 11 contenu dans le sac.</p>
      )}
      {(string === 'u_1_mission6' && lang === 'fra') && (
      <p>Nous voici devant le drapeau</p>
      )}

      {(string === 'm_2_mission6' && lang === 'fra') && (
      <p>La structure du drapeau américain est probablement un système de cryptage, une « clé » permettant de savoir quels composants d'Apollo 11 dissimulent le Glyphe.</p>
      )}
      {(string === 'm_3_mission6' && lang === 'fra') && (
      <p><strong>Meta Dendral vous demande de retrouver les noms des 4 composants IBM utilisés lors de la mission Apollo 11 illustrés et décrits sur le disque et cryptés par le drapeau. Indiquez uniquement les noms des composants (sans chiffres devant) dans l'ordre numérique croissant.</strong></p>
      )}

      {(string === 'f_1_mission6' && lang === 'fra') && (
      <p>Eurêka ! Les éléments dessinés sur le disque pour un effet d'optique étonnant semblent s'animer et se fondre les uns dans les autres, révélant le Glyphe que vous recherchiez. La recherche continue !</p>
      )}

      {/* ******* MISSION 1 - EX MISSION 7 ******** */}

      {(string === 'm_0a_mission7' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss1" />
      )}

      {(string === 'm_0b_mission7' && lang === 'ita') && (
         <>
         <h2>Polene, draghi e voli pindarici</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> <span><CommonSvgContent stringToRet="pin" /> Pad. Aeronavale</span>
         </>
      )}

      {(string === 'm_0c_mission7') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione7.png')} alt="Mission 2" />
      )}

      {(string === 'm_1_mission7' && lang === 'ita') && (
      <p><strong>Venite a sapere dell’esistenza all’interno del Museo di una nave con due nomi, il secondo dei quali è San Giorgio, ma quello che la rende famosa è un altro. Cercate la nave, il vostro viaggio inizia da lì.</strong></p>
      )}
      {(string === 'u_1_mission7' && lang === 'ita') && (
      <p>Ecco la nave</p>
      )}
      {(string === 'm_2_mission7' && lang === 'ita') && (
      <p>Vi trovate di fronte al brigantino-goletta Ebe, il cui nome al varo nel 1921 era San Giorgio. Il mito narra che Giasone, volendo riconquistare il trono dell’antica città della Tessaglia, Iolco, usurpato dal fratellastro Pelia, venne ingaggiato da quest’ultimo per superare alcune prove e recuperare il vello d'oro…</p>
      )}
      {(string === 'm_2a_mission7') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM7" />
         </div>
      )}
      {(string === 'm_3_mission7' && lang === 'ita') && (
      <p>Questa pelle di ariete dorato si trovava appesa a un albero nella Colchide protetta da un temibile drago. Giasone raggruppa alcuni eroi, gli Argonauti, e fa costruire ad Argo una nave. La dea Atena interviene in loro aiuto e ne intaglia la prua dal frammento di una quercia sacra creando una polena parlante.</p>
      )}
      {((string === 'u_5a_mission7' && lang === 'ita') ||
        (string === 'u_1b_mission0' && lang === 'ita') ||
        (string === 'u_0a_mission2' && lang === 'ita') ||
        (string === 'u_0b_mission2' && lang === 'ita') ||
        (string === 'u_0a_mission3' && lang === 'ita') ||
        (string === 'u_0b_mission3' && lang === 'ita') ||
        (string === 'u_1a_mission7' && lang === 'ita')) && (
         <p>Continua</p>
      )}

      {((string === 'u_5a_mission7' && lang === 'eng') ||
        (string === 'u_1b_mission0' && lang === 'eng') ||
        (string === 'u_0a_mission2' && lang === 'eng') ||
        (string === 'u_0b_mission2' && lang === 'eng') ||
        (string === 'u_0a_mission3' && lang === 'eng') ||
        (string === 'u_0b_mission3' && lang === 'eng') ||
        (string === 'u_1a_mission7' && lang === 'eng')) && (
         <p>Continue</p>
      )}

      {((string === 'u_5a_mission7' && lang === 'fra') ||
        (string === 'u_1b_mission0' && lang === 'fra') ||
        (string === 'u_0a_mission2' && lang === 'fra') ||
        (string === 'u_0b_mission2' && lang === 'fra') ||
        (string === 'u_0a_mission3' && lang === 'fra') ||
        (string === 'u_0b_mission3' && lang === 'fra') ||
        (string === 'u_1a_mission7' && lang === 'fra')) && (
         <p>Continuer</p>
      )}

      {(string === 'm_4_mission7' && lang === 'ita') && (
      <p>Avvicinandovi alla polena sulla prua della nave, forse per pura suggestione, udite sussurrate queste parole:</p>
      )}
      {(string === 'm_5_mission7' && lang === 'ita') && (
      <p><strong>“In poppa, dove il nome mio giace tra draghi marini, un nodo troverai. Il suo nome qui, tu pronuncerai”.</strong></p>
      )}

      {(string === 'u_2_mission7' && lang === 'ita') && (
         <p>nodo piano</p>
      )}
      {(string === 'u_2_mission7_sugg' && lang === 'ita') && (
         <p>10 caratteri spazi inclusi</p>
      )}

      {(string === 'f_1_mission7' && lang === 'ita') && (
      <p>Eureka! Per un istante la polena-sirena pare brillare di una luce tenue e riprende a parlare:
      “Orsù, di più non tardar, un’altra nave ti attende, che il vello nella sala del Conte tu devi trovar”.</p>
      )}

      {(string === 'm_6_mission7' && lang === 'ita') && (
      <p><strong>Venite esortati dalla polena a proseguire nel viaggio mitologico alla ricerca di una grande nave che ha a che fare con un…Conte. Una volta arrivati, entrate all’interno.</strong></p>
      )}

      {(string === 'm_6b_mission7') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione7c.png')} alt="Mission 7c" />
      )}


      {(string === 'u_3#a_mission7' && lang === 'ita') && (
      <p>Ecco il «Conte»</p>
      )}
      {(string === 'u_3#b_mission7' && lang === 'ita') && (
      <p>La sala è inaccessibile? Mostrami un video</p>
      )}

      {(string === 'm_7a_mission7' && lang === 'ita') && (
      <p>Vi trovate nella sala da ballo del transatlantico Conte Biancamano, decorata dallo scultore Marcello Mascherini. Alzando gli occhi, vi accorgete della grande scultura che orna l’ampio soffitto, raffigurante il mito di Giasone e del vello d'oro.</p>
      )}

      {(string === 'm_7b_mission7' && lang === 'ita') && (
      <p>Vi trovate nella sala da ballo del transatlantico Conte Biancamano, decorata dallo scultore Marcello Mascherini. Alzando gli occhi, vi accorgete della grande scultura che orna l’ampio soffitto, raffigurante il mito di Giasone e del vello d'oro.</p>
      )}

      {(string === 'm_7c_mission7' && lang === 'ita') && (
         <video poster={require('../../assets/images/anteprima.jpg')} width="100%" controls>
             <source src={require('../../assets/video/conte.mp4')} type="video/mp4" />
         </video>
      )}

      {(string === 'm_8_mission7' && lang === 'ita') && (
      <p><strong>Dopo aver individuato il vello dorato e il drago messo a guardia, Meta Dendral vi chiede di dire il numero delle GRANDI teste del terribile drago per evocare il salvifico aiuto di Atena.</strong></p>
      )}

      {(string === 'u_4_mission7') && (
         <p>3</p>
      )}
      {(string === 'u_4_mission7_sugg' && lang === 'ita') && (
         <p>1 carattere</p>
      )}
      {(string === 'u_4_mission7_sugg' && lang === 'eng') && (
         <p>1 character</p>
      )}
      {(string === 'u_4_mission7_sugg' && lang === 'fra') && (
         <p>1 caractère</p>
      )}

      {(string === 'f_2_mission7' && lang === 'ita') && (
      <p>Eureka! Atena, la dea greca della sapienza e delle arti, con un bagliore si materializza nelle fattezze di una polena in legno di un veliero inglese ignoto.</p>
      )}

      {(string === 'm_9a_mission7') && (
         <img className="mappaMission" src={require('../../assets/images/mappe/missione7b.png')} alt="Mission 7b" />
      )}
      {(string === 'm_9_mission7' && lang === 'ita') && (
      <p><strong>Ma voi dovete guadagnarvi la sua benevolenza cercandone l’effige tra le polene che troverete esposte appena fuori dal transatlantico. Attenti, perché il suo nome sarà in… «romanesco»!</strong></p>
      )}

      {(string === 'u_5_mission7' && lang === 'ita') && (
      <p>Ecco Atena</p>
      )}

      {(string === 'm_10_mission7' && lang === 'ita') && (
      <p>Dopo un breve girovagare, trovate la polena Minerva esposta nella collezione di sculture e polene dove un altro enigma vi attende.</p>
      )}
      {(string === 'm_11_mission7' && lang === 'ita') && (
      <p>Atena, che dopo Omero venne chiamata con l'epiteto glaukopis, “dallo sguardo scintillante”, pare non volgere il suo glauco sguardo a voi ma altrove, con una divina indifferenza. Ma d’un tratto, nella vostra mente come per incanto, senza che alcuno emetta parola, risuonano queste parole…</p>
      )}
      {(string === 'm_12_mission7' && lang === 'ita') && (
      <p><strong>“Esploratori, portatevi dinnanzi a me e volgetemi il vostro sguardo, pupille nelle pupille. Ora il vostro sguardo vada ben oltre, sopra il mio elmo, fin lassù dove quell’oggetto volante parzialmente vi si svela. Raggiungetelo e il segreto tal non più sarà”.</strong></p>
      )}
      {(string === 'm_13_mission7' && lang === 'ita') && (
      <p>A quel punto vi congedate da Atena e raggiungete l’oggetto.</p>
      )}

      {(string === 'u_6_mission7' && lang === 'ita') && (
      <p>Eccoci sotto all’oggetto</p>
      )}

      {(string === 'm_14_mission7' && lang === 'ita') && (
      <p>Vi trovate dinnanzi al AW 609, modello da galleria del vento del convertiplano, un velivolo che si solleva come un elicottero e si muove come un aereo. Atena riprende a parlarvi:</p>
      )}
      {(string === 'm_15_mission7' && lang === 'ita') && (
      <p><strong>“Ora, col favor della luce che si porta, nella pancia dell’oggetto volante il tuo vello troverai e qui il numero scriverai”.</strong></p>
      )}
      {(string === 'u_7_mission7') && (
         <p>1</p>
      )}
      {(string === 'u_7_mission7_sugg' && lang === 'ita') && (
         <p>1 carattere</p>
      )}
      {(string === 'u_7_mission7_sugg' && lang === 'eng') && (
         <p>1 character</p>
      )}
      {(string === 'u_7_mission7_sugg' && lang === 'fra') && (
         <p>1 caractère</p>
      )}

      {(string === 'f_3_mission7' && lang === 'ita') && (
      <p>Eureka! Inserite il numero e trovate un importante Glifo degli Antichi. Il vello è recuperato e Atena abbandona le lignee sembianze di polena per tornare a regnar nel suo mondo tra arte e saggezza. Voi proseguite nella vostra avventura.</p>
      )}

      {/* MISSION 1 - EX MISSION 7 ENG */}

      {(string === 'm_0a_mission7' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis1" />
      )}

      {(string === 'm_0b_mission7' && lang === 'eng') && (
         <>
         <h2>Figureheads, dragons and flights of fancy</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> <span><CommonSvgContent stringToRet="pin" /> Air and Water Transport Building</span>
         </>
      )}

      {(string === 'm_1_mission7' && lang === 'eng') && (
      <p><strong>You learn that there is a ship in the Museum with two names. Its second name is San Giorgio, or St George, but the name that made it famous was its first. Find the ship and your journey begins there.</strong></p>
      )}
      {(string === 'u_1_mission7' && lang === 'eng') && (
      <p>Here is the ship</p>
      )}

      {(string === 'm_2_mission7' && lang === 'eng') && (
      <p>Before you is the brigantine-schooner “Ebe”, whose name was “San Giorgio”, or St George, at its launch. Legend has it that Jason, intending to reclaim the throne of Iolcos, ancient city of Thessaly, usurped by his half-brother Pelias, was sent by Pelias on a quest for the golden fleece…</p>
      )}
      {(string === 'm_3_mission7' && lang === 'eng') && (
      <p>This golden ram’s fleece was hanging from a tree in Colchis, guarded by a fearsome dragon. Jason had a ship built by Argos and called together a band of heroes, the Argonauts. The goddess Athena intervened on their behalf and cut the bow of the ship from a fragment of a sacred oak, creating a figurehead that could speak.</p>
      )}
      {(string === 'm_4_mission7' && lang === 'eng') && (
      <p>Step closer to the figurehead at the bow of the ship and you might even hear these words whispered in your ear,</p>
      )}
      {(string === 'm_5_mission7' && lang === 'eng') && (
      <p><strong>“At the stern, you’ll find two knots where with sea dragons there is my name. The name of these knots you must pronounce to move forward in the game.”</strong></p>
      )}
      {(string === 'u_2_mission7' && lang === 'eng') && (
         <p>square knot</p>
      )}
      {(string === 'u_2_mission7_sugg' && lang === 'eng') && (
         <p>11 characters (with spaces)</p>
      )}

      {(string === 'f_1_mission7' && lang === 'eng') && (
      <p>Yes! For an instant, the mermaid figurehead seems to glow in a soft light. She speaks again,<br />
      “Come now, hasten, another ship is next in line. And it is the fleece in a floating ballroom that now you must find.”.</p>
      )}

      {(string === 'm_6_mission7' && lang === 'eng') && (
      <p><strong>She is urging you to continue on the mythological journey in search of a large ship that has a... ballroom. Once you have arrived, venture inside.</strong></p>
      )}

      {(string === 'u_3#a_mission7' && lang === 'eng') && (
      <p>Here we are in the ballroom of the Conte Biancamano.</p>
      )}
      {(string === 'u_3#b_mission7' && lang === 'eng') && (
      <p>Can’t get into the room? Let me see the video</p>
      )}

      {(string === 'm_7a_mission7' && lang === 'eng') && (
      <p>This is the ballroom of the Conte Biancamano ocean liner, decorated by the sculptor Marcello Mascherini. If you look up at the wide ceiling you’ll see a large sculpture of the myth of Jason and the Golden Fleece.</p>
      )}

      {(string === 'm_7b_mission7' && lang === 'eng') && (
      <p>This is the ballroom of the Conte Biancamano ocean liner, decorated by the sculptor Marcello Mascherini. If you look up at the wide ceiling you’ll see a large sculpture of the myth of Jason and the Golden Fleece.</p>
      )}

      {(string === 'm_8_mission7' && lang === 'eng') && (
      <p><strong>Find the golden fleece and the dragon guarding it, then Meta Dendral will ask you for the number of LARGE heads on the fearsome dragon to invoke Athena’s divine intervention.</strong></p>
      )}

      {(string === 'f_2_mission7' && lang === 'eng') && (
      <p>Yes! Athena, Greek goddess of wisdom and handicraft, materialises in a shimmering light, in the form of the wooden figurehead of an unknown English ship.</p>
      )}
      {(string === 'm_9_mission7' && lang === 'eng') && (
      <p><strong>But you will need to earn her goodwill by finding her likeness among the figureheads displayed just outside the ocean liner. Think twice, because here she goes by her Roman name.</strong></p>
      )}

      {(string === 'u_5_mission7' && lang === 'eng') && (
      <p>Here is Athena</p>
      )}

      {(string === 'm_10_mission7' && lang === 'eng') && (
      <p>After wandering around for a short while, you find the figurehead Minerva displayed in the collection of sculptures and figureheads where the next riddle awaits.</p>
      )}
      {(string === 'm_11_mission7' && lang === 'eng') && (
      <p>After Homer gave Athena the epithet of glaukopis, “bright-eyed”, it seems as if she no longer looks at you, but gazes elsewhere, with divine indifference. And all of a sudden, you hear in your head, as if by an enchantment, without any word being uttered...</p>
      )}
      {(string === 'm_12_mission7' && lang === 'eng') && (
      <p><strong>“Explorers, stand before me and look deep into my eyes. Now raise your eyes and look beyond, above my helmet, up to the object in the air that you can partly see. Go to it and you shall know the secret”.</strong></p>
      )}
      {(string === 'm_13_mission7' && lang === 'eng') && (
      <p>Now you bid farewell to Athena and go to the object.</p>
      )}

      {(string === 'u_6_mission7' && lang === 'eng') && (
      <p>Here we are under the object</p>
      )}

      {(string === 'm_14_mission7' && lang === 'eng') && (
      <p>You are standing before the AW 609, the wind tunnel model of the tiltrotor, an aircraft that rises like a helicopter and moves like an aeroplane. Athena speaks to you again:</p>
      )}
      {(string === 'm_15_mission7' && lang === 'eng') && (
      <p><strong>“Now, with the light you have, in the belly of the flying object you will find your fleece and with it the number you will write”.</strong></p>
      )}

      {(string === 'f_3_mission7' && lang === 'eng') && (
      <p>Well done! Enter the number and find a crucial Glyph of the Ancients. The fleece has been found and Athena withdraws from her figurehead likeness to reign in her world of art and wisdom as you continue your adventure.</p>
      )}

      {/* MISSION 1 - EX MISSION 7 FRA */}

      {(string === 'm_0a_mission7' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis1" />
      )}

      {(string === 'm_0b_mission7' && lang === 'fra') && (
         <>
         <h2>Figures de proue, dragons et vols pindariques</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> <span><CommonSvgContent stringToRet="pin" /> Pav. Aéronavale</span>
         </>
      )}

      {(string === 'm_1_mission7' && lang === 'fra') && (
      <p><strong>Apprenez l'existence à l'intérieur du Musée d'un navire avec deux noms, dont le second est San Giorgio, mais il doit sa célébrité à un autre nom. Cherchez le navire, c'est là que commence votre voyage.</strong></p>
      )}

      {(string === 'u_1_mission7' && lang === 'fra') && (
      <p>Voici le navire</p>
      )}

      {(string === 'm_2_mission7' && lang === 'fra') && (
      <p>Vous vous trouvez devant le brigantin-goélette Ebe, qui s'appelait San Giorgio lors de son lancement en 1921. Le mythe raconte que Jason, voulant reconquérir le trône de l'ancienne ville de Thessalie, Iolcos, usurpé par son demi-frère Pélias, fut engagé par ce dernier pour surmonter certaines épreuves et récupérer la Toison d'or...</p>
      )}

      {(string === 'm_3_mission7' && lang === 'fra') && (
      <p>Cette peau de bélier en or était suspendue à un arbre en Colchide, protégée par un redoutable dragon. Jason rassemble des héros, les Argonautes, et fait construire un navire à Argos. La déesse Athéna intervient pour les aider et sculpte la proue dans un fragment de chêne sacré, créant ainsi une figure de proue parlante.</p>
      )}

      {(string === 'm_4_mission7' && lang === 'fra') && (
      <p>Alors que vous vous approchez de la figure de proue du navire, peut-être par pure suggestion, vous entendez ces mots chuchotés :</p>
      )}

      {(string === 'm_5_mission7' && lang === 'fra') && (
      <p><strong>« En poupe, où mon nom repose parmi les dragons marins, tu trouveras un nœud. Ici, tu prononceras son nom ».</strong></p>
      )}

      {(string === 'u_2_mission7' && lang === 'fra') && (
         <p>nœud plat</p>
      )}
      {(string === 'u_2_mission7_sugg' && lang === 'fra') && (
         <p>9 caractères, espaces compris</p>
      )}

      {(string === 'f_1_mission7' && lang === 'fra') && (
      <p>Eurêka ! Pendant un instant, la figure de proue-sirène semble briller d'une lumière tamisée et reprend la parole :
      « Viens, ne tarde plus, un autre navire t’attend, et il faut que tu trouves la toison dans la salle de bal. »</p>
      )}

      {(string === 'm_6_mission7' && lang === 'fra') && (
      <p><strong>La figure de proue vous incite à poursuivre le voyage mythologique à la recherche d'un grand navire avec une salle de bal. Une fois sur place, entrez à l'intérieur.</strong></p>
      )}

      {(string === 'u_3#a_mission7' && lang === 'fra') && (
      <p>Voici le « Comte ».</p>
      )}
      {(string === 'u_3#b_mission7' && lang === 'fra') && (
      <p>La salle est inaccessible ? Montre-moi la vidéo</p>
      )}

      {(string === 'm_7a_mission7' && lang === 'fra') && (
      <p>Vous êtes dans la salle de bal du paquebot Conte Biancamano, décorée par le sculpteur Marcello Mascherini. En levant les yeux, on remarque la grande sculpture qui orne le large plafond et qui représente le mythe de Jason et de la Toison d'or.</p>
      )}

      {(string === 'm_7b_mission7' && lang === 'fra') && (
      <p>Vous êtes dans la salle de bal du paquebot Conte Biancamano, décorée par le sculpteur Marcello Mascherini. En levant les yeux, on remarque la grande sculpture qui orne le large plafond et qui représente le mythe de Jason et de la Toison d'or.</p>
      )}

      {(string === 'm_8_mission7' && lang === 'fra') && (
      <p><strong>Après avoir repéré la Toison d'or et le dragon gardien, Meta Dendral vous demande de dire le nombre de GRANDES têtes du terrible dragon pour invoquer l'aide salvifique d'Athéna.</strong></p>
      )}

      {(string === 'f_2_mission7' && lang === 'fra') && (
      <p>Eurêka ! Athéna, la déesse grecque de la sagesse et des arts, se matérialise sous la forme d'une figure de proue en bois provenant d'un voilier anglais inconnu.</p>
      )}

      {(string === 'm_9_mission7' && lang === 'fra') && (
      <p><strong>Mais vous devez gagner sa bienveillance en cherchant son effigie parmi les figures de proue que vous trouverez affichées juste à l'extérieur du paquebot. Attention, son nom sera écrit « à la Romaine » !</strong></p>
      )}

      {(string === 'u_5_mission7' && lang === 'fra') && (
      <p>Voici Athéna</p>
      )}

      {(string === 'm_10_mission7' && lang === 'fra') && (
      <p>Après une courte promenade, vous trouverez la figure de proue Minerva exposée dans la collection de sculptures et de figures de proue, où une autre énigme vous attend.</p>
      )}
      {(string === 'm_11_mission7' && lang === 'fra') && (
      <p>Athéna, qui après Homère a été appelée par l'épithète glaukopis, « au regard étincelant », ne semble pas tourner son regard glauque vers vous mais ailleurs, avec une indifférence divine. Mais soudain, dans votre esprit, comme par magie, sans que personne ne prononce un mot, ces paroles résonnent...</p>
      )}
      {(string === 'm_12_mission7' && lang === 'fra') && (
      <p><strong>« Explorateurs, tenez-vous devant moi et regardez-moi droit dans les yeux. Maintenant, votre regard va bien au-delà, au-dessus de mon casque, jusqu'à l'endroit où cet objet volant se révèle partiellement à vous. Rejoignez-le et le secret n’en sera plus un ».</strong></p>
      )}
      {(string === 'm_13_mission7' && lang === 'fra') && (
      <p>À ce moment-là, vous prenez congé d'Athéna et rejoignez l’objet.</p>
      )}

      {(string === 'u_6_mission7' && lang === 'fra') && (
      <p>Nous voici sous l'objet</p>
      )}

      {(string === 'm_14_mission7' && lang === 'fra') && (
      <p>Vous vous trouvez devant l'AW 609, une maquette en soufflerie du tiltrotor, un appareil qui s'élève comme un hélicoptère et se déplace comme un avion. Athéna reprend la parole :</p>
      )}
      {(string === 'm_15_mission7' && lang === 'fra') && (
      <p><strong>« Maintenant, à la faveur de la lumière que l’on apporte, dans le ventre de l'objet volant tu trouveras ta toison et ici tu écriras le numéro »</strong></p>
      )}

      {(string === 'f_3_mission7' && lang === 'fra') && (
      <p>Eurêka ! Entrez le numéro et trouvez un important Glyphe des Anciens. La toison est récupérée et Athéna abandonne l’aspect ligneux de figure de proue pour revenir régner dans son monde fait d’art et de sagesse. Vous poursuivez votre aventure.</p>
      )}

      {/* ******* MISSION 7 - EX MISSION 8 ******** */}

      {(string === 'm_0a_mission8' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss7" />
      )}

      {(string === 'm_0b_mission8' && lang === 'ita') && (
         <>
         <h2>Un filetto preparato a puntino</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}

      {(string === 'm_1_mission8' && lang === 'ita') && (
      <p>Una mappa che pare disegnata dallo stesso Leonardo vi conduce alle Gallerie. Una macchia vi indica il colore del luogo e la posizione in cui è esposta la macchina progettata da Leonardo fra il 1487 e il 1490 i cui ingranaggi sembrano contenere alcuni importanti Glifi legati a strumenti di fissaggio, molto importanti nell’ambito della tecnologia dell’epoca.</p>
      )}
      {(string === 'u_1_mission8' && lang === 'ita') && (
      <p>Ecco la macchina</p>
      )}
      {(string === 'm_2a_mission8') && (
         <div className="icons" ref={ref}>
            <IconsSvgContent stringToRet="iconM8" />
         </div>
      )}
      {(string === 'm_2_mission8' && lang === 'ita') && (
      <p>La macchina davanti alla quale vi trovate, esposta nella sezione “L’ingegno del fare”, serve per filettare le viti.<br />
      <strong>Confrontando il disegno del manoscritto di Leonardo con la macchina esposta, scoprite che per proseguire dovete individuare la coppia di ruote dentate alternative che deve essere montata sulla macchina per ottenere il passo della barra filettata che trovate nella borsa. Inserite la coppia di lettere che identifica le ruote dentate corrette (a,b,c,d).</strong></p>
      )}
      {(string === 'u_2_mission8') && (
         <p>ab</p>
      )}
      {(string === 'u_2_mission8_sugg' && lang === 'ita') && (
         <p>2 caratteri</p>
      )}
      {(string === 'u_2_mission8_sugg' && lang === 'eng') && (
         <p>2 characters</p>
      )}
      {(string === 'u_2_mission8_sugg' && lang === 'fra') && (
         <p>2 caractères</p>
      )}

      {(string === 'f_1_mission8' && lang === 'ita') && (
      <p>Eureka! Il Glifo si rivela. Potete proseguire nella ricerca.</p>
      )}


      {/* MISSION 7 - EX MISSION 8 ENG */}

      {(string === 'm_0a_mission8' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis7" />
      )}

      {(string === 'm_0b_mission8' && lang === 'eng') && (
         <>
         <h2>A perfect thread</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}

      {(string === 'm_1_mission8' && lang === 'eng') && (
      <p>A map that might have been drawn by Leonardo himself leads you to the Galleries. A dot indicates the colour of the space and the spot where you will find the machine designed by Leonardo between 1487 and 1490 whose gears contain a few important Glyphs related to fasteners, a very important technology of that time.</p>
      )}

      {(string === 'u_1_mission8' && lang === 'eng') && (
      <p>Here is the machine</p>
      )}

      {(string === 'm_2_mission8' && lang === 'eng') && (
      <p>The machine you see before you in the “Ingenuity at work” section is used to thread screws.<br />
      <strong>By comparing the drawing in Leonardo’s manuscript with the machine on display, you discover that to proceed you must first identify which pair of gears should be mounted on the machine in order to create the same size thread as the screw in your kit. Enter the pair of letters identifying the gears that would create the thread of the screw in your kit (a, b, c or d).</strong></p>
      )}

      {(string === 'f_1_mission8' && lang === 'eng') && (
      <p>Excellent! The Glyph is revealed. You may continue your search.</p>
      )}

      {/* MISSION 7 - EX MISSION 8 FRA */}

      {(string === 'm_0a_mission8' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis7" />
      )}

      {(string === 'm_0b_mission8' && lang === 'fra') && (
         <>
         <h2>Un filetage préparé à la perfection</h2>
         <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> <span><CommonSvgContent stringToRet="pin" /> Leonardo da Vinci</span>
         </>
      )}

      {(string === 'm_1_mission8' && lang === 'fra') && (
      <p>Une carte qui semble avoir été dessinée par Léonard de Vinci lui-même vous emmène aux Galeries. Une tache dessinée vous indique la couleur du lieu et l'emplacement où est exposée la machine conçue par Léonard de Vinci entre le 1487 et le 1490 dont les engrenages semblent contenir quelques glyphes importants liés à des outils de fixation, très importants dans le domaine de la technologie de l'époque.</p>
      )}
      {(string === 'u_1_mission8' && lang === 'fra') && (
      <p>Voici la machine</p>
      )}

      {(string === 'm_2_mission8' && lang === 'fra') && (
      <p>La machine devant laquelle vous vous trouvez, exposée dans la section « Ingenuity at work », sert à fileter les vis.<br />
      <strong>En comparant le dessin du manuscrit de Léonard de Vinci avec la machine exposée, vous découvrez que pour continuer, vous devez identifier la paire de roues dentées alternatives qui doit être montée sur la machine pour obtenir le pas de la barre filetée que vous trouvez dans le sac. Insérer les deux lettres correspondant aux roues dentées qui conviennent (a, b, c, d).</strong></p>
      )}

      {(string === 'f_1_mission8' && lang === 'fra') && (
      <p>Eurêka ! Le Glyphe se révèle. Vous pouvez poursuivre votre recherche.</p>
      )}

</>
)
}
